// api.js
export const callApi = async (endpoint, accessToken, options = {}, retries = 3, delay = 5000) => {
    try {
      if (!process.env.REACT_APP_REVEDY_API_HOST || !process.env.REACT_APP_AUTH0_AUDIENCE) {
        console.error('REACT_APP_REVEDY_API_HOST or REACT_APP_AUTH0_AUDIENCE is not set');
        // Handle the error appropriately.
        return;
      }
  
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      };
  
      const url = `${process.env.REACT_APP_REVEDY_API_HOST}${endpoint}`;
      for (let i = 0; i < retries; i++) {
        try {
          const response = await fetch(url, options);
          if (response.status === 504) {
            throw new Error('Gateway timeout');
          }
          return response;
        } catch (error) {
          console.error(`Attempt ${i + 1} failed. Retrying after delay...`);
          await new Promise(resolve => setTimeout(resolve, delay));
        }
      }
      throw new Error(`Failed to make API call to ${url} after ${retries} attempts`);
    } catch (error) {
      console.error("Error making API call", error);
      throw error;
    }
  };