//./src/components/CMSSourceWebContent.js
import React, { useState, useEffect, useContext } from 'react';
import {
  ChakraProvider, Box, Grid, GridItem, Flex, Image, VStack, Text, Input, Button,
  Heading, useToast, Table, Thead, Tbody, Tr, Th, Td, FormControl, FormLabel,
  Collapse, IconButton
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';
import ChatToggle from './ChatToggle';
import { extendTheme } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

const colors = {
  brand: {
    900: '#8ECAE6',
    800: '#219EBC',
    700: '#023047',
    600: '#FFB703',
    500: '#FB8500'
  },
};

const theme = extendTheme({ colors });

function CMSSourceWebContent() {
  const [content, setContent] = useState([]);
  const [url, setUrl] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [instructions, setInstructions] = useState('');
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    setLoading(true);
    try {
      const response = await callApi('/cms_source_web_content', accessToken, {
        method: 'GET',
      });
      const data = await response.json();
      setContent(data);
    } catch (error) {
      console.error('Error fetching content:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch content',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await callApi('/cms_source_web_content', accessToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url, specialty, instructions }),
      });
      if (response.ok) {
        toast({
          title: 'Success',
          description: 'Content added successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setUrl('');
        setSpecialty('');
        setInstructions('');
        fetchContent();
      } else {
        throw new Error('Failed to add content');
      }
    } catch (error) {
      console.error('Error adding content:', error);
      toast({
        title: 'Error',
        description: 'Failed to add content',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const toggleRowExpansion = (id) => {
    setExpandedRows(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const renderExpandableRow = (item) => {
    return (
      <Tr>
        <Td colSpan={5}>
          <Collapse in={expandedRows[item.id]} animateOpacity>
            <Box p={4} mt={4} bg="gray.50" borderRadius="md">
              <Text><strong>CPT Codes:</strong> {item.cpt_codes || 'N/A'}</Text>
              <Text><strong>ICD-10 Codes:</strong> {item.icd10_codes || 'N/A'}</Text>
              <Text><strong>Additional Info:</strong> {item.additional_info || 'N/A'}</Text>
              

                <Box border="1px" borderColor="gray.200" p={2} borderRadius="md">
                    <ReactMarkdown>{item.extracted_markdown || 'N/A'}</ReactMarkdown>
                </Box>

            </Box>
          </Collapse>
        </Td>
      </Tr>
    );
  };

  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Grid
          templateAreas={`"header" "main" "footer"`}
          gridTemplateRows={'60px 1fr 60px'}
          gridTemplateColumns={'1fr'}
          h='100vh'
          gap='1'
          color='blackAlpha.800'
        >
          <GridItem area={'header'}>
            <HeaderMenus />
          </GridItem>

          <GridItem bg='gray.50' area={'main'} overflowY="auto">
            <Box p={4}>
              <VStack spacing={4} align="stretch">
                <Heading mb={4}>CMS Source Web Content</Heading>

                <form onSubmit={handleSubmit}>
                  <VStack spacing={4} align="stretch">
                    <FormControl>
                      <FormLabel>URL</FormLabel>
                      <Input value={url} onChange={(e) => setUrl(e.target.value)} required />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Specialty</FormLabel>
                      <Input value={specialty} onChange={(e) => setSpecialty(e.target.value)} required />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Instructions</FormLabel>
                      <Input value={instructions} onChange={(e) => setInstructions(e.target.value)} required />
                    </FormControl>
                    <Button type="submit" isLoading={loading}>Add Content</Button>
                  </VStack>
                </form>

                <Heading size="md" mt={8} mb={4}>Existing Content</Heading>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th></Th>
                      <Th>URL</Th>
                      <Th>Specialty</Th>
                      <Th>Instructions</Th>
                      <Th>Date Requested</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {content.map((item) => (
                      <React.Fragment key={item.id}>
                        <Tr>
                          <Td>
                            <IconButton
                              aria-label="Expand row"
                              icon={expandedRows[item.id] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                              onClick={() => toggleRowExpansion(item.id)}
                              size="sm"
                            />
                          </Td>
                          <Td>{item.url}</Td>
                          <Td>{item.specialty}</Td>
                          <Td>{item.instructions}</Td>
                          <Td>{new Date(item.date_requested).toLocaleDateString()}</Td>
                        </Tr>
                        {renderExpandableRow(item)}
                      </React.Fragment>
                    ))}
                  </Tbody>
                </Table>
              </VStack>
            </Box>
          </GridItem>

          <GridItem
            bg='white'
            borderTop='1px'
            borderColor='brand.200'
            area={'footer'}
          >
            <Flex justifyContent="center" alignItems="center" height="100%">
              <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
              <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                &copy; {new Date().getFullYear()}
              </Text>
            </Flex>
          </GridItem>
        </Grid>

        <ChatToggle
          contextType={'revedy'}
          contextData={'revedy_cms_source_web_content'}
          contextPersona={'revedy_cms_source_web_content'}
          firstMessage={"Hi, what can I do on this page?"}
          useOptions={false}
          maintainContext={true}
          contextMode={'popup'}
        />
      </Box>
    </ChakraProvider>
  );
}

export default CMSSourceWebContent;