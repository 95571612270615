import React, { useMemo, useState, useEffect, useContext } from 'react';
import { callApi } from '../callApi'; 
import { AppContext } from '../components/AppContext';
import CreateUser from './CreateUser';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';



import { ChakraProvider, Box, Grid, GridItem, 
    Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel, Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Spinner,

 } from "@chakra-ui/react";
import { extendTheme } from '@chakra-ui/react';
import AccountMenu from '../account';
import CaseServiceList from './CaseServiceList';
import HeaderMenus from './HeaderMenus';
import { setIn } from 'formik';
import { PieChart, Pie, Cell } from 'recharts';
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"
import { useTable, useSortBy } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { WebSocketProvider, useWebSocket } from './WebSocket'; 


// ... other code ...
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
 

function AdminLocal() {
    const { accessToken, loading, appState, setAppState } = useContext(AppContext);
    const [workflowSummary, setWorkflowSummary] = useState(null);
    const [chatDetails, setChatDetails] = useState(null);
    const [chatSummaries, setChatSummaries] = useState(null);
    const [userActivitySummary, setUserActivitySummary] = useState(null);

    const [daysBack, setDaysBack] = useState('');
    const [email, setEmail] = useState('');

    const { socket } = useWebSocket();

    const resetSections = () => {
        setChatDetails(null);
    };

    const handleUserActivyButtonClick = async (event) => {
        const response = await callApi('/local_user_activity_summary', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log("User activity response: ", response);
        // Parse the response as JSON
        const data = await response.json();
        console.log("User activity data: ", data);

        // You can now use the data in your component state or props
        // For example:
        resetSections();
        setUserActivitySummary(data);
    }


    const handleChatDetailsButtonClick = async (event) => {
        // Basic validation for daysBack and email
        if (!daysBack || daysBack < 0 || !Number.isInteger(Number(daysBack))) {
            console.error("Please enter a valid number of days.");
            return;
        }
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            email = '';
        }
    
        const response = await callApi('/local_admin_chat', accessToken, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                days: Number(daysBack),
                email: email,
            }),
        });
        console.log("Chat details response: ", response);
        const data = await response.json();
        console.log("Chat details data: ", data);
    
        resetSections();
        setChatDetails(data);
    }

    return (

        <Box textAlign="center" fontSize="xl">
        <Grid
        templateAreas={`"header header"    
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px auto 1fr 60px'}
        gridTemplateColumns={'1fr 1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontSize='12px'
        >
            
                <HeaderMenus />
            
            <GridItem bg='gray.50' padding={4} area={'main'} verticalAlign={"bottom"}>


        <div>
        <Button onClick={handleUserActivyButtonClick}>Get User Activity</Button>

        <FormLabel htmlFor="daysBack">Days Back:</FormLabel>
    <Input
        id="daysBack"
        type="number"
        value={daysBack}
        onChange={(e) => setDaysBack(e.target.value)}
        placeholder="Enter number of days"
    />
    <FormLabel htmlFor="email">Email Address:</FormLabel>
    <Input
        id="email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email address"
    />
    <Button onClick={handleChatDetailsButtonClick}>Get Chat Details Local</Button>



    {userActivitySummary && (
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Email</Th>
                            <Th>Time Slot</Th>
                            <Th>Activity Count</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                    {userActivitySummary.activity_counts
                        .sort((a, b) => new Date(b.time_slot) - new Date(a.time_slot))
                        .map((row, index) => (
                            <Tr key={index}>
                            <Td>{row.email}</Td>
                            <Td>{row.time_slot}</Td>
                            <Td>{row.activity_count}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}

    
            {chatDetails && (
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Chat Info</Th>
                            <Th>Messages</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                    {chatDetails.chat_details
                        .sort((a, b) => new Date(b.chat_created_at) - new Date(a.chat_created_at))
                        .map((row, index) => (
                            <Tr key={index}>
                            <Td>{row.email}
                            <br/>
                                {row.chat_id}
                                <br/>
                                {row.chat_created_at}
                                <br/>
                                {row.chat_additional_data}
                                </Td>
                                <Td>
                {row.messages
                    .sort((a, b) => new Date(a.message_created_at) - new Date(b.message_created_at))
                    .map((message, messageIndex) => (
                        <p key={messageIndex}>
                            <strong>{message.actor}</strong>: {message.message}
                            <br/>
                            {new Date(message.message_created_at).toLocaleString()}
                        </p>
                    ))}
            </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}

        </div>
        </GridItem>
        </Grid>
        </Box>
    );
}

export default AdminLocal;