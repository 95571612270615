import React, { useState, useContext, useEffect } from 'react';
import { useToast, Box, Text, Button, Grid, GridItem, Flex, Input, InputGroup, InputLeftElement, Icon, Select, VStack, HStack, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { callApi } from '../callApi';
import { AppContext } from './AppContext';
import HeaderMenus from './HeaderMenus';
import { FiFile, FiUpload, FiPlay } from 'react-icons/fi';

function ExternalIONMCase() {
  const [externalCaseId, setExternalCaseId] = useState('');
  const [cases, setCases] = useState([]);
  const [processing, setProcessing] = useState(false);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  const fileTypes = [
    { id: 1, name: 'Cadwell JSON' },
    { id: 2, name: 'Chat Transcript' },
    { id: 3, name: 'Tech Report' },
    { id: 4, name: 'System Summary' },
  ];

  useEffect(() => {
    fetchCases();
  }, []);

  const fetchCases = async () => {
    try {
      const response = await callApi('/list_external_cases', accessToken);
      const data = await response.json();
      if (response.ok) {
        setCases(data.cases);
      } else {
        throw new Error(data.error || 'Failed to fetch cases');
      }
    } catch (error) {
      console.error('Error fetching cases:', error);
      toast({
        title: 'Failed to fetch cases',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCreateCase = async () => {
    if (!externalCaseId) {
      toast({
        title: 'External Case ID required',
        description: "Please enter an External Case ID.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setProcessing(true);
    try {
      const response = await callApi('/create_external_case', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ external_case_id: externalCaseId }),
      });

      const data = await response.json();
      if (response.ok) {
        toast({
          title: 'Case created successfully',
          description: `Case ID: ${data.case_id}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchCases();
        setExternalCaseId('');
      } else {
        throw new Error(data.error || 'Failed to create case');
      }
    } catch (error) {
      console.error('Error creating case:', error);
      toast({
        title: 'Case creation failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleFileUpload = async (caseId, fileTypeId) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (!file) return;

      setProcessing(true);
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file_type_id', fileTypeId);

        const response = await callApi(`/upload_file/${caseId}`, accessToken, {
          method: 'POST',
          body: formData,
        });

        const data = await response.json();
        if (response.ok) {
          toast({
            title: 'File uploaded successfully',
            description: `File: ${file.name}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          fetchCases();
        } else {
          throw new Error(data.error || 'Failed to upload file');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast({
          title: 'File upload failed',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setProcessing(false);
      }
    };
    fileInput.click();
  };



  const handleProcessCase = async (caseId) => {
    setProcessing(true);
    try {
      const response = await callApi(`/process_ionm_case/${caseId}`, accessToken, {
        method: 'POST',
      });

      const data = await response.json();
      if (response.ok) {
        toast({
          title: 'Case processed successfully',
          description: `External Case ID: ${data.external_case_id}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Optionally, you can update the case status or refresh the case list here
        fetchCases();
      } else {
        throw new Error(data.error || 'Failed to process case');
      }
    } catch (error) {
      console.error('Error processing case:', error);
      toast({
        title: 'Case processing failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Box fontSize="xl">
      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
      >
        <HeaderMenus />

        <GridItem bg='white' padding={4} area={'main'}>
          <Box fontSize="sm">
            <VStack spacing={4} align="stretch">
              <Box>
                <Text fontSize="lg" fontWeight="bold">Create External IONM Case</Text>
                <HStack>
                  <Input
                    placeholder="Enter External Case ID"
                    value={externalCaseId}
                    onChange={(e) => setExternalCaseId(e.target.value)}
                  />
                  <Button
                    onClick={handleCreateCase}
                    disabled={processing || !externalCaseId}
                    colorScheme="blue"
                  >
                    Create Case
                  </Button>
                </HStack>
              </Box>







              <Box>
        <Text fontSize="lg" fontWeight="bold">External IONM Cases</Text>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Case ID</Th>
              <Th>External Case ID</Th>
              {fileTypes.map(type => (
                <Th key={type.id}>{type.name}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {cases.map(case_ => (
              <Tr key={case_.id}>
                <Td>{case_.id}
                <Button
                    size="sm"
                    onClick={() => handleProcessCase(case_.id)}
                    leftIcon={<Icon as={FiPlay} />}
                    colorScheme="green"
                    isDisabled={processing || !Object.keys(case_.files || {}).length === fileTypes.length}
                  >
                    Process Case
                  </Button>
                </Td>
                <Td>{case_.external_case_id}</Td>
                {fileTypes.map(type => (
                  <Td key={type.id}>
                    {case_.files && case_.files[type.id] ? (
                      <VStack>
                        <Text>{case_.files[type.id].file_name}</Text>
                        <Button
                          size="sm"
                          onClick={() => handleFileUpload(case_.id, type.id)}
                          leftIcon={<Icon as={FiUpload} />}
                        >
                          Update
                        </Button>
                      </VStack>
                    ) : (
                      <Button
                        size="sm"
                        onClick={() => handleFileUpload(case_.id, type.id)}
                        leftIcon={<Icon as={FiFile} />}
                      >
                        Upload
                      </Button>
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>







            </VStack>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default ExternalIONMCase;
