import React, { useState, useContext } from 'react';
import { callApi } from '../callApi';
import { AppContext } from './AppContext';
import {
  ChakraProvider,
  Box,
  Container,
  VStack,
  Heading,
  Text,
  Button,
  List,
  ListItem,
  ListIcon,
  useToast,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react";
import { CheckCircleIcon } from '@chakra-ui/icons';
import HeaderMenus from './HeaderMenus';

const StripeCheckout = () => {
  const [loading, setLoading] = useState(false);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  const handleCheckout = async () => {
    setLoading(true);

    try {
      const isLocal = window.location.href.includes('local') || window.location.href.includes('localhost');

      const product_id = 'prod_QqiHgUWyGEX6na'
      const price_id = 'price_1Pz0qeK50DNkAyBzKLIye0hg'
      const pk = 'pk_live_51OxYPUK50DNkAyBzqQy6ls8k2pGIGutz2Ds2g1KmfX6k3RiyLBquyYlU7bm9jhC3UV545YkobcS8gkF7izLkwgO8009O3hS5IX';



      const response = await callApi('/checkout_create_session', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ product_id, price_id }),
      });

      const { session_id } = await response.json();
      const stripe = window.Stripe(pk);
      await stripe.redirectToCheckout({ sessionId: session_id });
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: "An error occurred.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <ChakraProvider>
      <HeaderMenus />
      <Container maxW="container.xl" py={10}>
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="2xl" textAlign="center">
            Revedy Automated Coding
          </Heading>
          <Box bg="blue.50" p={6} borderRadius="md" boxShadow="md">
            <Heading as="h2" size="lg" mb={4}>
              Subscription Details
            </Heading>
            <Text fontSize="xl" fontWeight="bold" mb={2}>
              $99/month
            </Text>
            <Text mb={4}>Includes 50 encounters</Text>
            <Text fontSize="sm" color="gray.600">
              Each additional encounter: $1.00
            </Text>
          </Box>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Box>
              <Heading as="h3" size="md" mb={4}>
                Fully automated, real-time coding:
              </Heading>
              <List spacing={3}>
                {[
                  "Raw Transcript from audio",
                  "Structured Transcript",
                  "SOAP note generation from transcript",
                  "CPT and ICD-10 coding from note, including detailed E/M explanations",
                  "Coding output validation using current CMS and AMA guidelines",
                  "Patient Facing Summary",
                ].map((item, index) => (
                  <ListItem key={index}>
                    <ListIcon as={CheckCircleIcon} color="green.500" />
                    {item}
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box>
              <Heading as="h3" size="md" mb={4}>
                API and Team
              </Heading>
              <List spacing={3}>
                {[
                  "Full API access to support QuickBlox integration",
                  "Custom branding",
                  "Unlimited team members",
                  "Analytics dashboard",
                  "Regular feature updates"
                ].map((item, index) => (
                  <ListItem key={index}>
                    <ListIcon as={CheckCircleIcon} color="blue.500" />
                    {item}
                  </ListItem>
                ))}
              </List>
            </Box>
          </SimpleGrid>
          <Divider />
          <Button
            onClick={handleCheckout}
            isLoading={loading}
            loadingText="Processing"
            size="lg"
            colorScheme="blue"
            width="full"
          >
            Subscribe Now
          </Button>
        </VStack>
      </Container>
    </ChakraProvider>
  );









};

export default StripeCheckout;
