import React, { useState, useContext, useEffect } from 'react';
import {
  Box, Button, Grid, GridItem, Text, Input, Select, VStack, HStack,
  useToast, Table, Thead, Tbody, Tr, Th, Td, Modal, ModalOverlay,
  ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton
} from "@chakra-ui/react";
import { AppContext } from './AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';

function ContentManagement() {
  const [payers, setPayers] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [contents, setContents] = useState([]);
  const [selectedPayer, setSelectedPayer] = useState('');
  const [selectedContentType, setSelectedContentType] = useState('');
  const [contentName, setContentName] = useState('');
  const [sourceUrl, setSourceUrl] = useState('');
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [specificContent, setSpecificContent] = useState('');

  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  useEffect(() => {
    fetchPayers();
    fetchContentTypes();
    fetchContents();
  }, []);

  const fetchPayers = async () => {
    try {
      const response = await callApi('/payers', accessToken, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      setPayers(data);
    } catch (error) {
      console.error('Error fetching payers:', error);
      toast({
        title: 'Error fetching payers',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchContentTypes = async () => {
    try {
      const response = await callApi('/content_types', accessToken, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      setContentTypes(data);
    } catch (error) {
      console.error('Error fetching content types:', error);
      toast({
        title: 'Error fetching content types',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchContents = async () => {
    try {
      const response = await callApi('/content', accessToken, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      setContents(data);
    } catch (error) {
      console.error('Error fetching contents:', error);
      toast({
        title: 'Error fetching contents',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedPayer || !selectedContentType || !contentName || !sourceUrl || !file || !specificContent) {
      toast({
        title: 'Please fill all fields',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('payer_name', selectedPayer);
    formData.append('content_type', selectedContentType);
    formData.append('content_name', contentName);
    formData.append('source_url', sourceUrl);
    formData.append('specific_content', specificContent);
    formData.append('file', file);

    try {
      const response = await callApi('/content', accessToken, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      toast({
        title: 'Content uploaded successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchContents();
      setSelectedPayer('');
      setSelectedContentType('');
      setContentName('');
      setSourceUrl('');
      setSpecificContent('');
      setFile(null);
    } catch (error) {
      console.error('Error uploading content:', error);
      toast({
        title: 'Error uploading content',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleContentClick = (content) => {
    setSelectedContent(content);
    setIsModalOpen(true);
  };

  return (
    <Box fontSize="xl">
      <Grid
        templateAreas={`"header header" "main main" "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
      >
        <HeaderMenus />
        <GridItem bg='white' padding={4} area={'main'}>
          <VStack spacing={4} align="stretch">
            <Text fontSize="2xl" fontWeight="bold">Content Management</Text>
            
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="stretch">
                <Select
                  placeholder="Select Payer"
                  value={selectedPayer}
                  onChange={(e) => setSelectedPayer(e.target.value)}
                >
                  {payers.map((payer) => (
                    <option key={payer.id} value={payer.name}>{payer.name}</option>
                  ))}
                </Select>
                
                <Select
                  placeholder="Select Content Type"
                  value={selectedContentType}
                  onChange={(e) => setSelectedContentType(e.target.value)}
                >
                  {contentTypes.map((type) => (
                    <option key={type.id} value={type.name}>{type.name}</option>
                  ))}
                </Select>
                
                <Input
                  placeholder="Content Name"
                  value={contentName}
                  onChange={(e) => setContentName(e.target.value)}
                />
                
                <Input
                  placeholder="Source Notes"
                  value={sourceUrl}
                  onChange={(e) => setSourceUrl(e.target.value)}
                />

                <Input
                  placeholder="Content"
                  value={specificContent}
                  onChange={(e) => setSpecificContent(e.target.value)}
                />

                
                <Input
                  type="file"
                  onChange={handleFileChange}
                />
                
                <Button type="submit" colorScheme="blue">Upload Content</Button>
              </VStack>
            </form>
            
            <Text fontSize="xl" fontWeight="bold" mt={4}>Existing Content</Text>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Payer</Th>
                  <Th>Content Type</Th>
                  <Th>Content Name</Th>
                  <Th>Source Notes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {contents.map((content) => (
                  <Tr key={content.id} onClick={() => handleContentClick(content)} cursor="pointer">
                    <Td>{content.payer_name}</Td>
                    <Td>{content.content_type}</Td>
                    <Td>{content.content_name}</Td>
                    <Td>{content.source_url}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </VStack>
        </GridItem>
      </Grid>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedContent?.content_name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text><strong>Payer:</strong> {selectedContent?.payer_name}</Text>
            <Text><strong>Content Type:</strong> {selectedContent?.content_type}</Text>
            <Text><strong>Source Notes:</strong> {selectedContent?.source_url}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ContentManagement;