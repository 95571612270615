import React, { useState, useContext, useEffect } from 'react';
import { useToast, Box, Text, Button, Grid, GridItem, Input, VStack, HStack, Table, Thead, Tbody, Tr, Th, Td, Select } from "@chakra-ui/react";
import { callApi } from '../callApi';
import { AppContext } from './AppContext';
import HeaderMenus from './HeaderMenus';

function LogViewer() {
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(50);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  useEffect(() => {
    fetchLogs();
  }, [page, perPage, search]);

  const fetchLogs = async () => {
    try {
      const response = await callApi(`/get_logs?page=${page}&per_page=${perPage}&search=${search}`, accessToken);
      const data = await response.json();
      if (response.ok) {
        setLogs(data.logs);
        setTotalPages(data.pages);
      } else {
        throw new Error(data.error || 'Failed to fetch logs');
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
      toast({
        title: 'Failed to fetch logs',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handlePerPageChange = (e) => {
    setPerPage(Number(e.target.value));
    setPage(1);
  };

  return (
    <Box fontSize="xl">
      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
      >
        <HeaderMenus />

        <GridItem bg='white' padding={4} area={'main'}>
          <Box fontSize="sm">
            <VStack spacing={4} align="stretch">
              <HStack>
                <Input
                  placeholder="Search logs"
                  value={search}
                  onChange={handleSearch}
                />
                <Select value={perPage} onChange={handlePerPageChange}>
                  <option value={25}>25 per page</option>
                  <option value={50}>50 per page</option>
                  <option value={100}>100 per page</option>
                </Select>
              </HStack>

              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Timestamp</Th>
                    <Th>Level</Th>
                    <Th>Message</Th>
                    <Th>Module</Th>
                    <Th>Function</Th>
                    
                  </Tr>
                </Thead>
                <Tbody>
                  {logs.map(log => (
                    <Tr key={log.id}>
                      <Td>{new Date(log.timestamp).toLocaleString()}</Td>
                      <Td>{log.level}</Td>
                      <Td>{log.message}</Td>
                      <Td>{log.module}</Td>
                      <Td>{log.function}</Td>
                    
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <HStack justifyContent="center">
                <Button
                  onClick={() => setPage(page - 1)}
                  isDisabled={page === 1}
                >
                  Previous
                </Button>
                <Text>{`Page ${page} of ${totalPages}`}</Text>
                <Button
                  onClick={() => setPage(page + 1)}
                  isDisabled={page === totalPages}
                >
                  Next
                </Button>
              </HStack>
            </VStack>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default LogViewer;
