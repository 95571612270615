import React, { useState, useContext, useEffect } from 'react';
import { callApi } from '../callApi';
import { AppContext } from '../components/AppContext';
import {
  ChakraProvider,
  Box,
  VStack,
  Heading,
  Textarea,
  Button,
  Text,
  Spinner,
  Progress,
} from "@chakra-ui/react";
import HeaderMenus from './HeaderMenus';

function IONMbp() {
  const [batchData, setBatchData] = useState('');
  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [progress, setProgress] = useState(0);
  const { accessToken } = useContext(AppContext);

  const handleBatchProcess = async () => {
    setIsLoading(true);
    setError(null);
    setResults(null);
    setJobId(null);
    setProgress(0);

    try {
      const response = await callApi('/process_batch', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_data: batchData }),
      });

      if (!response.ok) {
        throw new Error('Failed to start batch process');
      }

      const data = await response.json();
      setJobId(data.job_id);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkStatus = async () => {
      if (!jobId) return;

      try {
        const response = await callApi(`/batch_status/${jobId}`, accessToken);
        const data = await response.json();

        if (data.status === 'completed') {
          setResults(data.results);
          setIsLoading(false);
          setJobId(null);
        } else if (data.status === 'failed') {
          setError(data.error);
          setIsLoading(false);
          setJobId(null);
        } else {
          // If still processing, update progress
          setProgress((prevProgress) => Math.min(prevProgress + 10, 90));
        }
      } catch (err) {
        setError('Failed to check job status');
        setIsLoading(false);
        setJobId(null);
      }
    };

    if (jobId) {
      const intervalId = setInterval(checkStatus, 5000); // Check every 5 seconds
      return () => clearInterval(intervalId);
    }
  }, [jobId, accessToken]);

  return (
    <ChakraProvider>
      <Box textAlign="center" fontSize="xl">
        <HeaderMenus />
        <VStack spacing={8} padding={8}>
          <Heading>Batch Process IONM Cases</Heading>
          <Textarea
            value={batchData}
            onChange={(e) => setBatchData(e.target.value)}
            placeholder="Enter case IDs and payer names (one per line, comma-separated)"
            size="lg"
            height="200px"
          />
          <Button
            onClick={handleBatchProcess}
            isLoading={isLoading}
            loadingText="Processing"
            colorScheme="blue"
          >
            Process Batch
          </Button>
          {error && (
            <Text color="red.500">{error}</Text>
          )}
          {isLoading && (
            <Box width="100%">
              <Progress value={progress} size="sm" colorScheme="blue" />
              <Text mt={2}>Processing... Please wait.</Text>
            </Box>
          )}
          {results && (
            <Box>
                <Heading size="md">Results:</Heading>
                <div
                    style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    textAlign: 'left',
                    fontFamily: 'monospace',
                    fontSize: '14px',
                    padding: '10px',
                    backgroundColor: '#f5f5f5',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    maxHeight: '400px',
                    overflowY: 'auto',
                    }}
                >
                    {JSON.stringify(results, null, 2)}
                </div>
            </Box>
          )}
        </VStack>
      </Box>
    </ChakraProvider>
  );
}

export default IONMbp;