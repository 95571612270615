import React, { useState, useContext } from 'react';
import { 
  ChakraProvider, Box, Grid, GridItem, Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel,
  Spinner, Tab, Tabs, TabList, TabPanel, TabPanels, Divider, Select, useToast
} from "@chakra-ui/react";
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';
import ChatToggle from './ChatToggle';
import { extendTheme } from '@chakra-ui/react';

const colors = {
  brand: {
    900: '#8ECAE6', //light blue
    800: '#219EBC', //blue
    700: '#023047', //dark blue
    600: '#FFB703', //yellow
    500: '#FB8500' //orange
  },
};

const theme = extendTheme({ colors });

function CPTCoding() {
  const [file, setFile] = useState(null);
  const [text, setText] = useState('');
  const [results, setResults] = useState(null);
  const [processing, setProcessing] = useState(false);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = async () => {
    setProcessing(true);
    setResults(null);
  
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    } else if (text) {
      formData.append('text', text);
    } else {
      toast({
        title: 'No input provided',
        description: 'Please provide either a file or text input.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      setProcessing(false);
      return;
    }
  
    try {
      const response = await callApi('/process_cpt_coding', accessToken, {
        method: 'POST',
        body: formData,
      });
  
      const data = await response.json();
      setResults(data);
      toast({
        title: 'Processing successful',
        description: 'CPT codes have been extracted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error processing CPT coding:', error);
      toast({
        title: 'Processing failed',
        description: 'There was an error processing your input.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };


  const renderCPTCode = (code, details) => {
    try {
      return (
        <Box key={code} mt={2}>
          <Text fontWeight="bold">{code}</Text>
          <Text>Units: {details.units}</Text>
          <Text>Description: {details.patient_description}, {details.description}</Text>
          <Text>Score: {details.score}</Text>
          <Text>Related Text: {details.justification}</Text>
        </Box>
      );
    } catch (error) {
      console.error("Error rendering CPT code:", error);
      return (
        <Box key={code} mt={2}>
          <Text fontWeight="bold">{code}</Text>
          <Text color="red.500">Could not process this code</Text>
        </Box>
      );
    }
  };
  
  const renderCPTCodes = (codes, title) => {
    try {
      return (
        <Box mt={4}>
          <Text fontSize="lg" fontWeight="bold">{title}</Text>
          {codes.length > 0 ? (
            codes.map(([code, details]) => renderCPTCode(code, details))
          ) : (
            <Text fontStyle="italic">No codes in this category</Text>
          )}
        </Box>
      );
    } catch (error) {
      console.error("Error rendering CPT codes:", error);
      return (
        <Box mt={4}>
          <Text fontSize="lg" fontWeight="bold">{title}</Text>
          <Text color="red.500">Could not process codes in this category</Text>
        </Box>
      );
    }
  };




  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Grid
          templateAreas={`"header"    
                          "main"
                          "footer"`}
          gridTemplateRows={'60px 1fr 60px'}
          gridTemplateColumns={'1fr'}
          h='100vh'
          gap='1'
          color='blackAlpha.800'
        >
        <GridItem area={'header'}>
            <HeaderMenus />
          </GridItem>

          <GridItem bg='gray.50' area={'main'}>
            <Box p={4}>
              <VStack spacing={4} align="stretch">
                <Text fontSize="xl" fontWeight="bold">CPT Code Extraction</Text>
                <Input type="file" onChange={handleFileChange} accept=".pdf,.txt" />
                <Text>OR</Text>
                <Textarea
                  placeholder="Enter medical text here..."
                  value={text}
                  onChange={handleTextChange}
                />
                <Button onClick={handleSubmit} isLoading={processing} loadingText="Processing">
                  Extract CPT Codes
                </Button>
                {results && (
                  <Box>
                    <Text fontSize="lg" fontWeight="bold">Results:</Text>
                    <Text whiteSpace="pre-wrap">{results.text}</Text>
                    
                    {(() => {
                        try {

                        const sortedCodes = Object.entries(results.cpt_codes).sort((a, b) => b[1].score - a[1].score);
                        const likelyCodes = sortedCodes.filter(([_, details]) => details.score >= 90);
                        const possibleCodes = sortedCodes.filter(([_, details]) => details.score >= 50 && details.score < 90);
                        const consideredCodes = sortedCodes.filter(([_, details]) => details.score < 50);

                        return (
                            <>
                            {renderCPTCodes(likelyCodes, "Likely CPT Codes")}
                            {renderCPTCodes(possibleCodes, "Possible CPT Codes")}
                            {renderCPTCodes(consideredCodes, "Related CPT Codes, Poor Match")}
                            </>
                        );
                    } catch (error) {
                        console.error("Error rendering results:", error);
                        return <Text color="red.500">Could not process results</Text>;
                    };
                    })()}
                  </Box>
                )}
              </VStack>
            </Box>
          </GridItem>

          <GridItem
            bg='white'
            borderTop='1px'
            borderColor='brand.200'
            area={'footer'}
          >
            <Flex justifyContent="center" alignItems="center" height="100%">
              <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
              <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                &copy; {new Date().getFullYear()}
              </Text>
            </Flex>
          </GridItem>
        </Grid>

        <ChatToggle
          contextType={'revedy'}
          contextData={'revedy_cpt_coding'}
          contextPersona={'revedy_cpt_coding'}
          firstMessage={"Hi, what can I do on this page?"}
          useOptions={false}
          maintainContext={true}
          contextMode={'popup'}
        />
      </Box>
    </ChakraProvider>
  );

}

export default CPTCoding;