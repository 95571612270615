import { Menu, MenuButton, MenuList, MenuItem, Box, Text, Input, Button, HStack } from "@chakra-ui/react";
import { useState, useEffect, useRef, useContext } from 'react';
import { handleCaseList } from './caseService'; // adjust the import based on your project structure
import { callApi } from '../callApi'; 
import { AppContext } from './AppContext'; // adjust the import based on your project structure

function CaseServiceList({ handleCaseButtonClick }) {
  const [cases, setCases] = useState([]);
  const [filter, setFilter] = useState('');
  const inputRef = useRef();
  const { accessToken, loading, appState, setAppState } = useContext(AppContext);

const handleSubmit = async (event) => {
  event.preventDefault();
  if (filter.trim()) {
    console.log('ionm cases filter', filter);
    let codingIssues = 0;
    let searchTerm = filter;
    let codeId = "";
    const fetchedCases = await handleCaseList(callApi, accessToken, codeId, codingIssues, searchTerm);
    setCases(fetchedCases);
  }
};

return (
  <Menu onClose={() => setFilter('')}>
    <MenuButton as={Text} fontSize={"sm"}>
      Search Cases
    </MenuButton>
    <MenuList onTransitionEnd={() => inputRef.current && inputRef.current.focus()}>
      <form onSubmit={handleSubmit}>
        <HStack>
          <Input
            placeholder="Filter cases"
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
            ref={inputRef}
              w="150px"
/>
          <Button type="submit" colorScheme="blue">
            Search
          </Button>
        </HStack>
      </form>
      {cases.map((caseItem, index) => (
        <MenuItem fontSize={"sm"} key={index}>
              <Box>
                <a
                  style={{color: 'blue', textDecoration: 'underline'}}
                  onClick={() => {
                    const url = `?caseid=${caseItem.case_id}`;
                    window.location.href = url;
                }}
                >
                  {`PID: ${caseItem.external_patient_id}`}
                </a>
                <Text fontSize="sm" color="gray.500">
                  {`Secondary PID: ${caseItem.external_patient_id_secondary}`} 
                  <br/>
                  {`CID: ${caseItem.case_id}`} 
                  <br/>
                  {`${caseItem.case_name}`}
                  <br/>
                  {`Procedure Date: ${caseItem.procedure_date}`}
                  <br/>
                  {`Patient Name: ${caseItem.patient_first_name} ${caseItem.patient_last_name}`}
                </Text>
              </Box>
            </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export default CaseServiceList;
