import React, { useState, useContext, useEffect } from 'react';
import {
  useToast,
  Box,
  Text,
  Button,
  Grid,
  GridItem,
  Input,
  UnorderedList,
  ListItem,
  VStack,
} from "@chakra-ui/react";
import { callApi } from '../callApi';
import { AppContext } from './AppContext';
import HeaderMenus from './HeaderMenus';

function FileUpload() {
  const [file, setFile] = useState(null);
  const [userFiles, setUserFiles] = useState([]);
  const [processing, setProcessing] = useState(false);

  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  useEffect(() => {
    fetchUserFiles();
  }, [accessToken, toast]);

  const fetchUserFiles = async () => {
    try {
      let response = await callApi('/list_user_files', accessToken);
      let result = await response.json();
      setUserFiles(result.files);
    } catch (error) {
      console.error('Error fetching user files:', error);
      toast({
        title: 'Fetching files failed.',
        description: "There was an error fetching the files.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast({
        title: 'No file selected.',
        description: "Please select a file to upload.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setProcessing(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      let response = await callApi('/upload_file', accessToken, {
        method: 'POST',
        body: formData,
      });
      let result = await response.json();
      console.log(result.message);
      fetchUserFiles();
      toast({
        title: 'File uploaded.',
        description: "The file has been successfully uploaded.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      toast({
        title: 'Upload failed.',
        description: "There was an error uploading the file.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const renderFileList = () => {
    const extractFileName = (filePath) => {
        const parts = filePath.split('/');
        return parts[parts.length - 1];
      };
    
    return (
      <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
        <Text fontSize="xl" fontWeight="bold" mb={2}>
          Uploaded Files:
        </Text>
        <UnorderedList>
          {userFiles.map((file, index) => (
          <ListItem key={index}>{extractFileName(file)}</ListItem>
        ))}
        </UnorderedList>
      </Box>
    );
  };

  return (
    <Box fontSize="sm">
      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='regular'
      >
        <HeaderMenus />

        <GridItem bg='white' padding={4} area={'main'}>
          <VStack spacing={4} alignItems="stretch">
            <Box>
              <Text fontSize="xl" fontWeight="bold" mb={2}>
                Secure File Upload
              </Text>
              <Input
                type="file"
                onChange={handleFileChange}
                disabled={processing}
                mb={2}
              />
              <Button
                onClick={handleFileUpload}
                disabled={processing || !file}
              >
                {processing ? 'Uploading...' : 'Upload File'}
              </Button>
            </Box>

            {renderFileList()}
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default FileUpload;