import React, { useState } from 'react';

const ChatBox = ({ sendChatMessage, runIdChatBox }) => {
  const [userMessage, setUserMessage] = useState('');

  const handleInputChange = (event) => {
    setUserMessage(event.target.value);
  };

  const handleButtonClick = () => {
    sendChatMessage(userMessage, runIdChatBox);
    setUserMessage('');
  };

  return (
    <div style={{ paddingBottom: '10px' }}>
      <input 
        type="text" 
        value={userMessage} 
        onChange={handleInputChange} 
        style={{
          width: '80%', // make the input box wider
          background: 'white',
          border: '1px solid',
          borderColor: 'brand.200',
          padding: '10px',
          borderRadius: '5px',
          fontStyle: userMessage ? 'normal' : 'italic',
          color: userMessage ? 'black' : '#999'
        }}
        placeholder="Type question here"
      />
        <button 
        onClick={handleButtonClick} 
        style={{
            background: 'brand.800', // change to a darker color
            color: 'black',
            border: '2px solid brand.700', // add a border
            borderRadius: '5px',
            padding: '10px',
            marginLeft: '10px',
            fontSize: '16px', // increase font size
        }}
        >
        Send
        </button>
    </div>
  );
};

export default ChatBox;