import React, { useState } from 'react';

const Rating = () => {
  const [selectedRating, setSelectedRating] = useState('neutral');

  const handleUpClick = () => {
    setSelectedRating((prevRating) => (prevRating === 'up' ? 'neutral' : 'up'));
  };

  const handleDownClick = () => {
    setSelectedRating((prevRating) => (prevRating === 'down' ? 'neutral' : 'down'));
  };

  const getIconColor = (icon) => {
    if (selectedRating === icon) {
      return icon === 'up' ? 'green' : 'red';
    }
    return 'lightgrey';
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <i
        className="material-icons"
        onClick={handleUpClick}
        style={{
          cursor: 'pointer',
          fontSize: '18px',
          color: getIconColor('up'),
        }}
      >
        thumb_up
      </i>
      <i
        className="material-icons"
        onClick={handleDownClick}
        style={{
          cursor: 'pointer',
          fontSize: '18px',
          color: getIconColor('down'),
        }}
      >
        thumb_down
      </i>
    </div>
  );
};

export default Rating;