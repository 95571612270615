import React, { useState, useContext, useEffect } from 'react';
import { callApi } from '../callApi'; 
import { AppContext } from '../components/AppContext';
import ReactMarkdown from 'react-markdown';
import { ChakraProvider, Box, Grid, GridItem, 
    Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel, Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Spinner,
    FormControl,
 } from "@chakra-ui/react";
import { extendTheme } from '@chakra-ui/react';
import AccountMenu from '../account';
import CaseServiceList from './CaseServiceList';
import HeaderMenus from './HeaderMenus';
import { connectWebSocket } from '../utils';
import { setIn } from 'formik';
import ChatToggle from './ChatToggle'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';
  
function GenerateNote() {
    const [inputData, setInputData] = useState('');
    const [otherData, setOtherData] = useState('');
    const [resultsReceived, setResultsReceived] = useState(false);
    const [resultsmReceived, setResultsmReceived] = useState(false);
    const [generatedNote, setGeneratedNote] = useState('');
    const [generatedNoteText, setGeneratedNoteText] = useState('');
    const [patientContent, setPatientContent] = useState('');
    const { accessToken, loading, appState, setAppState } = useContext(AppContext);
    const navigate = useNavigate();
    const [transcript, setTranscript] = useState(appState.transcript || '');
    const [clinicianNotes, setClinicianNotes] = useState('');
    const [chatHistory, setChatHistory] = useState('');
    const [additionalContext, setAdditionalContext] = useState('');
    const [patientRecord, setPatientRecord] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [percentComplete, setPercentComplete] = useState(0);

    useEffect(() => {
        if (isLoading) {
            const interval = setInterval(() => {
                setPercentComplete(oldPercent => {
                    const newPercent = oldPercent + (100 / 60);
                    if (newPercent >= 100) {
                        clearInterval(interval); // Clear interval if 100% is reached
                        return 100;
                    }
                    return newPercent;
                });
            }, 1000); // Update every second
    
            return () => clearInterval(interval); // Clear interval on cleanup
        }
    }, [isLoading]);

    useEffect(() => {
        if (appState.transcript) {
          setTranscript(appState.transcript);
        }
      }, [appState.transcript]);

    const handleCreateNoteClick = (generatedNote) => {
        setAppState(prevState => ({
          ...prevState,
          generatedNote: true, // Set this to indicate a note should be created
          generatedNoteText: generatedNote, // Replace with your actual note text
        }));
        navigate('/create-note?create=true'); // Or '/note?create=true', depending on your preference
    };

    const handleSampleButtonClick = async (event) => {
        const response = await callApi('/generate_note_from_data_list', accessToken, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            }),
        });
        //console.log("compare response: ", response);
        // Parse the response as JSON
        const data = await response.json();
        //setInputData(data.sample);
        setAdditionalContext(data.additional_context);
        setPatientRecord(data.patient_record);
        setTranscript(data.transcript);
        setClinicianNotes(data.clinician_notes);
        setChatHistory(data.chat_history);


    };


    const handleDataSubmit = async (event, dataType, processingSpeed) => {
        event.preventDefault();
        setResultsReceived(false);
        setResultsmReceived(false);
        setIsLoading(true); // Start loading

        setGeneratedNote(<Box align="center" justify="center"><Spinner size="sm" /></Box>);    
        setPatientContent(<Box align="center" justify="center"><Spinner size="sm" /></Box>);

        const onProgress = (data) => {
            // Update the 'intro' state variable with the response data

            if ('generate_note_with_icd10_cpt' in data) {
                const context = data.generate_note_with_icd10_cpt;
                const lines = context.split('\n');
                const jsxLines = lines.map((line, index) => <Text key={index} align="left" ml={4} fontSize="md" fontWeight="normal">{line}</Text>);

                //setGeneratedNote();
                //setGeneratedNoteText(context);
                //setResultsReceived(true);
            }
            if ('patient_facing_content' in data) {
                //console.log("patient_facing_content data: ", data.patient_facing_content);
                let content = data.patient_facing_content;
                //console.log("context: ", content);
                let contentLines = content.split('\n');
                let jsxContentLines = contentLines.map((line, index) => <Text key={index} align="left" ml={4} fontSize="md" fontWeight="normal">{line}</Text>);
                setPatientContent(jsxContentLines);
            }
            // Disconnect WebSocket once all data has been received
            if (data.status === 'complete' || data.status === 'error') {
                socket.disconnect();
            }
        };

        const socket = await connectWebSocket(onProgress);

        
        let cptSetters = [];
        let descSetters = [];

        const response = await callApi('/generate_note_from_data', accessToken, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              transcript: transcript,
              note: clinicianNotes,
              chat_history: chatHistory,
              additional_context: additionalContext,
              patient_record: patientRecord,
              data_type: dataType,
              socket_id: socket.id,
              processing_speed: processingSpeed,
              // ... other data
            }),
          });

        if (response.ok) {
            const data = await response.json();
            // Assuming 'note' is the field you want to display
            const note = data.note;
            const lines = note.split('\n');
            const jsxLines = lines.map((line, index) => <Text key={index}>{line}</Text>);
    
            setGeneratedNoteText(note);
            setGeneratedNote(jsxLines);
            setResultsReceived(true);
        } else {
            // Handle errors
        }
        setResultsReceived(true);
        setIsLoading(false); // Stop loading
        setPercentComplete(0); // Reset percent complete
    };


    return (
        <Box textAlign="center" fontSize="xl">
        <Grid
        templateAreas={`"header header"    
                        "main results"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr 1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='bold'
        >
                {/* Header with menu */}
                    <HeaderMenus />
        
            <GridItem bg='gray.50' padding={4} area={'main'}>
                    <form onSubmit={handleDataSubmit} ml={1} mr={1}>
                    <Flex  justifyContent="flex-end">


                    <Box marginBottom={4} marginRight={4}>
                            <Text
                            fontSize="sm"
                            color="blue.500"
                            onClick={(event) => handleSampleButtonClick(event)}
                            _hover={{ textDecoration: "underline" }}
                            >
                                Get Sample
                            </Text>
                        </Box>


                        <Box marginBottom={4} marginRight={4}>
                            <Text
                            fontSize="sm"
                            color="blue.500"
                            onClick={(event) => handleDataSubmit(event, "no_messages", "fastest")}
                            _hover={{ textDecoration: "underline" }}
                            >
                                Generate Note - Fastest
                            </Text>
                            <Text
                            fontSize="sm"
                            color="blue.500"
                            onClick={(event) => handleDataSubmit(event, "no_messages", "fast")}
                            _hover={{ textDecoration: "underline" }}
                            >
                                Generate Note - Fast
                            </Text>
                            <Text
                            fontSize="sm"
                            color="blue.500"
                            onClick={(event) => handleDataSubmit(event, "no_messages", "normal")}
                            _hover={{ textDecoration: "underline" }}
                            >
                                Generate Note - Normal
                            </Text>
                        </Box>
                      
                    </Flex>
                    <VStack spacing={4}>
                        <FormControl>
                            <FormLabel>Transcript</FormLabel>
                            <Textarea bgColor="white" placeholder="Enter transcript here" value={transcript} onChange={e => setTranscript(e.target.value)} rows={10} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Clinician Notes</FormLabel>
                            <Textarea bgColor="white" placeholder="Enter clinician notes here" value={clinicianNotes} onChange={e => setClinicianNotes(e.target.value)} rows={10} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Chat History</FormLabel>
                            <Textarea bgColor="white" placeholder="Enter chat history here" value={chatHistory} onChange={e => setChatHistory(e.target.value)} rows={10} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Additional Context</FormLabel>
                            <Textarea bgColor="white" placeholder="Enter any additional context here" value={additionalContext} onChange={e => setAdditionalContext(e.target.value)} rows={10} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Patient Record</FormLabel>
                            <Textarea bgColor="white" placeholder="Enter patient record here" value={patientRecord} onChange={e => setPatientRecord(e.target.value)} rows={10} />
                        </FormControl>
                        </VStack>
                    {/*

                    <FormLabel>
                        Additional Data:
                        <Textarea value={otherData} onChange={e => setOtherData(e.target.value)} rows="20" />
                    </FormLabel>
*/}
                    </form>
                
            </GridItem>

            <GridItem bg='gray.50' padding={4} area={'results'}>
            
            <Tabs>
  <TabList>
    <Tab>Note</Tab>
    <Tab>Patient</Tab>
    {/* <Tab>ICD-10</Tab>
    <Tab>CPT</Tab>
    */}
  </TabList>
  <TabPanels>
    <TabPanel>
    {isLoading ? (
        <Box position="relative" display="inline-block">
            
            <Text
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                fontSize="md"
            >
                {Math.round(percentComplete)}%
            </Text>
        </Box>
    ) : (
                 generatedNoteText && (
            <div>
                <Box marginLeft="4px" 
               className="markdown-content"
                textAlign={'left'}
                >
               <button
                    onClick={() => handleCreateNoteClick(generatedNoteText)}
                    style={{ color: 'blue', backgroundColor: 'transparent', border: 'none', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        Click here to code this note
                </button>
                    <ReactMarkdown>
                        {generatedNoteText}
                    </ReactMarkdown>
                </Box>
                </div>
        )
        )}





</TabPanel>
<TabPanel>
{patientContent && (    
    <div>
        <Text align="left" ml={4} fontSize="md" fontWeight="normal">{patientContent}</Text>
    </div>
)}

</TabPanel>
<TabPanel>
    </TabPanel>
    </TabPanels>
    </Tabs>
            

            </GridItem>


            <GridItem
              bg='white'
              borderTop='1px'
              borderColor='brand.200'
              paddingY={4}
              paddingX={6}
              area={'footer'}
            >
              <Flex justifyContent="center" alignItems="center">
                <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
                <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                  &copy; {new Date().getFullYear()}
                </Text>
              </Flex>
            </GridItem>
          </Grid>
        <ChatToggle
            contextType={'revedy'}
            contextData={'revedy_generate_note'}
            contextPersona={'revedy_generate_note'}
            firstMessage={"Hi, what can I do on this page?"}
            useOptions={false}
            maintainContext={true}
            contextMode={'popup'}
        />
        </Box>
      );
    };        


export default GenerateNote;