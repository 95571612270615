import React, { useState, useEffect, useContext } from 'react';
import { callApi } from '../callApi'; 
import { AppContext } from '../components/AppContext';
import ReactMarkdown from 'react-markdown';
import RRelevancyResults from './IONMRMedicalNecessity';

import { ChakraProvider, Box, Grid, GridItem, Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel,
    Spinner,
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Divider,
    Select, } from "@chakra-ui/react";
    
import { useTable, useSortBy } from 'react-table';

import { extendTheme } from '@chakra-ui/react';
import AccountMenu from '../account';
import CaseServiceList from './CaseServiceList';
import HeaderMenus from './HeaderMenus';
import { json, useLocation } from 'react-router-dom';
import Chat from './Chat';
import { handleCaseList } from './caseService'; 
import ChatToggle from './ChatToggle';


const colors = {
    brand: {
      900: '#8ECAE6', //light blue
      800: '#219EBC', //blue
      700: '#023047', //dark blue
      600: '#FFB703', //yellow
      500: '#FB8500' //orange
    },
  };
  
  // Extend the theme
  const theme = extendTheme({ colors });

  
function IONMmn () {
    const [patientName, setPatientName] = useState('Jane Smith');
    const [patientId, setPatientId] = useState('123456789');
    const [caseId, setCaseId] = useState('');
    const [jsonData, setJsonData] = useState('');
    const [caseFiles, setCaseFiles] = useState([]);
    const [otherData, setOtherData] = useState('');
    const [cpt1, setCpt1] = useState('');
    const [cpt1desc, setCpt1desc] = useState('');
    const [pdfUrl, setPdfUrl] = useState(null);
    const [resultsReceived, setResultsReceived] = useState(false);
    const [resultsmReceived, setResultsmReceived] = useState(false);
    const [cptData, setCptData] = useState('');
    const [cptmData, setCptmData] = useState('');
    const { accessToken, loading, appState, setAppState } = useContext(AppContext);
    const [results, setResults] = useState(undefined);
    const [resultsValue, setResultsValue] = useState(undefined);
    const [cases, setCases] = useState([]);
    const [currentCaseIndex, setCurrentCaseIndex] = useState(0);
    const [caseIds, setCaseIds] = useState([]);
    const [codingInProgress, setCodingInProgress] = useState(false);
    const location = useLocation();
    const [autoRun, setAutoRun] = useState(false);
    const [chatSummaries, setChatSummaries] = useState(null);
    const [statusMapping, setStatusMapping] = useState({});
    
    const [relevancyResults, setRelevancyResults] = useState({});
    const [relevancyErrors, setRelevancyErrors] = useState({});
    const [gettingRelevancyResults, setGettingRelevancyResults] = useState({});


    const [testsRun, setTestsRun] = useState({
        SSEP: { isRun: false, data: "" },
        MEP: { isRun: false, data: "" },
        EMG: { isRun: false, data: "" },
        To4: { isRun: false, data: "" },
        BAER: { isRun: false, data: "" },
        NerveConduction: { isRun: false, data: "" },
        EEG: { isRun: false, data: "" },
        Other: { isRun: false, data: "" },
      });


    const [selectedPayer, setSelectedPayer] = useState('cms');
    const payers = [
        'medicare', 'cigna', 'aetna', 'UHC', 'BCBS','BCBS TX', 'BCBS NV', 'BCBS MI', 'BCBS IN',
        'BCBS AL', 'UMR', 'BCBS IL', 'BCBS PA', 'BCBS MA', 
        'BCBS GA', 'BSBS TN', 'BCBS NM', 'BCBS MS', 'BCBS CO', 'BCBS AZ', 'BCBS AR'
    ];

    const handlePayerChange = (event) => {
        setSelectedPayer(event.target.value);
        if (caseId) {
            handleMNClick(caseId, event.target.value);
        }
    };
    
    const openFileDialog = () => {
        document.getElementById('fileInput').click();
    };
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // Now you can handle the file. For example, you can read its content or upload it to a server.
    };

    useEffect(() => {
      const fetchCases = async () => {
        const params = new URLSearchParams(location.search);
        let caseIdFromSystem = params.get('caseid');

        if (!caseIdFromSystem) {            
          if (appState.caseId) {
            caseIdFromSystem = appState.caseId;
          }
        }
        if (!caseIdFromSystem) {
          return;
        }
        let cases = await handleCaseList(callApi, accessToken, caseIdFromSystem);
        
        setCases(cases);
        if (cases.length > 0) {
          const ids = cases.map(caseItem => caseItem.case_id);
          setCaseIds(ids);
    
          const params = new URLSearchParams(location.search);
          const caseIdFromUrl = params.get('caseid');
          let newIndex = 0;

          newIndex = ids.indexOf(caseIdFromSystem);
    
          setCurrentCaseIndex(newIndex);
          setAppState(prevState => ({
            ...prevState,
            caseId: ids[newIndex]
          }));

        }
      };
      fetchCases();
    }, [location]);

    React.useEffect(() => {
      if (appState.caseId) {
        setTestsRun(null);
        setCpt1desc(null);

        setCaseId(appState.caseId);

        const params = new URLSearchParams(location.search);
        const payerFromURL = params.get('payer');

        let payer = 'cms';
        if (payerFromURL) {
            setSelectedPayer(payerFromURL);
            payer = payerFromURL;
        }
        else {
            setSelectedPayer('cms');
        }

        handleMNClick(appState.caseId, payer);


        console.log("caseId: ", appState.caseId);

        const newIndex = caseIds.indexOf(appState.caseId);

        if (newIndex !== -1) {
          setCurrentCaseIndex(newIndex);
        }
      }
    }, [appState.caseId]);


    function getTestsRun(data) {
        const tests = {
          SSEP: { isRun: false, data: "" },
          MEP: { isRun: false, data: "" },
          EMG: { isRun: false, data: "" },
          To4: { isRun: false, data: "" },
          BAER: { isRun: false, data: "" },
          NerveConduction: { isRun: false, data: "" },
          EEG: { isRun: false, data: "" },
          Other: { isRun: false, data: "" },
        };
      
        const ssepCodes = new Set(["95925", "95926", "95938"]);
        const mepCodes = new Set(["95928", "95929", "95939"]);
        const emgCodes = new Set(["51785","95860", "95861", "95864", "95865", "95867", "95868", "95870", "95885", "95886", "95887", "EMG from chat"]);
        const to4Codes = new Set(["95999"]);
        const nerveConductionCodes = new Set(["95907", "95908", "95909", "95910", "95911", "95912", "95913"]);
        const baerCodes = new Set(["92653"]);
        const eegCodes = new Set([ "95822", "95955"]);
      
        console.log("data : ", data);

        for (const item of data) {
            const code = item.cpt_code;
            if (ssepCodes.has(code)) {
              tests.SSEP.isRun = true;
              tests.SSEP.data += item.reason + ' ';
            } else if (mepCodes.has(code)) {
              tests.MEP.isRun = true;
              tests.MEP.data += item.reason + ' ';
            } else if (emgCodes.has(code)) {
              tests.EMG.isRun = true;
              tests.EMG.data += item.reason + ' ';
            } else if (to4Codes.has(code)) {
              tests.To4.isRun = true;
              tests.To4.data += item.reason + ' ';
            } else if (baerCodes.has(code)) {
              tests.BAER.isRun = true;
              tests.BAER.data += item.reason + ' ';
            } else if (nerveConductionCodes.has(code)) {
              tests.NerveConduction.isRun = true;
              tests.NerveConduction.data += item.reason + ' ';
            } else if (eegCodes.has(code)) {
              tests.EEG.isRun = true;
              tests.EEG.data += item.reason + ' ';
            } else {
              tests.Other.isRun = true;
              tests.Other.data += item.reason + '\n';
            }
          }
              
        return tests;
      }

      const TestCard = ({ title, data, isTestRun, spanTwoColumns }) => {
        const lines = data.split('\n').map((line, index, array) => (
          <React.Fragment key={index}>
            <Text fontSize="sm">{line}</Text>
            {index !== array.length - 1 && <br />}
          </React.Fragment>
        ));
      
        return (
          <Box
            textAlign={'left'}
            bg={isTestRun ? "green.500" : "gray.200"}
            color={isTestRun ? "white" : "black"}
            p={4}
            borderRadius="md"
            boxShadow="sm"
            gridColumn={spanTwoColumns ? "span 2" : null}
          >
            <Text fontSize="lg" mb={2}>
              {title}
            </Text>
            {lines}
          </Box>
        );
      };

    const handleMNClick = async (case_id, payer_name) => {
        console.log("getting case results for case_id: " + case_id);
        setRelevancyResults(prev => ({ ...prev, [payer_name]: null }));
        setRelevancyErrors(prev => ({ ...prev, [payer_name]: null }));
        setGettingRelevancyResults(prev => ({ ...prev, [payer_name]: true }));

        try {
            let response = await callApi('/ionm_process_case', accessToken, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({case_id: case_id,
                                      payer_name: payer_name,
                })
                });
            let results = await response.json();
          setGettingRelevancyResults(prev => ({ ...prev, [payer_name]: false }));

          if (results.error) {
              setRelevancyErrors(prev => ({ ...prev, [payer_name]: results.error }));
          } else {
              setRelevancyResults(prev => ({ ...prev, [payer_name]: results }));
          }
          console.log("handleMNclick: got results with length: ", results.length);
        } catch (error) {
          console.error(error);
        }
    };

    const handleFileClick = async (externalPatientId, fileType) => {
      try {
        const response = await callApi('/getfile', accessToken, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            external_patient_id: externalPatientId, 
            file_type: fileType 
          }),
        });
    
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const newTab = window.open(url, '_blank');  // Open the PDF in a new tab
    
          // Optional: Check if the new tab was blocked by the browser
          if (newTab) {
            newTab.focus();  // Focus on the new tab if it was successfully opened
          } else {
            alert('Please allow pop-ups for this website.');  // Inform the user if the pop-up was blocked
          }
    
          // Consider delaying the revocation of the Blob URL
          setTimeout(() => {
            window.URL.revokeObjectURL(url);  // Revoke the URL after the new tab has loaded the content
          }, 100);  // Delay can be adjusted based on needs
        } else {
          console.error('Error downloading file:', response.statusText);
        }
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };

    const handleBackfillClick = async (case_id) => {
        console.log("getting case results for case_id: " + case_id);
        try {
            let response = await callApi('/codecases', accessToken, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                });
            let results = await response.json();
    
            console.log("handlebackfillclick: got results:", results);
          }
          catch (error) {
            console.error(error);
          }
    };


    return (
        <Box textAlign="center" fontSize="xl">
        <Grid
        templateAreas={`"header"    
                        "results"
                        "form"
                        "footer"`}
        gridTemplateRows={'60px auto 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='bold'
        >
            
            
                <HeaderMenus />
            
                    
            <GridItem bg='gray.50' padding={4} area={'results'} bgColor={"white"}>



      <Box fontSize="sm" fontWeight="normal" textAlign="left">


    {caseId && ( //show button that triggers handleMNClick
            <>
                <Flex alignItems="center" mb={4}>
                    <Select 
                        value={selectedPayer} 
                        onChange={handlePayerChange}
                        width="200px"
                        mr={4}
                    >
                        {payers.map((payer) => (
                            <option key={payer} value={payer}>{payer.toUpperCase()}</option>
                        ))}
                    </Select>
                    <Button
                        onClick={() => handleMNClick(caseId, selectedPayer)}
                        variant="solid"
                        size="sm"
                        colorScheme="blue"
                    >
                        Refresh
                    </Button>
                </Flex>
                <Button
                  onClick={() => handleMNClick(caseId, 'cms')}
                  variant="link"
                  size="sm"
                  colorScheme="blue"
                >
                  Refresh
                </Button>
              </>              
              )
          }


            {relevancyErrors[selectedPayer] && (
                <Box textAlign="left" ml={4} fontSize="md" fontWeight="normal">
                    Could not generate coverage analysis results: {relevancyErrors[selectedPayer]}
                </Box>
            )}


            {relevancyResults[selectedPayer] && 
                <RRelevancyResults 
                    relevancyResults={relevancyResults[selectedPayer]}
                />
            }

            {gettingRelevancyResults[selectedPayer] && (
                <Box textAlign="left" ml={4} fontSize="md" fontWeight="normal">
                    Getting coverage analysis results, 30-90 seconds...
                    <Spinner size="xs" marginLeft="8px" />
                </Box>
            )}


      {gettingRelevancyResults['cms'] && (
        <Box textAlign="left" ml={4} fontSize="md" fontWeight="normal">
          Getting coverage analysis results, 30-90 seconds...
          <Spinner size="xs" marginLeft="8px" />
        </Box>
      )}

          </Box>





            {cptmData && (
                <div>
                    <Text align="left" ml={4} fontSize="md" fontWeight="normal">{cptmData}</Text>
                </div>
            )}


            

            </GridItem>
            <GridItem gridArea="form">
            {pdfUrl ? (
                <div>
                            <Text>CMS 1500 form:</Text>
                            <iframe src={pdfUrl} width="100%" height="800px" />
                            </div>       
                    ) : (
                        <div>
                            <Text></Text>
                        </div>
                            )}
            </GridItem>


            <GridItem
              bg='white'
              borderTop='1px'
              borderColor='brand.200'
              paddingY={4}
              paddingX={6}
              area={'footer'}
            >
              <Flex justifyContent="center" alignItems="center">
                <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
                <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                  &copy; {new Date().getFullYear()}
                </Text>
              </Flex>
            </GridItem>
          </Grid>
          <ChatToggle
            contextType={'revedy'}
            contextData={'revedy_ionm_coding'}
            contextPersona={'revedy_ionm_coding'}
            firstMessage={"Hi, what can I do on this page?"}
            useOptions={false}
            maintainContext={true}
            contextMode={'popup'}
        />

        </Box>

      );
    };        


export default IONMmn;