import React, { useState, useEffect, useRef } from 'react';

function AudioVolumeVisualizer() {
  const [stream, setStream] = useState(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const canvasRef = useRef(null);

  // Start capturing the audio stream
  useEffect(() => {
    async function enableStream() {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setStream(audioStream);
      } catch (err) {
        console.error('Error accessing the microphone', err);
      }
    }

    enableStream();

    return () => {
      stream && stream.getTracks().forEach(track => track.stop());
    };
  }, []);

  // Set up the audio context and analyser
  useEffect(() => {
    if (stream) {
      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);

      source.connect(analyser);
      analyserRef.current = analyser;
      audioContextRef.current = audioContext;

      // Start visualizing
      animationFrameRef.current = requestAnimationFrame(updateVisualization);
    }

    return () => {
      audioContextRef.current && audioContextRef.current.close();
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, [stream]);

  // Update the visualization

  const MAX_BARS = 50; // The number of bars to show in the equalizer
const peakBuffer = new Array(MAX_BARS).fill(0); // Buffer to store peak values for each frequency band
const fallRate = 2; // Rate at which the bars fall from the peaks

const updateVisualization = () => {
  if (analyserRef.current && canvasRef.current) {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const analyser = analyserRef.current;
    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(dataArray);

    // Determine the frequency range for each bin
    const binSize = audioContextRef.current.sampleRate / analyserRef.current.frequencyBinCount;
    const minFrequency = 10; // Minimum frequency to visualize (e.g., 20 Hz)
    const maxFrequency = 600; // Maximum frequency to visualize (e.g., 5 kHz)

    // Calculate the range of bins to include
    const minBin = Math.floor(minFrequency / binSize);
    const maxBin = Math.floor(maxFrequency / binSize);

    // Only include bins within the desired frequency range
    for (let i = minBin; i <= maxBin; i++) {
      // Calculate the volume for the current bin
      let volume = dataArray[i];

     // Add a small offset to make the visualization look better
      // Apply a scaling factor to make the visualizer more sensitive to lower volumes
      const scalingFactor = .1; // Adjust this factor to achieve the desired sensitivity
      volume *= scalingFactor;

      // Ensure that the volume does not exceed the canvas height
      volume = Math.min(volume, canvas.height);

      // Update peaks for the bouncy effect
      const barIndex = i - minBin; // Map the bin index to the bar index
      if (volume > peakBuffer[barIndex]) {
        peakBuffer[barIndex] = volume; // Update peak value for the current bar
      } else {
        peakBuffer[barIndex] = Math.max(peakBuffer[barIndex] - fallRate, 0); // Animate falling
      }
    }

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the equalizer visualization using bars
    const barWidth = canvas.width / (maxBin - minBin + 1); // Calculate the bar width based on the number of bars
    for (let i = 0; i < MAX_BARS; i++) {
      const barHeight = peakBuffer[i];
      const x = (i + minBin) * barWidth;
      ctx.fillStyle = 'green';
      ctx.fillRect(x, canvas.height - barHeight, barWidth, barHeight);
    }

    // Loop the animation
    animationFrameRef.current = requestAnimationFrame(updateVisualization);
  }
};
  return (
    <>
      <canvas ref={canvasRef} width="200" height="30" 
      style={{
        border: '1px solid #aaa', // Thin black border
      }}
      />
    </>
  );
}

export default AudioVolumeVisualizer;