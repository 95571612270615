// APIDocs.js
import { Box, Grid, GridItem, Text, VStack, Link, Divider, Heading, Code, Container,Button, Spinner } from "@chakra-ui/react";
import React, { useState, useContext } from 'react';
import { AppContext } from '../components/AppContext'; // Adjust the import path based on your project structure
import { MenuVersionDefault } from './HeaderMenus';

function APIDocsPublic() {
    const { accessToken } = useContext(AppContext);
    const [credentials, setCredentials] = useState(null);
    const [loadingCredentials, setLoadingCredentials] = useState(false);

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box textAlign="center" fontSize="xl">
        <Grid
        templateAreas={`"header header"    
                        "main results"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'250px 4fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='bold'
        >
                {/* Header with menu */}
                    <MenuVersionDefault />

        {/* Navigation sidebar */}
        <GridItem area="main" bg='white' p={3} overflowY="auto" height="1500px">
            <br/>
            
  <VStack textAlign="left" align="start" spacing={3}>
    //header text saying Revedy API documentation
    <Heading as="h3" size="m">Revedy API Documentation</Heading>
    <Divider />
    <Link fontSize="sm" fontWeight="normal" href="#overview" onClick={(e) => scrollToSection(e, 'overview')}>Overview</Link>
    <Link fontSize="sm" fontWeight="normal" href="#endpoints" onClick={(e) => scrollToSection(e, 'endpoints')}>Endpoints</Link>
      <VStack textAlign="left" align="start" spacing={1} pl={4}>
        <Link fontSize="sm" fontWeight="normal" href="#encounter-analysis" onClick={(e) => scrollToSection(e, 'encounter-analysis')}>Encounter Analysis</Link>
        <VStack textAlign="left" align="start" spacing={1} pl={8}>
          <Text>Transcript</Text>
          <Text>Note</Text>
          <Text>Coding</Text>
        </VStack>
        <Link fontSize="sm" fontWeight="normal" href="#encounters" onClick={(e) => scrollToSection(e, 'encounters')}>Encounters</Link>
        <Link fontSize="sm" fontWeight="normal" href="#encounters_search" onClick={(e) => scrollToSection(e, 'encounters_search')}>Search Encounters</Link>
        <Link fontSize="sm" fontWeight="normal" href="#encounter" onClick={(e) => scrollToSection(e, 'specific-encounter')}>Encounter</Link>
        <Link fontSize="sm" fontWeight="normal" href="#coverage-score" onClick={(e) => scrollToSection(e, 'coverage-score')}>Coverage Analysis</Link>

        <Link fontSize="sm" fontWeight="normal" href="#generate-note-from-data" onClick={(e) => scrollToSection(e, 'generate-note-from-data')}>Generate Note from Data</Link>
        {/* Add more endpoint links as needed */}
        <Link fontSize="sm" fontWeight="normal" href="#get-recent-notes" onClick={(e) => scrollToSection(e, 'get-recent-notes')}>Get Recent Notes</Link>
      </VStack>
    <Link fontSize="sm" fontWeight="normal" href="#authentication" onClick={(e) => scrollToSection(e, 'authentication')}>Authentication</Link>
    <Link fontSize="sm" fontWeight="normal" href="#error-codes" onClick={(e) => scrollToSection(e, 'error-codes')}>Error Codes</Link>
    <Link fontSize="sm" fontWeight="normal" href="#rate-limits" onClick={(e) => scrollToSection(e, 'rate-limits')}>Rate Limits</Link>
    {/* Add more navigation links as needed */}
  </VStack>
</GridItem>

        <GridItem area="results" bg='white' p={6} ml="30px" overflowY="auto" height="700px" width="1000px">
            <VStack spacing={8} textAlign="left" align="start">
            <Box id="overview">
                <Heading as="h2" size="lg" mb={2}>Overview</Heading>
                <Text fontSize="md" >
                    The Revedy API provides a secure way for partners to programmatically access our services. Authentication is handled through a standard OAuth 2.0 process, where clients are issued tokens that grant temporary access to the API.
                </Text>
                </Box>
                <Divider />



                <Box id="endpoints">
                    <Heading as="h2" size="lg" mb={2}>Endpoints</Heading>
                </Box>

                <Divider />


                    <Box id="encounter-analysis">
    <Heading as="h3" size="md" mb={2}>Encounter Analysis</Heading>
    <Text fontSize="md">
      The <Code>/v1/encounter-analysis</Code> endpoint is designed to process various types of data (audio, transcript, or external note) 
      and perform analysis based on the specified analysis types. 
      The process involves creating an encounter record, processing the source data, and performing the requested analysis (e.g., coding).
    </Text>
    <Text fontSize="md">
      HTTP Method: <Code>POST</Code>
    </Text>
    <Text fontSize="md">
      URL: <Code>https://api.revedy.io/v1/encounter-analysis</Code>
    </Text>
    <Text fontSize="md">
      Headers:
    </Text>
    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
    <Code p={2} bg="gray.100" children={`Content-Type: multipart/form-data`} />
    <br /><br />
    <Text fontSize="md">
      Form Parameters:<br />
      One of audio, transcript, or external_note is required<br />
      All other fields are optional. If no encounter_id is provided, a unique identifier will be generated.
    </Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {
`encounter_id: Unique identifier for the encounter in your system.
external_patient_id: External identifier for the patient.
external_provider_id: External identifier for the provider.
fhir_patient_reference: FHIR reference for the patient.
fhir_practitioner_reference: FHIR reference for the practitioner.
fhir_location_reference: FHIR reference for the location.
analysis_type: The type(s) of analysis to be performed (e.g., "coding").
note_template_id: Identifier for the note template to be used.
note_guidelines_id: Identifier for the note guidelines to be followed.
encounter_type_id: Identifier for the encounter type.
audio: The audio file to be processed.
external_note: The external note to be processed. 
transcript: The transcript to be processed.
additional_data: JSON array containing additional data points.`}
    </Code>

    <br /><br />
    <Text fontSize="md">
      Additional Data Parameters:<br />
      Additional data is optional.
      It allows you to store additional patient and encounter data of any type. 
      This data will be returned with any Encounter request.
    </Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
    {`{
  "data_key": "string", // Required - Unique identifier for the data point
  "data_value": "string", // Required - Value of the data point
  "data_type": "string", // Required - Data type of the value (e.g., string, integer, date)
  "fhir_resource_type": "string", // Optional - FHIR resource type associated with the data point
  "fhir_resource_id": "string", // Optional - Unique identifier for the FHIR resource
  "fhir_path": "string", // Optional - FHIR path to locate the relevant information within the resource
  "fhir_display": "string", // Optional - Human-readable display text for the data point
  "fhir_extension_url": "string", // Optional - URL for a FHIR extension providing additional information
  "fhir_extension_value": "string" // Optional - Value for the FHIR extension
  "fhir_version": "string" // Optional - FHIR version (e.g., 4.0.1)
}`}
    </Code>

    <Text fontSize="md">
      Sample Request:
    </Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {`curl --request POST \\
--url 'https://api.revedy.io/v1/encounter-analysis' \\
--header 'Authorization: Bearer TOKENHERE' \\
--header 'Content-Type: multipart/form-data' \\
--form 'encounter_id=ZZZ447652' \\
--form 'external_patient_id=ZZZ456' \\
--form 'external_provider_id=ZZZ789' \\
--form 'fhir_patient_reference=Patient/ZZZ123' \\
--form 'fhir_practitioner_reference=Practitioner/ZZZ456' \\
--form 'fhir_location_reference=Location/ZZZ789' \\
--form 'analysis_type=coding' \\
--form 'note_template_id=TEMP123' \\
--form 'note_guidelines_id=GUIDE456' \\
--form 'encounter_type_id=TYPE789' \\
--form 'audio=@file.mp3' \\
--form 'additional_data=[
  {
    "data_key": "chief_complaint",
    "data_value": "Patient complains of severe headache and dizziness",
    "data_type": "string",
    "fhir_resource_type": "Condition",
    "fhir_resource_id": "Condition/abc123"
  },
  {
    "data_key": "patient_name",
    "data_value": "John Doe",
    "data_type": "string"
  },
  {
    "data_key": "patient_age",
    "data_value": "45",
    "data_type": "integer",
    "fhir_resource_type": "Patient",
    "fhir_path": "Patient.age"
  },
  {
    "data_key": "patient_gender",
    "data_value": "male",
    "data_type": "string",
    "fhir_resource_type": "Patient",
    "fhir_path": "Patient.gender"
  },
  {
    "data_key": "medication_list",
    "data_value": "[\"Aspirin 81mg\", \"Lisinopril 10mg\"]",
    "data_type": "json",
    "fhir_resource_type": "MedicationStatement",
    "fhir_version": "4.0.1",
    "fhir_path": "MedicationStatement.medication"
  },
  {
    "data_key": "patient_mrn",
    "data_value": "MRN123456",
    "data_type": "string"
  },
  {
    "data_key": "encounter_date",
    "data_value": "2023-06-01",
    "data_type": "date",
    "fhir_resource_type": "Encounter",
    "fhir_path": "Encounter.period.start"
  },
  {
    "data_key": "provider_name",
    "data_value": "Dr. Jane Smith",
    "data_type": "string"
  }
]'`}
    </Code>
    <Text fontSize="md">
      Response Format (pretty-printed):
    </Text>
    <Code p={2} bg="gray.100" whiteSpace="pre">
      {`{
  "coding": {
    "api_call_durations": {
      "analyze_duration": 2.6700210571289062,
      "generate_note_duration": 0.2616300582885742,
      "transcribe_audio_duration": 10.819272994995117
    },
    "codes": {
      "job_id": 574,
      "note_id": 1716,
      "request_id": "1",
      "note": "The patient is a 55-year-old male presenting with a few weeks' history of neck and...",
      "structured_transcript": "**Patient:** I'm doing all right. I've been having this pain...",
      "transcribed_test": "okay I'm doing all right I've been having this pain in...",
      "results": {
        "cpt_em": {
          "code": "99204",
          "score": "moderate",
          "patienttype": "new",
          "risk":
          {
            "high":
            {
                "likelihood score": "10",
                "reader discussion":
                {
                    "text": "there is no indication of drug therapy requiring..."
                }
            },
            "low":
            {},
            "moderate":
            {
                "likelihood score": "70",
                "reader discussion":
                {
                    "text": "the patient is being managed for suspected herniated cervical ..."
                }
            }
          },
          "problem type counts":
          {
              "4": 2
          },
          //additional data supporting the MDM determination
        },
        "icd10": {
          "M5412": {
            "diagnosis": {
              "texts": [
                {
                  "content": "the patient's symptoms and examination findings suggest a herniated ...",
                  "end": "start",
                  "start": "end"
                }
              ]
            },
            "evidence": {
              "texts": [
                {
                  "content": "the patient is a 55-year-old male ...",
                  "end": "start",
                  "start": "end"
                }
              ]
            },
            "match": {
              "explanation": "Based on the provided content, the ICD-10 code M54.12 (Radiculopathy, ...",
              "score": 100
            },
            "medication": {
              "texts": [
                {
                  "content": "prescribe a medrol dose pack for 7 days to manage inflammation.",
                  "end": "start",
                  "start": "end"
                }
              ]
            }
          }
        }
      }
    }
  }
}`}
    </Code>
    <Text fontSize="md">
      This endpoint returns a JSON object containing the CPT and ICD-10 codes extracted from the generated note, along with the durations of each API call involved in the process.
    </Text>
    <Text fontSize="md">
      Error Responses:
    </Text>
    <Code p={2} bg="gray.100" children={`400 Bad Request - No source data was included in the request.`} />
    <Code p={2} bg="gray.100" children={`500 Internal Server Error - Transcription, note generation, or analysis failed.`} />
  </Box>

        
  <Box id="encounters">
  <Heading as="h3" size="md" mb={2}>Encounters</Heading>
  <Text fontSize="md">
    The <Code>/v1/encounters</Code> endpoint retrieves a list of all encounters. Each encounter includes an external encounter ID, a unique identifier, and the creation date in ISO format.
  </Text>
  <Text fontSize="md" mt={4}>
    Example request to retrieve encounters:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`curl --request GET \\
--url 'https://api.revedy.io/v1/encounters' \\
--header 'Authorization: Bearer TOKEN'`} />
  <Text fontSize="md" mt={4}>
    Example response:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`{
  "encounters": [
    {"external_encounter_id": "ENC123", "id": "b1a4ce7f-a9e9-4f13-8df0-66a221d57b57", "create_date": "2023-01-01T12:00:00Z"},
    {"external_encounter_id": "enc2", "id": "775ab4e4-f611-4a81-b077-7658f066c793", "create_date": "2023-01-02T12:00:00Z"},
    {"external_encounter_id": "enc2a", "id": "5f7c1c23-7dd3-4d35-b290-58cbedd08f13", "create_date": "2023-01-03T12:00:00Z"},
    {"external_encounter_id": "enc2b", "id": "04f13d77-f480-438d-a04d-52807da4dcf5", "create_date": "2023-01-04T12:00:00Z"},
    {"external_encounter_id": "enc2d", "id": "81792074-9e88-4db5-aa28-a4eff0e1ad3e", "create_date": "2023-01-05T12:00:00Z"}
  ]
}`} />
  <Text fontSize="md" mt={4}>
    Error Responses:
  </Text>
  <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
</Box>

<Box id="encounters_search">
  <Heading as="h3" size="md" mb={2}>Search Encounters</Heading>
  <Text fontSize="md">
    The <Code>/v1/encounters/search</Code> endpoint allows for searching encounters based on external provider and patient IDs. This endpoint uses a POST request to ensure sensitive information is not exposed in the URL.
  </Text>
  <Text fontSize="md" mt={4}>
    Example request to search encounters:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`curl --request POST \\
--url 'https://api.revedy.io/v1/encounters/search' \\
--header 'Authorization: Bearer TOKEN' \\
--header 'Content-Type: application/json' \\
--data '{
  "external_provider_id": "PROVIDER123",
  "external_patient_id": "PATIENT456"
}'`} />
  <Text fontSize="md" mt={4}>
    Example response:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`{
  "encounters": [
    {"external_encounter_id": "ENC123", "id": "b1a4ce7f-a9e9-4f13-8df0-66a221d57b57", "create_date": "2023-01-01T12:00:00Z"},
    {"external_encounter_id": "enc2", "id": "775ab4e4-f611-4a81-b077-7658f066c793", "create_date": "2023-01-02T12:00:00Z"}
  ]
}`} />
  <Text fontSize="md" mt={4}>
    Error Responses:
  </Text>
  <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
</Box>

<Box id="specific-encounter">
  <Heading as="h3" size="md" mb={2}>Specific Encounter Details</Heading>
  <Text fontSize="md">
    The <Code>/v1/encounters/encounter_id</Code> endpoint retrieves detailed information about a specific encounter using its unique identifier.
  </Text>
  <Text fontSize="md" mt={4}>
    Example request to retrieve a specific encounter:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`curl --request GET \\
--url 'https://api.revedy.io/v1/encounters/{encounter_id}' \\
--header 'Authorization: Bearer TOKEN'`} />
  <Text fontSize="md" mt={4}>
    Example response:
  </Text>
  <Code p={2} bg="gray.100" whiteSpace="pre" children=
{`{
    "external_encounter_id": "ZZZ01",
    "external_patient_id": "PAT456",
    "external_provider_id": "DOC789",
    "fhir_location_reference": "Location/789",
    "fhir_patient_reference": "Patient/123",
    "fhir_practitioner_reference": "Practitioner/456",
    "additional_data": [
        {
            "data_key": "medication_list",
            "data_type": "json",
            "data_value": "[\\"Aspirin 81mg\\", \\"Lisinopril 10mg\\"]",
            "fhir_display": null,
            "fhir_extension_url": null,
            "fhir_extension_value": null,
            "fhir_path": "MedicationStatement.medication",
            "fhir_resource_id": null,
            "fhir_resource_type": "MedicationStatement",
            "fhir_version": "4.0.1"
        },
        {
            "data_key": "allergy_intolerance",
            "data_type": "string",
            "data_value": "Penicillin",
            "fhir_display": "Allergy to Penicillin",
            "fhir_extension_url": "http://example.org/fhir/StructureDefinition/severity",
            "fhir_extension_value": "moderate",
            "fhir_path": "AllergyIntolerance.code.text",
            "fhir_resource_id": "AllergyIntolerance/xyz789",
            "fhir_resource_type": "AllergyIntolerance",
            "fhir_version": null
        }
    ],
    "notes": [
        {
            "coding": {
                // Matches encounter-analysis
            }
        }
    ]
}`} />
  <Text fontSize="md" mt={4}>
    Error Responses:
  </Text>
  <Code p={2} bg="gray.100" children={`404 Not Found - No encounter found with the specified ID.`} />
  <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
</Box>
<Divider />

<Box id="coverage-score">
                            <Heading as="h3" size="md" mb={2}>Coverage Analysis</Heading>
                            <Text fontSize="md">
                                The <Code>/process_case</Code> endpoint processes a case to determine the coverage score based on the provided data.
                            </Text>
                            <Text fontSize="md">
                                HTTP Method: <Code>POST</Code>
                            </Text>
                            <Text fontSize="md">
                                URL: <Code>https://api.revedy.io/process_case</Code>
                            </Text>
                            <Text fontSize="md">
                                Headers:
                            </Text>
                            <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
                            <Code p={2} bg="gray.100" children={`Content-Type: application/json`} />
                            <Text fontSize="md">
                                Request Body Format:
                            </Text>
                            <Code p={2} bg="gray.100" whiteSpace="pre">
                                {`{
    "encounter_id": "unique_encounter_id",
    "payer_name": "cms" // or "cigna", "aetna"
}`}
                            </Code>
                            <Text fontSize="md">
                                Example Request:
                            </Text>
                            <Code p={2} bg="gray.100" whiteSpace="pre">
                                {`curl --request POST \\
--url 'https://api.revedy.io/process_case' \\
--header 'Authorization: Bearer YOUR_ACCESS_TOKEN' \\
--header 'Content-Type: application/json' \\
--data '{
    "encounter_id": "unique_encounter_id",
    "payer_name": "cms"
}'`}
                            </Code>
                            <Text fontSize="md">
                                Example Response:
                            </Text>
                            <Code p={2} bg="gray.100" whiteSpace="pre">
                                {`{
    "cpt": {...},
    "icd10": [...],
    "general_coverage_score": {
        "score": 0.8,
        "justification": "coverage_justification_text"
        "recommendations": "coverage_recommendations_text"
        "evidence": "coverage_evidence_text"
    }
    "detailed_coverage_scores": {
        99203 : {
            "score": 0.8,
            "justification": "coverage_justification_text"
            "recommendations": "coverage_recommendations_text"
            "evidence": "coverage_evidence_text"
        },
        ...
    }
}`}
                            </Code>
                            <Text fontSize="md">
                                Error Responses:
                            </Text>
                            <Code p={2} bg="gray.100" children={`404 Not Found - Case not found.`} />
                            <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
                            <Code p={2} bg="gray.100" children={`500 Internal Server Error - Failed to process case.`} />
                        </Box>
                    <Divider />

                    <Box id="generate-note-from-data">
                    <Heading as="h3" size="md" mb={2}>Generate Note from Data</Heading>
                        <Text fontSize="md" >
                            The <Code>/generate_note_from_data</Code> endpoint is designed to process various types of input data and generate a medical note. It supports multiple data types, including transcripts, clinician notes, chat history, additional context, and patient records. The endpoint also integrates with a workflow system that orchestrates calls to various services for code generation and validation.
                        </Text>
                        <Text fontSize="md" >
                            HTTP Method: <Code>POST</Code>
                        </Text>
                        <Text fontSize="md" >
                            URL: <Code>https://api.revedy.io/generate_note_from_data</Code>
                        </Text>
                        <Text fontSize="md" >
                            Headers:
                        </Text>
                        <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
                        <Code p={2} bg="gray.100" children={`Content-Type: application/json`} />
                        <Text fontSize="md" >
                            Request Body Format:
                        </Text>
                        <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
    "sent_data": "data_to_process",
    "external_sent_data_id": "optional_external_id",
    "transcript": "optional_transcript_data",
    "clinician notes": "optional_clinician_notes",
    "chat_history": "optional_chat_history",
    "additional_context": "optional_additional_context",
    "patient_record": "optional_patient_record"
}`}
                        </Code>
                        <Text fontSize="md" >
                            Response Format (pretty-printed):
                        </Text>
                        <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
    "generate_note_with_icd10_cpt_simple": {
        "data": "generated_note_data",
        "additional_info": "any_additional_information"
    },
// ... other workflow task results
}`}
                        </Code>
                        <Text fontSize="md" >
                            This endpoint returns a JSON object containing the results of the note generation workflow. The response includes the generated note along with any additional information from the workflow tasks.
                        </Text>
                        <Text fontSize="md" >
                            Error Responses:
                        </Text>
                        <Code p={2} bg="gray.100" children={`400 Bad Request - Client did not send sufficient data.`} />
                        <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
                        <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred while running the workflow.`} />

</Box>

<Box id="get-recent-notes">
                    <Heading as="h3" size="md" mb={2}>Get Recent Notes</Heading>
                    <Text fontSize="md" >
                        The <Code>/getrecentnotes</Code> endpoint retrieves the most recent notes created by the user, up to a limit of 30 notes. Each note includes a snippet, version information, coding status, and processing time.
                    </Text>
                    <Text fontSize="md" >
                        HTTP Method: <Code>GET</Code>
                    </Text>
                    <Text fontSize="md" >
                        URL: <Code>https://api.revedy.io/getrecentnotes</Code>
                    </Text>
                    <Text fontSize="md" >
                        Headers:
                    </Text>
                    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
                    <Text fontSize="md" >
                        Response Format (pretty-printed):
                    </Text>
                    <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
"notes": 
    [
        {
        "note_id": "unique_note_id",
        "note_snippet": "First 20 characters of the note...",
        "version_id": "version_identifier",
        "coding_status": "job_status",
        "id": "note_identifier",
        "process_time": "time_in_seconds"
        },
        // ... more notes
    ]
}`}
                    </Code>
                    <Text fontSize="md" >
                        This endpoint returns a JSON object containing an array of notes. If no notes are available, a sample note is created and returned in the response.
                    </Text>
                    <Text fontSize="md" >
                        Error Responses:
                    </Text>
                    <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
                    <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred on the server.`} />
                    {/* Add more error responses as needed */}
                    </Box>
                    


<Box id="authentication">
                    <Heading as="h2" size="lg" mb={2}>Authentication</Heading>
                    <Text fontSize="md" >
                    To access the API, clients must first obtain an access token by providing their unique client credentials. This token is then used in the Authorization header as a Bearer token to authenticate API requests.
                </Text>
                <Text fontSize="md" >
                    All interactions with the API should be conducted over HTTPS to ensure the confidentiality and integrity of the data transmitted.
                </Text>
                <Text fontSize="md" >
                    The base URL for all API endpoints is <Code>https://api.revedy.io</Code>. Below is an example of how to obtain an access token and make a request to the API.
                </Text>
                <Text fontSize="md">
                    Example request to obtain an access token:
                </Text>
                <Code p={2} bg="gray.100"  whiteSpace="pre" children=
{`curl --request POST \\
--url 'https://api.revedy.io/oauth/token' \\
--header 'content-type: application/json' \\
--data '{
    "client_id": "YOUR_CLIENT_ID",
    "client_secret": "YOUR_CLIENT_SECRET",
    "audience": "https://revedy.io/demo",
    "grant_type": "client_credentials"
}'`} />
<Text fontSize="md" mt={4}>
                    Example request to the <Code>/getrecentnotes</Code> endpoint using the access token:
                </Text>
                <Code whiteSpace="pre" p={2} bg="gray.100" children=
{`curl --request GET \\
--url 'https://api.revedy.io/getrecentnotes' \\
--header 'Authorization: Bearer YOUR_ACCESS_TOKEN'`} />
</Box>
<Divider />
                <Box id="credentials">
                    <Heading as="h2" size="lg" mb={2}>Client Credentials</Heading>
                    
                    <Text fontSize="md" >
                    <a href="https://app.revedy.io/api-docs" target="_blank">Login here for credentials access.</a>
                    </Text>
                </Box>
                <Divider />

                
{/* Error Codes Section */}
<Box id="error-codes">
<Heading as="h2" size="lg" mb={2}>Error Codes</Heading>
<Text fontSize="md">
    The API uses the following error codes:
</Text>
<Container p={2} bg="gray.50" borderRadius="md">
    <Text fontSize="sm"><Code children="no_file_part" /> No file was included in the request.</Text>
    <Text fontSize="sm"><Code children="error generating event id" /> There was an error generating the event ID.</Text>
    <Text fontSize="sm"><Code children="transcription_failed" /> The transcription of the audio file failed.</Text>
    {/* Add more error codes as needed */}
</Container>
</Box>
                
                <Divider />

                {/* Rate Limits Section */}
                <Box id="rate-limits">
                <Heading as="h2" size="lg" mb={2}>Rate Limits</Heading>
                <Text fontSize="md">
                    The API limits the number of requests that can be made to prevent abuse. If you exceed these limits, you will receive a <Code children="429" /> Too Many Requests response.
                </Text>
                </Box>
                {/* Add more sections as needed */}

            </VStack>
            </GridItem>



        {/* Footer */}
        <GridItem area="footer" bg='white' borderTop='1px' borderColor='gray.200' p={4}>
          {/* Footer content */}
        </GridItem>
      </Grid>
    </Box>
  );
}

export default APIDocsPublic;