import { useState, useContext } from 'react';
import { ChakraProvider, Box, Button, Text, Grid, GridItem, Flex, Image, VStack, Link } from '@chakra-ui/react';
import { callApi } from '../callApi'; 
import { AppContext } from '../components/AppContext';

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const { accessToken, loading, appState, setAppState } = useContext(AppContext);

  const handleAgree = async () => {
    try {
      const response = await callApi('/agree_terms', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: user.sub })
      });

      if (response.status === 200) {
        navigate('/home');
      } else {
        // Handle error
      }
    } catch (error) {
      // Handle error
    }
  };

  return (
    <ChakraProvider>
      <Box textAlign="center" fontSize="xl">
        <Grid
          templateAreas={`"header header"
                          "main main"
                          "footer footer"`}
          gridTemplateRows={'60px 1fr 60px'}
          gridTemplateColumns={'1fr'}
          h='100%'
          gap='2'
          color='blackAlpha.800'
          fontWeight='bold'
        >
          <GridItem
            bg='white'
            borderBottom='1px'
            borderColor='brand.200'
            paddingY={4}
            paddingX={6}
            area={'header'}
          >
            <Flex justifyContent="space-between" alignItems="center" width="100%" height="100%">
              <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
            </Flex>
          </GridItem>

          <GridItem
            bg='gray.50'
            padding={4}
            area={'main'}
          >
            <VStack spacing={6} align="stretch" maxW="md" mx="auto" mt={10} p={6} borderWidth={1} borderRadius="lg" boxShadow="lg">
              <Text fontSize="14" color="grey.700" align="left">
              Welcome to REVEDY! 
              Our privacy policy is available 
                 <Link color="blue.500" href="https://www.revedy.io/privacy-policy" isExternal> here</Link>.
              </Text>
              <Button colorScheme="blue" onClick={handleAgree}>
                Agree
              </Button>
            </VStack>
          </GridItem>

          <GridItem
            bg='white'
            borderTop='1px'
            borderColor='brand.200'
            paddingY={4}
            paddingX={6}
            area={'footer'}
          >
            <Flex justifyContent="center" alignItems="center">
              <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
              <Text fontSize="md" color="3e81c3" textAlign="center" marginLeft={2}>
                &copy; {new Date().getFullYear()}
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};

export default TermsAndConditions;