import React, { useState, useEffect, useContext } from 'react';
import NoSleep from 'nosleep.js';
import { callApi } from '../callApi';
import ReactMarkdown from 'react-markdown';
import {
  Box, Grid, GridItem, Flex, Image, VStack, Text, Button, Input, FormLabel, Spinner, HStack, FormControl,
  Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Link
} from "@chakra-ui/react";
import { extendTheme } from '@chakra-ui/react';
import HeaderMenus from './HeaderMenus';
import ChatToggle from './ChatToggle';
import AudioVolumeVisualizer from './AudioVolumeVisualizer';
import { AppContext } from './AppContext';
import { AddIcon } from '@chakra-ui/icons';
import { useToast } from '@chakra-ui/react';

import { CopyIcon, EditIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { useDisclosure } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';

const colors = {
  brand: {
    900: '#8ECAE6', //light blue
    800: '#219EBC', //blue
    700: '#023047', //dark blue
    600: '#FFB703', //yellow
    500: '#FB8500' //orange
  },
};

const theme = extendTheme({ colors });

function GenerateTranscript() {
  const [recordingState, setRecordingState] = useState('ready');
  const [audioData, setAudioData] = useState(null);
  const [transcribedText, setTranscribedText] = useState('');
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [timer, setTimer] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const [providerEncounterId, setProviderEncounterId] = useState('');
  const [fileSize, setFileSize] = useState(null);
  const [fileDuration, setFileDuration] = useState(null);
  const { accessToken } = useContext(AppContext);
  const isSupportedBrowser = /Chrome/.test(navigator.userAgent) || 
    (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor));
  const [fullResponse, setFullResponse] = useState(null);
  const [structuredTranscript, setStructuredTranscript] = useState('');
  const [note, setNote] = useState('');
  const [icd10Codes, setIcd10Codes] = useState([]);
  const [cptCode, setCptCode] = useState('');
  const [noteId, setNoteId] = useState('');
  const [noSleep, setNoSleep] = useState(new NoSleep());
  const [uploadedFile, setUploadedFile] = useState(null);
  


  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const handleCopy = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      toast({
        title: "Copied to clipboard",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }).catch(err => {
      console.error('Failed to copy: ', err);
      toast({
        title: "Failed to copy",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  const handleEdit = () => {
    window.open(`/note/?id=${noteId}&version=1&nid=&view=`, '_blank');
  };


  const ExportModal = () => {
    const [exportedTo, setExportedTo] = useState(null);
  
    const handleExport = (ehr) => {
      setExportedTo(ehr);
    };
  
    return (
      <Modal isOpen={isOpen} onClose={() => { onClose(); setExportedTo(null); }} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!exportedTo ? (
              <VStack spacing={4}>
                <Button 
                  leftIcon={<Image src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Epic_Systems.svg/1600px-Epic_Systems.svg.png?20220204054501" height="20px" />} 
                  width="100%"
                  onClick={() => handleExport('Epic')}
                >
                </Button>
                <Button 
                  leftIcon={<Image src="https://triceimaging.com/wp-content/uploads/2015/10/athena-health-logo1-440x61.png" height="20px" />} 
                  width="100%"
                  onClick={() => handleExport('Athena')}
                >
                </Button>
                <Button 
                  leftIcon={<Image src="https://seeklogo.com/images/N/nextgen-healthcare-logo-D35E27087E-seeklogo.com.png" height="20px" />} 
                  width="100%"
                  onClick={() => handleExport('NextGen')}
                >
                </Button>
                <Button 
                  leftIcon={<Image src="https://mma.prnewswire.com/media/1749045/Cerner_logo_Logo.jpg?w=200" height="20px" />} 
                  width="100%"
                  onClick={() => handleExport('Cerner')}
                >
                </Button>
              </VStack>
            ) : (
              <VStack spacing={4} align="center">
                <Text fontSize="lg" fontWeight="medium" color="grey.500">
                  Success, exported to the {exportedTo} encounter record.

                </Text>
                <Text>
                </Text>
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };



  useEffect(() => {
    setProviderEncounterId(Math.floor(10000000 + Math.random() * 90000000).toString());
    return () => {
      noSleep.disable(); // Disable NoSleep when component unmounts
      if (timerInterval) clearInterval(timerInterval);
    };
  }, []);

  const handleUploadedFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'audio/mpeg') {
      setUploadedFile(file);
    } else {
      alert('Please upload an MP3 file.');
      setUploadedFile(null);
    }
  };

  const transcribeUploadedFile = async () => {
    if (!uploadedFile) {
      alert('Please upload an MP3 file first.');
      return;
    }
  
    setRecordingState('transcribing');
    await sendAudioData(uploadedFile);
    setRecordingState('ready');
    setUploadedFile(null);
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const clearData = () => {
    setTranscribedText('');
    setStructuredTranscript('');
    setNote('');
    setIcd10Codes([]);
    setCptCode('');
    setFullResponse(null);
    setFileSize(null);
    setFileDuration(null);
    setNoteId('');
  };

  const startRecording = async () => {
    clearData();
    noSleep.enable(); // Enable NoSleep when recording starts
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    setMediaStream(stream);
    const recorder = new MediaRecorder(stream);
    setMediaRecorder(recorder);

    const chunks = [];
    recorder.ondataavailable = (e) => chunks.push(e.data);
    recorder.onstop = () => {
      const blob = new Blob(chunks, { type: 'audio/webm' });
      setAudioData(blob);
      setFileSize(blob.size);
    };

    recorder.start();
    setRecordingState('recording');
    startTimer();
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  };

  const stopRecording = () => {
    noSleep.disable(); // Disable NoSleep when recording stops
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaStream.getTracks().forEach(track => track.stop());
    }
    setRecordingState('stopped');
    setFileDuration(timer); // Set file duration to current timer value
    stopTimer();
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };

  const startTimer = () => {
    setTimer(0);
    const interval = setInterval(() => {
      setTimer(prevTimer => prevTimer + 1);
    }, 1000);
    setTimerInterval(interval);
  };

  const stopTimer = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }
  };

  const transcribeAudio = async () => {
    setRecordingState('transcribing');
    await sendAudioData(audioData);
    setRecordingState('ready');
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const sendAudioData = async (audioData) => {
    const formData = new FormData();
    formData.append('audio', audioData, audioData.name || 'audio.webm');
    //formData.append('audio', audioData, 'audio.webm');
    formData.append('provider_encounter_id', providerEncounterId);
    formData.append('analysis_type', 'coding');

    const response = await callApi('/v1/encounter-analysis', accessToken, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    setFullResponse(data);
    setTranscribedText(data.coding?.codes?.transcribed_text || '');
    setStructuredTranscript(data.coding?.codes?.structured_transcript || '');
    setNote(data.coding?.codes?.note || '');
    setIcd10Codes(Object.entries(data.coding?.codes?.results?.icd10 || {}));
    setCptCode(data.coding?.codes?.results?.cpt_em?.code || '');
    setNoteId(data.coding?.codes?.note_id || ''); // Set noteId

  };

  return (
    <Box textAlign="center" fontSize="xl" bg="white">
      <Grid
        templateAreas={`"header header"    
                        "main results"
                        "fullResponse fullResponse"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 800px 60px'}
        gridTemplateColumns={'1fr 1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='bold'
      >
        <GridItem area={'header'}>
          <HeaderMenus />
        </GridItem>

        <GridItem bg='gray.50' padding={4} area={'main'}>
          <Flex justifyContent="left" alignItems="left" padding="3">
            <VStack spacing={4} align="stretch" width="100%">
              <FormControl>
                <FormLabel htmlFor="provider-encounter-id">Provider Encounter ID</FormLabel>
                <Input
                  id="provider-encounter-id"
                  value={providerEncounterId}
                  isReadOnly
                />
              </FormControl>
              {isSupportedBrowser ? (
                <VStack spacing={4} align="stretch">
                  <Text>{recordingState === 'recording' ? `Recording: ${formatTime(timer)}` : 'Ready to record'}</Text>
                  <HStack>
                    <Button
                      onClick={startRecording}
                      isDisabled={recordingState !== 'ready'}
                      colorScheme="blue"
                    >
                      Start Recording
                    </Button>
                    <Button
                      onClick={stopRecording}
                      isDisabled={recordingState !== 'recording'}
                      colorScheme="red"
                    >
                      Stop Recording
                    </Button>
                  </HStack>
                  {recordingState === 'recording' && mediaStream && (
                    <AudioVolumeVisualizer stream={mediaStream} />
                  )}
                  {recordingState === 'stopped' && (
                    <VStack align="stretch">
                      <Text>Recording stopped</Text>
                      <Text>Duration: {formatTime(fileDuration)}</Text>
                      <Text>File size: {(fileSize / 1024 / 1024).toFixed(2)} MB</Text>
                      <Button onClick={transcribeAudio} colorScheme="green">Transcribe</Button>
                    </VStack>
                  )}
                  {recordingState === 'transcribing' && <Spinner />}
                </VStack>
              ) : (
                <Text>
                  The audio recording feature is not available. Please use Chrome on desktop or Safari on iOS.
                </Text>
              )}
            </VStack>
          </Flex>

        </GridItem>

        <GridItem bg='gray.50' padding={4} area={'results'} align="left">
          <VStack align="stretch" spacing={4}>
            {noteId && (
              <>                          

            <Accordion allowMultiple allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Note
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>

                    <HStack spacing={4} mb={2}>
                    <Link onClick={() => handleCopy(note)}>
                      <HStack>
                        <CopyIcon />
                        <Text>Copy</Text>
                      </HStack>
                    </Link>
                    <Link onClick={handleEdit}>
                      <HStack>
                        <EditIcon />
                        <Text>Edit</Text>
                      </HStack>
                    </Link>
                    <Link onClick={onOpen}>
                      <HStack>
                        <ExternalLinkIcon />
                        <Text>Export</Text>
                      </HStack>
                    </Link>
                  </HStack>
                  
                  <Text whiteSpace="pre-wrap">{note}</Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      CPT E/M Code
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>

                    <HStack spacing={4} mb={2}>
                    <Link onClick={() => handleCopy(note)}>
                      <HStack>
                        <CopyIcon />
                        <Text>Copy</Text>
                      </HStack>
                    </Link>
                    <Link onClick={handleEdit}>
                      <HStack>
                        <EditIcon />
                        <Text>Edit</Text>
                      </HStack>
                    </Link>
                    <Link onClick={onOpen}>
                      <HStack>
                        <ExternalLinkIcon />
                        <Text>Export</Text>
                      </HStack>
                    </Link>
                  </HStack>

                  <Text>{cptCode}</Text>
                </AccordionPanel>
              </AccordionItem>


              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      ICD-10 Codes
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>

                    <HStack spacing={4} mb={2}>
                    <Link onClick={() => handleCopy(note)}>
                      <HStack>
                        <CopyIcon />
                        <Text>Copy</Text>
                      </HStack>
                    </Link>
                    <Link onClick={handleEdit}>
                      <HStack>
                        <EditIcon />
                        <Text>Edit</Text>
                      </HStack>
                    </Link>
                    <Link onClick={onOpen}>
                      <HStack>
                        <ExternalLinkIcon />
                        <Text>Export</Text>
                      </HStack>
                    </Link>
                  </HStack>

                  {icd10Codes.map(([code, details]) => (
                    <Text key={code}>{code}: {details.description}</Text>
                  ))}
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Structured Transcript
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>

                    <HStack spacing={4} mb={2}>
                    <Link onClick={() => handleCopy(note)}>
                      <HStack>
                        <CopyIcon />
                        <Text>Copy</Text>
                      </HStack>
                    </Link>
                    <Link onClick={handleEdit}>
                      <HStack>
                        <EditIcon />
                        <Text>Edit</Text>
                      </HStack>
                    </Link>
                    <Link onClick={onOpen}>
                      <HStack>
                        <ExternalLinkIcon />
                        <Text>Export</Text>
                      </HStack>
                    </Link>
                  </HStack>

                  <Text whiteSpace="pre-wrap">{structuredTranscript}</Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Transcribed Text
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>

                    <HStack spacing={4} mb={2}>
                    <Link onClick={() => handleCopy(note)}>
                      <HStack>
                        <CopyIcon />
                        <Text>Copy</Text>
                      </HStack>
                    </Link>
                    <Link onClick={handleEdit}>
                      <HStack>
                        <EditIcon />
                        <Text>Edit</Text>
                      </HStack>
                    </Link>
                    <Link onClick={onOpen}>
                      <HStack>
                        <ExternalLinkIcon />
                        <Text>Export</Text>
                      </HStack>
                    </Link>
                  </HStack>

                <Text whiteSpace="pre-wrap">{transcribedText}</Text>
                  
                </AccordionPanel>
              </AccordionItem>

            </Accordion>
          </>)
        }

</VStack>
          <ExportModal />
        </GridItem>

        <GridItem bg='gray.50' padding={4} area={'fullResponse'} align="left" verticalAlign={'top'}>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Full Response
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {fullResponse && (
                  <Box 
                    as="pre" 
                    bg="gray.100" 
                    p={4} 
                    borderRadius="md" 
                    overflowX="auto"
                    fontSize="sm"
                  >
                    <code>{JSON.stringify(fullResponse, null, 2)}</code>
                  </Box>
                )}

                <Input
                  id="upload-audio-file-input"
                  type="file"
                  accept=".mp3,audio/mpeg"
                  onChange={handleUploadedFileChange}
                  style={{ display: 'none' }}
                />
                <Button 
                  leftIcon={<AddIcon />} 
                  onClick={() => {
                    const input = document.getElementById('upload-audio-file-input');
                    if (input) {
                      input.click();
                    }
                  }}
                  mr={2}
                >
                  {uploadedFile ? 'Change MP3 File' : 'Add MP3 File'}
                </Button>
                {uploadedFile && (
                  <Text as="span" mr={2}>
                    {uploadedFile.name}
                  </Text>
                )}

                <Button
                  onClick={transcribeUploadedFile}
                  isDisabled={!uploadedFile || recordingState === 'transcribing'}
                  colorScheme="teal"
                >
                  Transcribe Uploaded File
                </Button>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

        </GridItem>

        <GridItem
          bg='white'
          borderTop='1px'
          borderColor='brand.200'
          paddingY={4}
          paddingX={6}
          area={'footer'}
        >
          <Flex justifyContent="center" alignItems="center">
            <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
            <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
              &copy; {new Date().getFullYear()}
            </Text>
          </Flex>
        </GridItem>
      </Grid>
      <ChatToggle
        contextType={'revedy'}
        contextData={'revedy_scribe_services'}
        contextPersona={'revedy_scribe_services'}
        firstMessage={"Hi, what can I do on this page?"}
        useOptions={false}
        maintainContext={true}
        contextMode={'popup'}
      />
    </Box>    
  );
}

export default GenerateTranscript;