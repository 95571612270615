//CaseServiceTable.js
import React, { useState, useEffect, useMemo, useContext } from 'react';

import { useTable, useSortBy } from 'react-table';

import { Box, Grid, GridItem, Button } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";

import HeaderMenus from './HeaderMenus';
import { handleCaseList } from './caseService';
 
 import { callApi } from '../callApi';
  import { useNavigate } from 'react-router-dom';
   import { AppContext } from '../components/AppContext';

function CaseServiceTable({ handleCaseButtonClick }) { 
  const [cases, setCases] = useState([]);
  const [loadingCases, setLoadingCases] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 100;
  const navigate = useNavigate();
  const { accessToken, loading, appState, setAppState } = useContext(AppContext);
  const [sortConfig, setSortConfig] = useState(null);

  const totalPages = Math.ceil(cases.length / rowsPerPage);

  const sortedCases = useMemo(() => {
    if (sortConfig === null) {
      return cases;
    }
  
    const sortedData = [...cases];
    sortedData.sort((a, b) => {
      const valueA = a[sortConfig.key];
      const valueB = b[sortConfig.key];
  
      if (valueA < valueB) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  
    return sortedData;
  }, [cases, sortConfig]);


  const currentData = useMemo(() => { const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return sortedCases.slice(startIndex, endIndex);
  }, [sortedCases, currentPage, rowsPerPage]);

  const fetchCases = async (searchTerm) => {
    const urlParams = new URLSearchParams(window.location.search);
    const codingIssues = urlParams.get('i');
    
    const perIssue = 1;
    const caseId = "";

    try {
      const fetchedCases = await handleCaseList(callApi, accessToken, "", urlParams.get('i'), searchTerm, 1);
      console.log('Fetched cases:', fetchedCases);
      setCases(fetchedCases);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoadingCases(false);
  };



  const handleSort = (column) => {
    if (sortConfig && sortConfig.key === column.id) {
      // If the same column is clicked, toggle the sort direction
      setSortConfig({
        key: column.id,
        direction: sortConfig.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      // If a different column is clicked, set the new column and default sort direction
      setSortConfig({
        key: column.id,
        direction: 'asc',
      });
    }
  };

  useEffect(() => { fetchCases("");
  }, [accessToken]);

  const data = React.useMemo(() => cases, [cases]);

  function formatDateWithTimeZone(value) { if (value === null || value === undefined || value === '' || value === 'None') { return 'N/A';
  } 
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = new Date(value);
  const options = { timeZone, month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  const columns = React.useMemo( () => [ 
    { 
      Header: 'Patient ID', 
      accessor: 'external_patient_id', Cell: ({ value }) => value || 'N/A', 
    }, 
    { 
      Header: 'For Review', accessor: 'coding_issues', }, 
      { Header: 'Procedure Date', accessor: 'procedure_date', Cell: ({ value }) => formatDateWithTimeZone(value), },
      { Header: 'Initial Processing', accessor: 'create_date', Cell: ({ value }) => formatDateWithTimeZone(value), }, 
      { Header: 'Last Updated', accessor: 'updated_at', Cell: ({ value }) => formatDateWithTimeZone(value), },
      { Header: 'Last Name', accessor: 'patient_last_name', }, 
      { Header: 'Case Name', accessor: 'case_name', }, 
      { Header: 'ICD-10 Codes', accessor: 'icd10_codes', }, 
      { Header: 'Secondary Patient ID', accessor: 'external_patient_id_secondary', },
      { Header: 'Cadwell Case ID', accessor: 'case_id', },
      {
        Header: 'Coverage',
        Cell: ({ row }) => {
          // List of payers
          const payers = ["BCBS", "Medicare", "Cigna", "Aetna", "UHC"];
          // Randomly select a payer
          const randomPayer = payers[Math.floor(Math.random() * payers.length)];
          // Construct the URL with the selected payer
          const url = `ionmmn?caseid=${row.original.case_id}&payer=${randomPayer}`;
    
          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {randomPayer}
            </a>
          );
        },
      },


      {
        Header: 'Score',
        Cell: ({ row }) => {
          // List of scores
          const scores = ["0/100", "50/100", "100/100"];
          const randomScore = scores[Math.floor(Math.random() * scores.length)];
        
          return (     
            <>       
              {randomScore}
              </>
          );
        },
      },



      ], [] );

  //const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, } = useTable({ columns, data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: currentData },
    useSortBy
  );

  

function convertToCSV(data) {
  const headers = columns.map(col => col.Header).join(',');
  const rows = data.map(row =>
    columns.map(col => {
      const cellValue = row[col.accessor];
      return cellValue !== undefined && cellValue !== null ? `"${cellValue}"` : '';
      //const cellValue = row[col.accessor];
      //return cellValue !== undefined ? `"${cellValue}"` : '';
    }).join(',')
  );
  return [headers, ...rows].join('\n');
}

function downloadCSV(csvString, filename) {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function handleExportToCSV() {
  const csvString = convertToCSV(cases);
  downloadCSV(csvString, 'RevedyProcessReport.csv');
}


  return ( 
<Box textAlign="center" fontSize="xl"> 
<Grid templateAreas={`"header header" "main main" "form form" "footer footer"`} 
gridTemplateRows={'60px auto 1fr 60px'} gridTemplateColumns={'1fr 1fr'} h='100%' gap='2' color='blackAlpha.800' fontSize='14px' fontWeight='medium' > <HeaderMenus />

    <GridItem bg='gray.50' padding={4} area={'main'} verticalAlign={"bottom"}>

    {loadingCases && <>Loading...</>}
    {cases && (
      <>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="1em">
                <Box display="flex" alignItems="center">
                  <Input
                    placeholder="Search..."
                    width="140px"
                    marginRight="1em"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        fetchCases(e.target.value);
                      }
                    }}
                  />
                  <Button onClick={(e) => fetchCases(e.target.previousSibling.value)}>Search</Button>
                  <Button onClick={(e) => {
                    fetchCases('');
                    e.target.previousSibling.previousSibling.value = '';
                  }} marginLeft="1em">
                    Clear
                  </Button>
                </Box>

                <Box display="flex" justifyContent="center" marginTop="1em">
      <Button
        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}
        marginRight="1em"
      >
        Previous
      </Button>
      <span>
        Page {currentPage} of {totalPages}
      </span>
      <Button
        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        disabled={currentPage === totalPages}
        marginLeft="1em"
      >
        Next
      </Button>
    </Box>

    


                <Box>
                  <a href="/ionmtable" rel="noopener noreferrer" style={{ marginRight: '10px', marginLeft: '10px' }}>All Results</a>
                  <a href="/ionmtable?i=1" rel="noopener noreferrer" style={{ marginRight: '10px' }}>For Review</a>
                  <button onClick={handleExportToCSV}>Export to CSV</button>
                </Box>
              </Box>
    
        <Box overflowX="auto" display="flex" justifyContent="center"> {/* Center the table */}
          <table {...getTableProps()} style={{ width: '100%', borderSpacing: '0' }}> {/* Make the table a little narrower */}
          <thead>
  {headerGroups.map(headerGroup => (
    <tr {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map(column => (
        <th
          {...column.getHeaderProps()}
          onClick={() => handleSort(column)}
          style={{
            borderBottom: 'solid 3px red',
            background: 'aliceblue',
            color: 'black',
            fontWeight: 'bold',
            width: '20%',
            cursor: 'pointer',
          }}
        >
          {column.render('Header')}
          <span>
            {sortConfig && sortConfig.key === column.id
              ? sortConfig.direction === 'asc'
                ? ' 🔼'
                : ' 🔽'
              : ''}
          </span>
        </th>
      ))}
    </tr>
  ))}
</thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                if (!row.original.external_patient_id || row.original.external_patient_id === 'None') {
                  return null; // Skip rendering this row
                }
                
                if (!row) return null;
                console.log('Row:', row);

                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                  >
                  {row.cells.map((cell,index) => {
                      return (
                        <td
                          {
                            ...cell.getCellProps()
                          }
                          style={{
                            padding: '10px',
                            border: 'solid 1px gray',
                            color: index === 0 ? 'blue' : 'black',
                            background: 'white',
                            cursor: index === 0 ? 'pointer' : 'default',
                          }}
                          onClick={() => {
                            if (index === 0) {
                              const url = `/ionmform?caseid=${row.original.case_id}`;
                              window.open(url, "_blank");
                            }
                          }}
                        >
                          {
                          
                          cell.render('Cell')
                          }
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>


    </>
)}

    </GridItem>
  </Grid>
</Box>
)

}

export default CaseServiceTable;