import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Input, Button, Flex } from "@chakra-ui/react";
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';

function TextModal({ isOpen, onClose, title, content }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box whiteSpace="pre-wrap">{content}</Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function ProviderEncountersTable() {
  const [encounters, setEncounters] = useState([]);
  const [loading, setLoading] = useState(true);
  const { accessToken } = useContext(AppContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState({ title: '', content: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState([]);
  const rowsPerPage = 30;

  useEffect(() => {
    fetchEncounters();
  }, [accessToken]);

  const fetchEncounters = async () => {
    try {
      const response = await callApi('/provider_encounters', accessToken);
      const data = await response.json();
      setEncounters(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching encounters:', error);
      setLoading(false);
    }
  };

  const truncateText = (text, maxLength = 50) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text || 'N/A';
  };

  const openModal = (title, content) => {
    setModalContent({ title, content });
    onOpen();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Encounter Date',
        accessor: 'encounter_date',
        Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : 'N/A',
      },
      {
        Header: 'Encounter ID',
        accessor: 'external_encounter_id',
      },
      {
        Header: 'Patient ID',
        accessor: 'external_patient_id',
      },
      {
        Header: 'Audio Length (KB)',
        accessor: 'audio_length_kb',
      },
      {
        Header: 'Encounter Code',
        accessor: 'encounter_code',
      },

      {
        Header: 'Note',
        accessor: 'note_content',
        Cell: ({ value, row }) => (
          <Link onClick={() => openModal('Note Content', value)}>
            {`ID: ${row.original.note_id || 'N/A'} - ${truncateText(value)}`}
          </Link>
        ),
      },

      {
        Header: 'View Note',
        accessor: 'public_note_id',
        Cell: ({ value, row }) => (
          <Link 
            href={`/note/?id=${row.original.public_note_id}`}
            isExternal
            target="_blank"
            rel="noopener noreferrer"
          >
            View Note
          </Link>
        ),
      },



      {
        Header: 'Raw Transcript',
        accessor: 'raw_transcript',
        Cell: ({ value }) => (
          <Link onClick={() => openModal('Raw Transcript', value)}>
            {truncateText(value)}
          </Link>
        ),
      },
      {
        Header: 'Structured Transcript',
        accessor: 'structured_transcript',
        Cell: ({ value }) => (
          <Link onClick={() => openModal('Structured Transcript', value)}>
            {truncateText(value)}
          </Link>
        ),
      },
    ],
    []
  );



  const sortedAndFilteredData = useMemo(() => {
    let filteredData = encounters.filter((encounter) =>
      Object.values(encounter).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    if (sortBy.length) {
      const { id, desc } = sortBy[0];
      filteredData.sort((a, b) => {
        if (a[id] < b[id]) return desc ? 1 : -1;
        if (a[id] > b[id]) return desc ? -1 : 1;
        return 0;
      });
    }

    return filteredData;
  }, [encounters, searchTerm, sortBy]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return sortedAndFilteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedAndFilteredData, currentPage]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: paginatedData,
      manualSortBy: true,
      disableMultiSort: true,
      initialState: { sortBy: sortBy },
    },
    useSortBy
  );

  const handleSort = (column) => {
    const isDesc = sortBy[0]?.id === column.id && !sortBy[0]?.desc;
    setSortBy([{ id: column.id, desc: isDesc }]);
  };

  const totalPages = Math.ceil(sortedAndFilteredData.length / rowsPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <HeaderMenus />
      <Box padding={4}>
        <Flex justifyContent="space-between" mb={4}>
          <Input
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            width="300px"
          />
          <Flex>
            <Button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              mr={2}
            >
              Previous
            </Button>
            <Box alignSelf="center" mr={2}>
              Page {currentPage} of {totalPages}
            </Box>
            <Button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Flex>
        </Flex>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th {...column.getHeaderProps()} onClick={() => handleSort(column)}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    )
                  })}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Box>
      <TextModal
        isOpen={isOpen}
        onClose={onClose}
        title={modalContent.title}
        content={modalContent.content}
      />
    </Box>
  );
}

export default ProviderEncountersTable;