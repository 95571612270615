import React, { useState, useContext, useEffect } from 'react';
import { callApi } from '../callApi';
import { AppContext } from '../components/AppContext';
import ReactMarkdown from 'react-markdown';
import {
    Box, Grid, GridItem, Spinner,
    Table, Thead, Tbody, Tr, Th, Td, Button,
    Tabs, TabList, TabPanels, Tab, TabPanel,
} from "@chakra-ui/react";
import HeaderMenus from './HeaderMenus';

function AdminActivity() {
    const [chatSummaries, setChatSummaries] = useState(null);
    const [userActivity, setUserActivity] = useState(null);
    const [caseSummaries, setCaseSummaries] = useState(null);
    const { accessToken } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        handleChatSummariesButtonClick();
      }, []);

    const handleCaseSummariesButtonClick = async () => {
        setIsLoading(true);
        const response = await callApi('/getcasessummary', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        setCaseSummaries(data);
        setIsLoading(false);
    }

    const handleChatSummariesButtonClick = async () => {
        setIsLoading(true);
        const response = await callApi('/admin_org_chat_summary', accessToken, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        setChatSummaries(data);
        setIsLoading(false);
    }

    const handleUserActivityButtonClick = async (event) => {
        setIsLoading(true);
        const response = await callApi('/user_activity_summary', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log("User activity response: ", response);
        // Parse the response as JSON
        const data = await response.json();
        console.log("User activity data: ", data);

        // You can now use the data in your component state or props
        // For example:
        setUserActivity(data);
        setIsLoading(false);
    }

    const handleExportToCSV = () => {
        const headers = ['Date', 'Processed', 'Procedure'];
        const rows = caseSummaries.map(row => [row.date, row.processed, row.procedure]);
        const csvContent = [headers, ...rows].map(row => row.join(',')).join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'CaseSummaries.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return (
        <Box bg="white" fontSize="md">
            <Grid
                templateAreas={`"header header"
                                "main main"
                                "footer footer"`}
                gridTemplateRows={'60px auto 1fr 60px'}
                gridTemplateColumns={'1fr 1fr'}
                h='100%'
                gap='2'
            >
                <HeaderMenus />

                <GridItem padding={4} area={'main'}>
                <Tabs onChange={(index) => {
                    if (index === 0) {
                        handleChatSummariesButtonClick();
                    }
                    if (index === 1) {
                        handleUserActivityButtonClick();
                    }
                    if (index === 2) {
                        handleCaseSummariesButtonClick();
                    }
                }}>
                        <TabList>
                            <Tab>Chat Summaries</Tab>
                            <Tab>User Activity</Tab>
                            <Tab>Case Summaries</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                            {isLoading ? (
                            <Spinner />
                            ) : (
                                <Button
                                    variant="link"
                                    colorScheme="blue"
                                    onClick={handleChatSummariesButtonClick}
                                    fontSize={"sm"}
                                    >
                                        Refresh
                                    </Button>
                            )}
                                {chatSummaries && (
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>Case ID</Th>
                                                <Th>Chat Summary</Th>
                                                <Th>Date</Th>
                                                <Th>Email</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {Object.entries(chatSummaries)
                                                .sort((a, b) => new Date(b[1].created_at) - new Date(a[1].created_at))
                                                .map(([chatId, summary], index) => (
                                                    <Tr
                                                        key={index}
                                                        onClick={() => {
                                                            const url = `/ionmform?caseid=${summary.case_id}`;
                                                            window.open(url, '_blank');
                                                        }}
                                                    >
                                                        <Td>{summary.case_id}</Td>
                                                        <Td>
                                                            <ReactMarkdown>
                                                                {summary.chat_summary}
                                                            </ReactMarkdown>
                                                        </Td>
                                                        <Td>{summary.email}</Td>
                                                        <Td>{summary.created_at}</Td>
                                                    </Tr>
                                                ))}
                                        </Tbody>
                                    </Table>
                                )}
                            </TabPanel>

                            <TabPanel>
                            {isLoading ? (
                            <Spinner />
                            ) : (
                                <Button
                                    variant="link"
                                    colorScheme="blue"
                                    onClick={handleUserActivityButtonClick}
                                    fontSize={"sm"}
                                    >
                                        Refresh
                                    </Button>
                            )}
                                {userActivity && (
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                            <Th>Email</Th>
                                            <Th>Last Activity Date</Th>
                                            <Th>Average Daily Usage</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {userActivity.user_summaries
                                            .sort((a, b) => new Date(b.last_activity_date) - new Date(a.last_activity_date))
                                            .map((row, index) => (
                                                <Tr key={index}>
                                                <Td>{row.email}</Td>
                                                <Td>{row.last_activity_date}</Td>
                                                <Td>{row.average_daily_usage}</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                )}

                            </TabPanel>
                            <TabPanel>
                            {isLoading ? (
                            <Spinner />
                            ) : (
                                <Button
                                    variant="link"
                                    colorScheme="blue"
                                    onClick={handleCaseSummariesButtonClick}
                                    fontSize={"sm"}
                                >
                                    Refresh
                                </Button>
                            )}
                                <br />

                                {caseSummaries && (
                                    <>
                                        <Button
                                            variant="link"
                                            colorScheme="blue"
                                            onClick={handleExportToCSV}
                                            fontSize={"sm"}
                                        >
                                            Export to CSV
                                        </Button>
                                        <Table variant="simple">
                                            <Thead>
                                                <Tr>
                                                    <Th>Date</Th>
                                                    <Th>Processed Date</Th>
                                                    <Th>Procedure Date</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                            {caseSummaries
                                                .sort((a, b) => new Date(b.date) - new Date(a.date))
                                                .map((row, index) => (
                                                    <Tr key={index}>
                                                        <Td>{row.date}</Td>
                                                        <Td>{row.processed}</Td>
                                                        <Td>{row.procedure}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </>
                                )}
                            </TabPanel>

                        </TabPanels>
                    </Tabs>
                </GridItem>
            </Grid>
        </Box>
    );
}

export default AdminActivity;