import React, { useState, useEffect, useContext } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, Link } from "@chakra-ui/react";
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';

function IONMNTable() {
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(true);
  const { accessToken } = useContext(AppContext);

  useEffect(() => {
    fetchIONMCases();
  }, [accessToken]);

  const fetchIONMCases = async () => {
    try {
      const response = await callApi('/ionm_cases', accessToken);
      const data = await response.json();
      setCases(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching IONM cases:', error);
      setLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Case ID',
        accessor: 'p_id',
      },
      {
        Header: 'Carrier',
        accessor: 'carrier',
      },
      {
        Header: 'Score',
        accessor: 'score',
      },
      {
        Header: 'Details',
        accessor: 'details',
        Cell: ({ row }) => (
          <Link href={`/ionmmn?caseid=${row.original.case_id}`} isExternal>
            View Details
          </Link>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: cases }, useSortBy);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <HeaderMenus />
      <Box padding={4}>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    )
                  })}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}

export default IONMNTable;