import React, { useState } from 'react';
import { 
  TextEntry
} from './TextEntry';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './quill.snow-custom.css';
import ReactQuill from 'react-quill';
import './custom.css';
import ProtectedRoute from './components/ProtectedRoute';
import BackendCheck from './components/BackendCheck';
import TermsAndConditions from './components/TermsAndConditions';
import CodeIOM from './components/IOM';
import IONMmn from './components/IONMmn';
import GenerateNote from './components/GenerateNote';
import GenerateTranscript from './components/GenerateTranscript';
import AuthRequired from './components/AuthRequired';
import Chat from './components/Chat';
import LoginPage from './loginpage';
import Admin from './components/Admin';
import AdminLocal from './components/AdminLocal';
import AdminActivity from './components/AdminActivity';
import AnalyticsPage from './components/Analytics';
import AnalyticsDemoPage from './components/AnalyticsDemo';
import APIDocs from './components/APIDocs';
import APIDocsPublic from './components/APIDocsPublic';
import CaseServiceTable from './components/CaseServiceTable';
import { WebSocketProvider } from './components/WebSocket';
import HomePage from './components/DefaultHome';
import { AppContextProvider } from './components/AppContext'; // adjust the import based on your project structure
import CreateNote from './components/CreateNote';
import ABN from './components/ABN';
import FullCode from './components/FullCode';
import IONMText from './components/IONMText';
import StripeCheckout from './components/StripeCheckout';
import FileUpload from './components/FileUpload';
import EncounterAnalysis from './components/EncounterAnalysis';
import EncounterList from './components/EncounterList';
import StripeThankYou from './components/StripeThankYou';
import ContentManagement from './components/ContentManagement';
import ProductDocs from './components/ProductDocs';
import IONMbp from './components/IONMbp';
import IONMMNTable from './components/IONMMNTable';
import RevedyWelcome from './components/RevedyWelcome';
import CPTCoding from './components/CPTCoding';
import CPTLookup from './components/CPTLookup';
import CMSSourceWebContent from './components/CMSSourceWebContent';
import ExternalIONMCase from './components/IONMExternalCase';
import LogViewer from './components/LogViewer';
import EncountersTable from './components/ProviderEncountersTable';
import CognitiveAssessment from './components/APICognitiveAssessment';

import {
  extendTheme,
  ChakraProvider,
  Box,
} from '@chakra-ui/react';
import AdminReview from './components/AdminReview';
import ReviewerDashboard from './components/ReviewerDashboard';
import AdminEncountersList from './components/AdminEncountersList';
import NoteEdit from './components/NoteEdit';
import {ExistingNoteAnalysis} from './components/NoteAnalysis';

const colors = {
  brand: {
    900: '#8ECAE6', //light blue
    800: '#219EBC', //blue
    700: '#023047', //dark blue
    600: '#FFB703', //yellow
    500: '#FB8500' //orange
  },
};

const theme = extendTheme({
  colors,
  components: {
    Text: {
      baseStyle: {
        fontSize: "sm",
        fontWeight: "normal",
      },
    },
  },
});


function App() {
  const [dataReceived, setDataReceived] = useState(false);

  const [results, setResults] = useState(undefined);
  
  const handleDataReceived = (results) => {
    console.log ("data received");    
    setDataReceived(true);
    setResults(results);
  };

  const [PTdataReceived, PTsetDataReceived] = useState(false);
  const [PTresults, PTsetResults] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const PThandleDataReceived = (PTresults) => {
    console.log ("data received");    
    PTsetDataReceived(true);
    PTsetResults(PTresults);
  };


  return (
    <Router>
      <AppContextProvider>
        <Routes>        
          <Route path="/test" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
            <BackendCheck>
            <WebSocketProvider>
              <ChakraProvider theme={theme}>
                <Box>
                  <ReactQuill />
                </Box>
              </ChakraProvider>
              </WebSocketProvider>
            </BackendCheck>
            </ProtectedRoute>
            } />
            
          <Route path="/note" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
            <BackendCheck>
              <WebSocketProvider>
                  <ChakraProvider theme={theme}>
                    <TextEntry onDataReceived={handleDataReceived} results={results} />
                  </ChakraProvider>
                </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

          <Route path="/scpt" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
            <BackendCheck>
              <WebSocketProvider>
                  <ChakraProvider theme={theme}>
                    <div> n/a </div>
                  </ChakraProvider>
                </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

          <Route path="/terms" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
              <TermsAndConditions />
            </ProtectedRoute>
          } />

          <Route  path="/" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
              <BackendCheck>
                <WebSocketProvider>
                  <ChakraProvider theme={theme}>
                      <HomePage />
                  </ChakraProvider>
                </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />  
          
          <Route path="/login" element={
            <ChakraProvider theme={theme}>
              <LoginPage errorMessage={errorMessage}/>
            </ChakraProvider>
          } />

            <Route path="/welcome" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                      <ChakraProvider theme={theme}>
                          <RevedyWelcome />
                      </ChakraProvider>
              </ProtectedRoute>
            } />


            <Route path="/content" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>              
                    <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <ContentManagement />
                      </ChakraProvider>
                    </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />

            <Route path="/ionmform" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>              
                    <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <CodeIOM />
                      </ChakraProvider>
                    </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />


            <Route path="/ionmcase" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>              
                    <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <ExternalIONMCase />
                      </ChakraProvider>
                    </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />

            <Route path="/logviewer" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>              
                    <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <LogViewer />
                      </ChakraProvider>
                    </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />

          <Route path="/encounters" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>              
                    <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <EncountersTable />
                      </ChakraProvider>
                    </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />



            <Route path="/ionmmntable" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>              
                    <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <IONMMNTable />
                      </ChakraProvider>
                    </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />



            <Route path="/ionmbp" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>              
                    <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <IONMbp />
                      </ChakraProvider>
                    </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />


            <Route path="/ionmmn" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>              
                    <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <IONMmn />
                      </ChakraProvider>
                    </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />


            <Route path="/ionmtable" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <CaseServiceTable />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

            <Route path="/code" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <FullCode />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

            <Route path="/ea" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <EncounterAnalysis />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />


            <Route path="/el" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <EncounterList />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

            <Route path="/cptsurgical" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <CPTCoding />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

            <Route path="/cptcms" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <CPTLookup />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

          <Route path="/cmstrack" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <CMSSourceWebContent />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

            <Route path="/ionmtext" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <IONMText />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

            <Route path="/securefiles" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <FileUpload />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />



            <Route path="/home" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <HomePage />
                      </ChakraProvider>
                    </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

            <Route path="/analytics" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                          <AnalyticsPage />
                      </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />

            <Route path="/api" element={
                    <ChakraProvider theme={theme}>
                      <APIDocsPublic />
                    </ChakraProvider>
            } />


            <Route path="/support" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                      <ProductDocs />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />


            <Route path="/api-docs" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                      <APIDocs />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />

            <Route path="/analyticsdemo" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                          <AnalyticsDemoPage />
                      </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />





          <Route path="/generate" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <GenerateNote />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />
          <Route path="/chat" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <Chat />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

          <Route path="/transcribe" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <GenerateTranscript />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />
          <Route path="/create-note" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
              <BackendCheck>
                <WebSocketProvider>
                  <ChakraProvider theme={theme}>
                    <CreateNote />
                  </ChakraProvider>
                </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />


          <Route path="/checkout" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
              <BackendCheck>
                <WebSocketProvider>
                  <ChakraProvider theme={theme}>
                    <StripeCheckout />
                  </ChakraProvider>
                </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />


          <Route path="/thankyou" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
              <BackendCheck>
                <WebSocketProvider>
                  <ChakraProvider theme={theme}>
                    <StripeThankYou />
                  </ChakraProvider>
                </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

          <Route path="/admin" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <Admin />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

          <Route path="/admin-activity" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <AdminActivity />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />




        <Route path="/adminlocal" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <AdminLocal />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />



          <Route path="/abn/:docId" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <ABN />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />



          <Route path="/prior" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                    <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <AuthRequired />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />
          <Route path="/denials" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <AuthRequired />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

          <Route path="/CPT2024" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <AuthRequired />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

          <Route path="/transition" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <AuthRequired />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

        <Route path="/adminreview/:encounterId" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <AdminReview />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

        <Route path="/reviewer" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <ReviewerDashboard />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

        <Route path="/adminreviewlist" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <AdminEncountersList />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

          <Route path="/noteedit" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <NoteEdit />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

          <Route path="/noteanalyze/:noteId" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <ExistingNoteAnalysis />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

        <Route path="/cognitive-api" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <CognitiveAssessment />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />


        </Routes>
      </AppContextProvider> 
    </Router>
  );
}

export default App;
