import React, { useCallback, useContext, useEffect, useState } from 'react';
import { callApi } from '../callApi'; 
import { ChakraProvider, Box, Grid, GridItem, 
    Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel, Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Spinner,
    Heading,
    Divider, 
 } from "@chakra-ui/react";
import { extendTheme } from '@chakra-ui/react';
import AccountMenu from '../account';
import HeaderMenus from './HeaderMenus';
import { setIn } from 'formik';
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"
import { useTable, useSortBy } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext'; // adjust the import based on your project structure
import ChatToggle from './ChatToggle'; // Adjust the import path as necessary

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    ScatterChart,
    ZAxis,
    Scatter,
    LabelList,
} from 'recharts';

function AnalyticsPage() {
    const { accessToken, loading, appState, setAppState } = useContext(AppContext);
    const [statusHistory, setStatusHistory] = useState(null);
    const navigate = useNavigate();
    

    // Dummy data for charts
  const dummyFHIRData = [
    { name: 'Jan', transactions: 400 },
    { name: 'Feb', transactions: 300 },
    { name: 'Mar', transactions: 500 },
    // ... add more data points as needed
  ];

  const dummyHL7Data = [
    { name: 'ADT', count: 240 },
    { name: 'ORM', count: 456 },
    { name: 'ORU', count: 789 },
    // ... add more data points as needed
  ];

  const dummyEHRData = [
    { name: 'Epic', value: 400 },
    { name: 'Nextgen', value: 300 },
    { name: 'Cerner', value: 300 },
    // ... add more data points as needed
  ];

  const dummyUserGrowthData = [
    { month: 'Oct', users: 120 },
    { month: 'Nov', users: 150 },
    { month: 'Dec', users: 200 },
    { month: 'Jan', users: 250 },
    { month: 'Feb', users: 300 },
    { month: 'Mar', users: 350 },
    // ... add more data points as needed
  ];

  
  // ... (Keep your existing dummy data for FHIR, HL7, and EHR) ...

  // Sample users for the Users table
  const sampleUsers = [
    { id: 1, name: 'John Doe', email: 'john.doe@example.com', role: 'Physician' },
    { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com', role: 'Nurse' },
    // ... add more sample users as needed
  ];

  const sampleUseCases = [
    { id: 1, useCase: 'Telemedicine Appointments', uniqueUsers: 50, totalVolume: 300, clinicalAdmin: 80, satisfactionRevenue: 70, totalPatients: 200 },
    { id: 2, useCase: 'Automated Billing System', uniqueUsers: 30, totalVolume: 150, clinicalAdmin: 20, satisfactionRevenue: 60, totalPatients: 150 },
    // ... add more sample use cases as needed
  ];
  

    // Colors for PieChart
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];




    const getStatusHistory = useCallback(async () => {
        const response = await callApi('/status_history', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },            
        });
        const data = await response.json();
        setStatusHistory(data);
    }, [callApi]);

    useEffect(() => {
        getStatusHistory();
    }, []);

    return (
        <Box textAlign="center" fontSize="xl">
            <Grid
                templateAreas={`"header header"    
                                "main main"
                                "footer footer"`}
                gridTemplateRows={'60px auto 1fr 60px'}
                gridTemplateColumns={'1fr 1fr'}
                h='100%'
                gap='2'
                color='blackAlpha.800'
                fontSize='12px'
            >
                <HeaderMenus />
                <GridItem bg='gray.50' padding={4} area={'main'} verticalAlign={"bottom"}>


{/* New Tabs Section */}
<Tabs>
            <TabList>
              <Tab>Integrations</Tab>
              <Tab>Users</Tab>
              <Tab>Activity</Tab>
              <Tab>FHIR/HL7</Tab>
              <Tab>Embed</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
              <Button onClick={getStatusHistory}>Refresh</Button>
                    {statusHistory && (
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>Message</Th>
                                    <Th>Type</Th>
                                    <Th>Timestamp</Th>
                                    <Th>Data</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {statusHistory.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map((statusMessage, index) => (
                                    <Tr key={index}>
                                        <Td>{statusMessage.message}</Td>
                                        <Td>{statusMessage.type}</Td>
                                        <Td>{new Date(statusMessage.timestamp).toLocaleString(undefined, {weekday: 'short', 
                                        year: 'numeric', 
                                        month: 'short', 
                                        day: 'numeric', 
                                        hour: '2-digit', 
                                        minute: '2-digit', 
                                        second: '2-digit',
                                        timeZoneName: 'short',
                                        })}</Td>
                                        <Td>
                                            {Array.isArray(statusMessage.data) ? (
                                                statusMessage.data.map((item, index) => (
                                                    <div key={index}>{item}</div>
                                                ))
                                            ) : (
                                                statusMessage.data
                                            )}
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    )}
              </TabPanel>
            <TabPanel>
              {/* 
                <Box>
                  <Heading as="h3" size="lg" textAlign="left">User Growth Over Time</Heading>
                  <Divider my={4} />
                  <BarChart width={730} height={300} data={dummyUserGrowthData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="users" fill="#8884d8" />
                  </BarChart>
                </Box>
                <Box mt={8}>
                  <Heading as="h3" size="lg" textAlign="left">Sample Users</Heading>
                  <Divider my={4} />
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>ID</Th>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Role</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {sampleUsers.map((user) => (
                        <Tr key={user.id}>
                          <Td>{user.id}</Td>
                          <Td>{user.name}</Td>
                          <Td>{user.email}</Td>
                          <Td>{user.role}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
                */}
              </TabPanel>

              <TabPanel>
                {/*
    <Box>
      <Heading as="h3" size="lg" textAlign="left">Activity Insights</Heading>
      <Divider my={4} />
      <ScatterChart width={730} height={300} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
        <CartesianGrid />
        <XAxis type="number" dataKey="clinicalAdmin" name="Clinical-Admin" unit="%" label={{ value: 'Clinical Focus vs. Administrative Load', position: 'insideBottom', offset: -10 }} />
        <YAxis type="number" dataKey="satisfactionRevenue" name="Satisfaction-Revenue" unit="%" label={{ value: 'Patient Satisfaction vs. Revenue', angle: -90, position: 'insideLeft' }} />
        <ZAxis type="number" range={[100, 400]} dataKey="totalPatients" name="Total Patients" />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Legend />
        <Scatter name="Use Cases" data={sampleUseCases} fill="#8884d8" shape="circle">
          <LabelList dataKey="useCase" position="top" />
        </Scatter>
      </ScatterChart>
      <Box mt={8}>
        <Heading as="h3" size="lg" textAlign="left">Recent Use Cases</Heading>
        <Divider my={4} />
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Use Case</Th>
              <Th>Unique Users</Th>
              <Th>Total Volume</Th>
              <Th>Clinical-Admin</Th>
              <Th>Satisfaction-Revenue</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sampleUseCases.map((useCase) => (
              <Tr key={useCase.id}>
                <Td>{useCase.id}</Td>
                <Td>{useCase.useCase}</Td>
                <Td>{useCase.uniqueUsers}</Td>
                <Td>{useCase.totalVolume}</Td>
                <Td>{useCase.clinicalAdmin}%</Td>
                <Td>{useCase.satisfactionRevenue}%</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
     */}
          </TabPanel>





              <TabPanel>
                {/* FHIR/HL7 Content (existing charts and tables) */}
                {/* ... (Keep your existing FHIR and HL7 charts here) ... */}
              </TabPanel>
              <TabPanel>
                {/* Embed Schematic Example */}
                {/* Placeholder for schematic example */}
              </TabPanel>
            </TabPanels>
          </Tabs>

                    
                </GridItem>
            </Grid>
            
          <ChatToggle
            contextType={'revedy'}
            contextData={'revedy_integration_services'}
            contextPersona={'revedy_integration_services'}
            firstMessage={"Hi, what can I do on this page?"}
            useOptions={false}
            maintainContext={true}
            contextMode={'popup'}
        />
        </Box>
    );
}

export default AnalyticsPage;