import React, { useState, useContext, useEffect } from 'react';
import { callApi } from '../callApi';
import { AppContext } from './AppContext';
import { useLocation } from 'react-router-dom';
import { ChakraProvider, Box, Grid, GridItem, 
    Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel, Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Spinner,
    Link,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,

 } from "@chakra-ui/react";
import HeaderMenus from './HeaderMenus';

const StripeThankYou = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id');
  //log the session id to console
  console.log('SESSION ID: ', sessionId);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { accessToken } = useContext(AppContext);
  const handleCheckoutSuccess = async () => {
    try {
      const response = await callApi('/checkout_success', accessToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ session_id: sessionId }),
      });
  
      // Handle the response from the backend
      // ...
    } catch (error) {
      // Handle any errors
      setError('An error occurred.: ' + error.message);
    }
  };
  useEffect(() => {
    if (sessionId) {
      console.log('calling handleCheckoutSuccess from useEffect');
      handleCheckoutSuccess();
    }
  }, [sessionId]);

  return (

<Box textAlign="center" fontSize="xl">
<Grid
templateAreas={`"header header"    
                "main main"
                "footer footer"`}
gridTemplateRows={'60px 1fr 60px'}
gridTemplateColumns={'1fr 1fr'}
h='100%'
gap='2'
color='blackAlpha.800'
fontWeight='bold'
>
        {/* Header with menu */}
            <HeaderMenus />

    <GridItem bg='gray.50' padding={4} area={'main'}>
      <Text fontSize="lg" color="black" textAlign="center">
        Thank you for your purchase! Your access is now enabled. 
      </Text>
      <Text fontSize="lg" color="blue" textAlign="center">
        <Link href="/api-docs" >
          Please review the API documentation here
        </Link> 
      </Text>
    </GridItem>

    <GridItem
      bg='white'
      borderTop='1px'
      borderColor='brand.200'
      paddingY={4}
      paddingX={6}
      area={'footer'}
    >
      <Flex justifyContent="center" alignItems="center">
        <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
        <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
          &copy; {new Date().getFullYear()}
        </Text>
      </Flex>
    </GridItem>
  </Grid>
</Box>

  );
};

export default StripeThankYou;
