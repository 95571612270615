import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

// Create a WebSocket context
const WebSocketContext = createContext(null);

// WebSocket provider component
export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize WebSocket connection
    const newSocket = io(process.env.REACT_APP_REVEDY_API_HOST, {
      reconnectionAttempts: 5,
      reconnectionDelay: 5000,
    });

    setSocket(newSocket);

    // Clean up on unmount
    return () => {
      newSocket.close();
    };
  }, []);

  // Provide the socket and any additional functionality
  const value = {
    socket,
    // You can add more functions here that use the socket
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Custom hook to use the WebSocket context
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (context === null) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};