// ChatToggle.js
import React, { useState, useEffect } from 'react';
import { Box, Grid, Text } from "@chakra-ui/react";
import Chat from './Chat';
import HeaderMenus from './HeaderMenus';

const ChatToggle = ({ contextType, contextData, contextPersona, firstMessage = null, 
useOptions = false,
maintainContext = false,
contextMode = 'popup',
toggleState = false, // New prop to toggle the chat
messageToSend = null, // New prop for the message content
triggerSubmit = false, // New prop to trigger message submission
}) => {
  const [isChatOpen, setIsChatOpen] = useState(toggleState);


  useEffect(() => {
    console.log('ChatToggle State trigger:', { isChatOpen });
    console.log('ChatToggle Props trigger:', { messageToSend, triggerSubmit });
  }, [isChatOpen, messageToSend, triggerSubmit]);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <>
      {/* Chat Icon */}
      <Box
        as="button"
        position="fixed"
        bottom="2rem"
        right="2rem"
        zIndex="100"
        onClick={toggleChat}
        p="0.5rem"
        borderRadius="50%"
        bg="blue.500"
        color="white"
        fontSize="2xl"
        boxShadow="md"
        _hover={{ bg: "blue.600" }}
        aria-label="Toggle chat"
      >
        <Text>💬</Text>
      </Box>

      {/* Chat Interface */}
      {isChatOpen && (
        <Box
          position="fixed"
          bottom="4rem"
          right="2rem"
          zIndex="99"
          bg="white"
          p="1rem"
          boxShadow="lg"
          borderRadius="md"
          maxW="300px"
          maxH="400px"
          overflowY="auto"
        >
          <Chat
            chatOnly={true}
            contextType={contextType}
            contextData={contextData}
            contextPersona={contextPersona}
            firstMessage={firstMessage}
            useOptions={useOptions}
            maintainContext={maintainContext}
            contextMode={contextMode}
            messageToSend={messageToSend}
            triggerSubmit={triggerSubmit}
          />
          <Box
            as="button"
            position="absolute"
            top="0.5rem"
            right="0.5rem"
            onClick={toggleChat}
            fontSize="lg"
            aria-label="Close chat"
          >
            ✖️
          </Box>
        </Box>
      )}
    </>
  );
};

export default ChatToggle;