import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Text, MenuDivider } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate } from 'react-router-dom';

const CapabilitiesMenu = () => {
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
  };

  return (
    <Menu>
      <MenuButton as={Text} rightIcon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        Capabilities
      </MenuButton>
      <MenuList>
        <MenuItem fontSize="sm" color="black" onClick={() => handleItemClick('/note')}>
          Detailed Coding Analysis
        </MenuItem>
        <MenuItem fontSize="sm" color="black" onClick={() => handleItemClick('/patient-notes')}>
          Patient-Friendly Notes (Link not provided, using placeholder)
        </MenuItem>
        <MenuItem fontSize="sm" color="black" onClick={() => handleItemClick('/ionmform')}>
          IONM CPT Coding
        </MenuItem>
        <MenuItem fontSize="sm" color="black" onClick={() => handleItemClick('/generate')}>
          Note Generation
        </MenuItem>
        <MenuItem fontSize="sm" color="black" onClick={() => handleItemClick('/chat')}>
          AI-Powered Chat Assistance
        </MenuItem>
        <MenuItem fontSize="sm" color="black" onClick={() => handleItemClick('/transcribe')}>
          Scribe Services
        </MenuItem>
        <MenuDivider />
        <MenuItem fontSize="sm" color="gray.500" isDisabled>
          Upcoming Features
        </MenuItem>
        <MenuItem fontSize="sm" color="black" onClick={() => handleItemClick('/prior')}>
          Prior Authorization (Coming Soon)
        </MenuItem>
        <MenuItem fontSize="sm" color="black" onClick={() => handleItemClick('/transition')}>
          Care Transitions (Coming Soon)
        </MenuItem>
        <MenuItem fontSize="sm" color="black" onClick={() => handleItemClick('/denials')}>
          Denial Responses (Coming Soon)
        </MenuItem>
        <MenuItem fontSize="sm" color="black" onClick={() => handleItemClick('/CPT2024')}>
          CPT 2024 Training (Coming Soon)
        </MenuItem>
        <MenuItem fontSize="sm" color="gray.500" isDisabled>
          Admin
        </MenuItem>
        <MenuItem fontSize="sm" color="black" onClick={() => handleItemClick('/analytics')}>
          Integration Status
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default CapabilitiesMenu;