// ProductDocs.js
import React, { useState } from 'react';
import {
  Box,
  Flex,
  VStack,
  Text,
  Heading,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { ChevronRightIcon } from '@chakra-ui/icons';
import HeaderMenus from './HeaderMenus'; // Adjust the import path as needed

function ProductDocs() {
  const [selectedContent, setSelectedContent] = useState('ionm-coding-issues');

  const renderContent = () => {
    if (selectedContent === 'ionm-coding-issues') {
      return (
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="xl">IONM Coding Issues</Heading>
          
          <Box>
            <Heading as="h2" size="lg" mb={2}>Baseline verbiage missing</Heading>
            <Text fontSize="md">
              Chat transcript lacks clear documentation of baseline setting and final interpretation times. Critical for accurate monitoring duration calculation. Review full chat log to identify these key points or consult with the medical team for clarification.
            </Text>
          </Box>
          <Divider />
    
          <Box>
            <Heading as="h2" size="lg" mb={2}>Missing EMG</Heading>
            <Text fontSize="md">
              EMG mentioned in the technical report, but corresponding data absent from the case file. Verify if EMG was performed and locate the data. Potential data transmission or upload issues.
            </Text>
          </Box>
          <Divider />
  
          <Box>
            <Heading as="h2" size="lg" mb={2}>Missing EMG Data</Heading>
            <Text fontSize="md">
              Partial EMG data present, but incomplete for all reported body areas. Review case to ensure all performed EMG tests are documented. Critical for accurate CPT code assignment based on the number and location of EMG tests.
            </Text>
          </Box>
          <Divider />
    
          <Box>
            <Heading as="h2" size="lg" mb={2}>Missing MEP Data</Heading>
            <Text fontSize="md">
              MEP testing performed, but data incomplete for all tested body areas. Review to ensure comprehensive documentation of all MEP tests. Impacts CPT code selection for upper limbs, lower limbs, or both.
            </Text>
          </Box>
          <Divider />
  
          <Box>
            <Heading as="h2" size="lg" mb={2}>Missing NCT</Heading>
            <Text fontSize="md">
              NCT or screw testing performed, but relevant traces or detailed data absent. Ensure all NCT data is properly recorded and associated with the case. Critical for accurate coding of pedicle screw placement or nerve proximity testing procedures.
            </Text>
          </Box>
          <Divider />
  
          <Box>
            <Heading as="h2" size="lg" mb={2}>Missing SSEP</Heading>
            <Text fontSize="md">
              SSEP testing cited in the technical report, but data not found in the case file. Confirm SSEP performance and locate the data. Essential for accurate SSEP procedure coding.
            </Text>
          </Box>
          <Divider />
  
          <Box>
            <Heading as="h2" size="lg" mb={2}>Other</Heading>
            <Text fontSize="md">
              Issues not categorized elsewhere. May involve EMG data anomalies, duration calculation discrepancies, or case-specific peculiarities requiring manual review. Thorough case review needed, potentially requiring consultation with the medical team.
            </Text>
          </Box>
        </VStack>
      );
    } else {
      return <Text>Select a topic from the sidebar to view documentation.</Text>;
    }
  };

  return (
    <Box>
      <HeaderMenus />
      <Flex>
        {/* Sidebar Navigation */}
        <Box width="300px" bg="gray.50" height="calc(100vh - 60px)" overflowY="auto" borderRight="1px" borderColor="gray.200">
          <Accordion allowMultiple defaultIndex={[0]}>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Coding
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Accordion allowMultiple>
                  <AccordionItem>
                    <h3>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          IONM
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h3>
                    <AccordionPanel pb={4}>
                      <Text 
                        cursor="pointer" 
                        onClick={() => setSelectedContent('ionm-coding-issues')}
                        _hover={{ bg: "gray.100" }}
                        p={2}
                      >
                        <ChevronRightIcon /> Coding Issues
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h3>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          E/M
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h3>
                    <AccordionPanel pb={4}>
                      {/* Add E/M content links here */}
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h3>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          ICD-10
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h3>
                    <AccordionPanel pb={4}>
                      {/* Add ICD-10 content links here */}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Chat
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {/* Add Chat subcategories and topics here */}
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Scribe
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {/* Add Scribe subcategories and topics here */}
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Medical Necessity
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {/* Add Medical Necessity subcategories and topics here */}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>

        {/* Content Area */}
        <Box flex={1} p={6} overflowY="auto" height="calc(100vh - 60px)">
          {renderContent()}
        </Box>
      </Flex>
    </Box>
  );
}

export default ProductDocs;