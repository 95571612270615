import { Menu, MenuButton, MenuList, MenuItem, Text, Button, Icon, Spinner, Box, List, ListItem } from "@chakra-ui/react";
import { useState, useEffect } from 'react';
import { handleNoteList } from './noteService'; // adjust the import based on your project structure
import { callApi } from './callApi';
import { useAccessToken } from './useAccessToken'; 

import { CheckIcon, SpinnerIcon } from "@chakra-ui/icons";

function NoteServiceList({ NeditorRef, navigate, handleNoteButtonClick, handleCreateNoteButtonClick, noteDisplay='menu' }) {
  const [notes, setNotes] = useState([]);
  const { accessToken, loading } = useAccessToken();

  const fetchNotes = async () => {
    const notes = await handleNoteList(accessToken);
    setNotes(notes);
  };


  if (noteDisplay === 'menu') {
  return (
    <Menu>
      <MenuButton as={Text} color="blue" fontSize="sm" onClick={fetchNotes}>
        Your Notes
      </MenuButton>
      <MenuList>
        <MenuItem>
          <Text
            fontSize="sm"
            color="blue.500"
            marginLeft={4}
            onClick={() => handleCreateNoteButtonClick(NeditorRef, navigate, 0)}
            _hover={{ textDecoration: "underline" }}
          >
            Use Your Own Note
          </Text>
        </MenuItem>
          {notes.map((note, index) => (
            <MenuItem key={index} fontSize="sm">
              {/* Conditionally render the green checkmark if coding_status is 'completed' */}
              {note.coding_status === 'completed' && (
                <Icon as={CheckIcon} color="green.500" mr="2" /> // Use Chakra UI's Icon with CheckIcon
              )}
                {note.coding_status === 'running' && (
                  <>
                  <Spinner size="sm" color="green.500" mr="2" /> 
                  <Text fontSize="sm" mr="2"> 
                  {Math.min(100, (note.process_time / 150) * 100).toFixed(0)}% 
                </Text>
                </>
              )}
              
              <a
                href={`/note/?id=${note.note_id}&version=${note.version_id}`}
                style={{ color: 'blue', textDecoration: 'underline' }}
                onClick={(event) => {
                  event.preventDefault();
                  handleNoteButtonClick(NeditorRef, navigate, note.note_id, note.version_id);
                }}
              >
                {`${note.note_id}-${note.version_id} , ${note.note_snippet}`}
              </a>
            </MenuItem>
          ))}
        </MenuList>
    </Menu>
  );
}
else if (noteDisplay === 'running') {
  return (
    <Box>
    <Text
      fontSize="sm"
      color="blue.500"
      cursor="pointer"
      _hover={{ textDecoration: "underline" }}
      onClick={fetchNotes}
    >
      Coding Status
    </Text>
      <List spacing={3}>
        {notes.filter(note => note.coding_status === 'running').map((note, index) => (
          <ListItem key={index} fontSize="sm" display="flex" alignItems="center">
            <Spinner size="sm" color="green.500" mr="2" />
            <Text fontSize="sm" mr="2">
              {Math.min(100, (note.process_time / 150) * 100).toFixed(0)}%
            </Text>
            <a
              href={`/note/?id=${note.note_id}&version=${note.version_id}`}
              style={{ color: 'blue', textDecoration: 'underline' }}
              onClick={(event) => {
                event.preventDefault();
                handleNoteButtonClick(NeditorRef, navigate, note.note_id, note.version_id);
              }}
            >
              {`${note.note_id}-${note.version_id}, ${note.note_snippet}`}
            </a>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
}

export default NoteServiceList;