import React, { useState, useContext } from 'react';
import { callApi } from '../callApi'; 
import { AppContext } from '../components/AppContext';

import { ChakraProvider, Box, Grid, GridItem, 
    Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel, Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Spinner,

 } from "@chakra-ui/react";
import { extendTheme } from '@chakra-ui/react';
import AccountMenu from '../account';
import CaseServiceList from './CaseServiceList';
import HeaderMenus from './HeaderMenus';
import { connectWebSocket } from '../utils';
import { setIn } from 'formik';


const colors = {
    brand: {
      900: '#8ECAE6', //light blue
      800: '#219EBC', //blue
      700: '#023047', //dark blue
      600: '#FFB703', //yellow
      500: '#FB8500' //orange
    },
  };
  
  // Extend the theme
  const theme = extendTheme({ colors });

  
function AuthRequired() {
    const [inputData, setInputData] = useState('');
    const [otherData, setOtherData] = useState('');
    const [resultsReceived, setResultsReceived] = useState(false);
    const [resultsmReceived, setResultsmReceived] = useState(false);
    const [generatedNote, setGeneratedNote] = useState('');
    const [patientContent, setPatientContent] = useState('');
    const { accessToken, loading, appState, setAppState } = useContext(AppContext);

    return (
        <Box textAlign="center" fontSize="xl">
        <Grid
        templateAreas={`"header"    
                        "main"
                        "footer"`}
        gridTemplateRows={'60px auto60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='bold'
        >
            <GridItem
                bg='black' // Change the background color to black
                borderBottom='1px'
                borderColor='brand.200'
                paddingY={4} // Increase the vertical padding
                paddingX={6}
                area={'header'}
            >                
            <HeaderMenus />
            </GridItem>
        
            <GridItem bg='gray.50' padding={4} area={'main'}>

                    <Box marginBottom={4} marginRight={4} bgColor={"white"}>
                            <Text
                            fontSize="sm"
                            color="blue.500"
                            >
                                This feature requires authorization, please request access from your admininstrator.
                            </Text>
                    </Box>

                
            </GridItem>

            <GridItem
              bg='white'
              borderTop='1px'
              borderColor='brand.200'
              paddingY={4}
              paddingX={6}
              area={'footer'}
            >
              <Flex justifyContent="center" alignItems="center">
                <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
                <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                  &copy; {new Date().getFullYear()}
                </Text>
              </Flex>
            </GridItem>
          </Grid>
        </Box>
      );
    };        


export default AuthRequired;