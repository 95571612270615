import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Menu, MenuButton, MenuList, MenuItem, Text, Spinner, Flex } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

const AccountMenu = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return isAuthenticated ? (
    <Flex spacing={40}>
      <Menu>
        <MenuButton as={Text} rightIcon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="white">
          Features
        </MenuButton>
        <MenuList>
          <MenuItem fontSize="sm" color="black">
            <Link to="/note">Code Note</Link>
          </MenuItem>
          <MenuItem fontSize="sm" color="black">
            <Link to="/generate">Generate Note</Link>
          </MenuItem>
          <MenuItem fontSize="sm" color="black">
            <Link to="/ionmform">Code IOM</Link>
          </MenuItem>
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton as={Text} rightIcon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="white">
          Account
        </MenuButton>
        <MenuList>
          <MenuItem isDisabled fontSize="sm" color="black">{user.email}</MenuItem>
          <MenuItem onClick={handleLogout} fontSize="sm" color="black">Log Out</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  ) : null;
};

export default AccountMenu;