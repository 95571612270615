import React, { useState, useContext, useEffect } from 'react';
import { useToast, Box, Text, Button, Grid, GridItem, Flex, Input, InputGroup, InputLeftElement, Icon, Select, VStack, HStack } from "@chakra-ui/react";
import { callApi } from '../callApi';
import { AppContext } from './AppContext';
import HeaderMenus from './HeaderMenus';
import RelevancyResults from './IONMMedicalNecessity';
import { FiFile, FiX, FiDownload } from 'react-icons/fi';

function IonmText() {
  const [processing, setProcessing] = useState(false);
  const [relevancyResults, setRelevancyResults] = useState(null);
  const [files, setFiles] = useState([]); // Changed to array for multiple files
  const [cases, setCases] = useState([]);
  const [caseFiles, setCaseFiles] = useState([]);
  const [selectedPayer, setSelectedPayer] = useState(null);

  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await callApi('/get_ionm_cases', accessToken);
        const data = await response.json();
        console.log("get_ionm_cases data: ", data); 
        if (data && data.cases) {
          setCases(data.cases);
        }
      } catch (error) {
        console.error('Error fetching cases:', error);
        toast({
          title: 'Fetching cases failed.',
          description: "There was an error fetching the cases.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchCases();
  }, [accessToken, toast]);









  const handleSampleDocs = async () => {
    setProcessing(true);
    setRelevancyResults(null);
    try {
      const response = await callApi('/process_ionm_pdf_sample', accessToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          payer_name: selectedPayer || 'cms'
        }),
      });
  
      const data = await response.json();      
      setRelevancyResults(data);
  
      if (data.case_files) {
        setCaseFiles(data.case_files);
      }
  
      toast({
        title: 'Sample docs processed.',
        description: "The sample documents have been successfully processed.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error processing sample docs:', error);
      toast({
        title: 'Processing failed.',
        description: "There was an error processing the sample documents.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };


  const handleCaseClick = async (caseId, payer) => {
    setProcessing(true);
    setRelevancyResults(null);
    setFiles([]);
    try {
      const response = await callApi('/process_ionm_pdf', accessToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          case_id: caseId,
          payer_name: payer
         }),
      });

      const data = await response.json();      
      setRelevancyResults(data);

      if (data.case_files) {
        setCaseFiles(data.case_files);
      }

      toast({
        title: 'Case processed.',
        description: "The case has been successfully processed.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error processing case:', error);
      toast({
        title: 'Processing failed.',
        description: "There was an error processing the case.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
  };

  const handleFileDownload = async (fileId, filename) => {
    try {
      const response = await callApi('/get_ionm_file', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          ionm_case_id: relevancyResults.case_id, 
          document_id: fileId 
        }),
      });
  
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error('Error downloading file:', response.statusText);
        toast({
          title: 'Download failed',
          description: "There was an error downloading the file.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      toast({
        title: 'Download failed',
        description: "There was an error downloading the file.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  

  const removeFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleFileUpload = async () => {
    if (files.length === 0) {
      toast({
        title: 'No files selected.',
        description: "Please select PDF files to upload.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setProcessing(true);
    setRelevancyResults(null);

    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append('file', file);  // Change this line
    });

    try {
      const response = await callApi('/process_ionm_pdf', accessToken, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      setRelevancyResults(data);    
      setFiles([]);


      if (data.case_files) {
        setCaseFiles(data.case_files);
      }
      
      toast({
        title: 'Processing successful.',
        description: "The files have been processed.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error processing ionm files:', error);
      toast({
        title: 'Processing failed.',
        description: "There was an error processing your files.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  return (

    <Box fontSize="xl">
        <Grid
          templateAreas={`"header header"    
                          "main results"
                          "footer footer"`}
          gridTemplateRows={'60px 1fr 60px'}
          gridTemplateColumns={'1fr'}
          h='100%'
          gap='2'
          color='blackAlpha.800'
          
        >
                    <HeaderMenus />

        <GridItem bg='white' padding={4} area={'main'}>
          <Box fontSize="sm">
          <Grid templateColumns="1fr 1fr" gap={4}>
  <GridItem>

    <Box position="relative">
        <HStack spacing={4} align="left" width={'100%'} verticalAlign={'middle'} alignItems={'center'}>
          <Text fontSize="lg" fontWeight="bold">Load&nbsp;Existing&nbsp;Case:</Text>
        <Select placeholder="Select a case" onChange={(e) => handleCaseClick(e.target.value, 'cms')}>
          {cases.map((caseItem) => (
            <option key={caseItem.id} value={caseItem.id}>
              {caseItem.case_name} {caseItem.id}
            </option>
          ))}
        </Select>

        {relevancyResults && (
            <>
            <Text fontSize="lg" fontWeight="bold" mt={4} mb={2}>
              <Button onClick={() => setRelevancyResults(null)}>Upload New Case</Button>
            </Text>                        
            </>
        )}
        <Text fontSize="lg" fontWeight="bold" mt={4} mb={2}>or</Text>
          <Button
        onClick={handleSampleDocs}
        disabled={processing}
        colorScheme="green"
        width="40%"
      >
        Use Sample
      </Button>


        </HStack>
    </Box>
    <br/>
    {processing && (
        <Text>Processing...</Text>
      )      
    }
    {!relevancyResults && !processing && (
        <>
          <Box position="relative">
            <Text fontSize="lg" fontWeight="bold">Upload files to code a new case:</Text>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={FiFile} color="gray.300" />}
              />
              <Input
                type="text"
                value={files.length > 0 ? `${files.length} file(s) selected - click here to add more` : ''}
                placeholder="Choose files"
                _placeholder={{ color: 'gray.500' }}
                readOnly
              />
            </InputGroup>
            <Input
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
              disabled={processing}
              position="absolute"
              top={0}
              left={0}
              opacity={0}
              width="100%"
              height="100%"
              cursor="pointer"
              multiple
            />
          </Box>
          {files.length > 0 && (
              <VStack align="stretch" mt={2}>
                {files.map((file, index) => (
                  <Flex key={index} justify="space-between" align="center">
                    <Text isTruncated maxWidth="80%">{file.name}</Text>
                    <Button size="sm" onClick={() => removeFile(index)}>
                      <Icon as={FiX} />
                    </Button>
                  </Flex>
                ))}
              </VStack>
          )}
          <Text fontSize="sm" color="gray.500">PDF, txt, and docx files are supported. We recommend tech reports, chat transcripts, and procedure details.
          </Text>
          <Button
              onClick={handleFileUpload}
              disabled={processing || files.length === 0}
              colorScheme="blue"
              width="40%"
              alignSelf={'flex-end'}
              mt={2}
          >
            {processing ? 'Processing...' : 'Code Case Using These Files'}
          </Button>  
        </>
    )}


  {caseFiles.length > 0 && relevancyResults && (
      <>  
        <VStack align="stretch">
          <Box mt={4} width={'75%'} verticalAlign={'top'}>
            <Text fontSize="lg" fontWeight="bold">Case ID: {relevancyResults.case_id}

            </Text>
            <VStack align="stretch" mt={2}>
              {caseFiles.map((file) => (
                <Flex key={file.id} justify="space-between" align="center">
                  <Text isTruncated maxWidth="80%">{file.filename}</Text>
                  <Button 
                    size="sm" 
                    leftIcon={<Icon as={FiDownload} />}
                    onClick={() => handleFileDownload(file.id, file.filename)}
                  >
                    Download
                  </Button>
                </Flex>
              ))}
            </VStack>
          </Box>
        </VStack>
      </>
    )}

  </GridItem>
  
  <GridItem>
    <VStack align="stretch">

    </VStack>
    
  </GridItem>
</Grid>


            <br/>
            {relevancyResults && <RelevancyResults 
            relevancyResults={relevancyResults} 
            caseId={relevancyResults.case_id}
            handleCaseClick={handleCaseClick}
            selectedPayer={selectedPayer}
            setSelectedPayer={setSelectedPayer}
            />}


          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default IonmText;