import React, { useState, useContext } from 'react';
import { callApi } from '../callApi';
import { AppContext } from '../components/AppContext';
import { ChakraProvider, Box, Grid, GridItem, Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel, Spinner, IconButton, 
    List, ListItem
 } from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import AccountMenu from '../account';
import HeaderMenus from './HeaderMenus';
import ChatToggle from './ChatToggle';
import { FormControl } from '@chakra-ui/react';

function EncounterAnalysis() {
    const [additionalData, setAdditionalData] = useState('');
    const [externalNote, setExternalNote] = useState('');
    const [audioFiles, setAudioFiles] = useState([]);
    const [transcriptSegments, setTranscriptSegments] = useState(['']);
    const [analysisResults, setAnalysisResults] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { accessToken } = useContext(AppContext);

    const handleDataSubmit = async (externalEncounterId, externalPatientId, externalProviderId, fhirPatientReference, fhirPractitionerReference, fhirLocationReference, additionalData, externalNote, audioFiles, transcriptSegments) => {
        setIsLoading(true);
        setAnalysisResults(null);

        const formData = new FormData();
        formData.append('encounter_id', externalEncounterId);
        formData.append('external_patient_id', externalPatientId);
        formData.append('external_provider_id', externalProviderId);
        formData.append('fhir_patient_reference', fhirPatientReference);
        formData.append('fhir_practitioner_reference', fhirPractitionerReference);
        formData.append('fhir_location_reference', fhirLocationReference);
        formData.append('additional_data', additionalData);
        formData.append('external_note', externalNote);
        formData.append('analysis_type', 'coding');

        audioFiles.forEach(audioFile => {
            formData.append('audio', audioFile);
        });

        transcriptSegments.forEach(segment => {
            formData.append('transcript', segment);
        });

        const response = await callApi('/v1/encounter-analysis', accessToken, {
            method: 'POST',
            body: formData,
        });

        if (response.ok) {
            const data = await response.json();
            setAnalysisResults(data);
        } else {
            // Handle errors
        }

        setIsLoading(false);
    };

    const handleAddTranscriptSegment = () => {
        setTranscriptSegments([...transcriptSegments, '']);
    };

    const handleRemoveTranscriptSegment = (index) => {
        const updatedSegments = [...transcriptSegments];
        updatedSegments.splice(index, 1);
        setTranscriptSegments(updatedSegments);
    };

    const handleTranscriptSegmentChange = (index, value) => {
        const updatedSegments = [...transcriptSegments];
        updatedSegments[index] = value;
        setTranscriptSegments(updatedSegments);
    };
    const handleAddAudioFile = (file) => {
        setAudioFiles([...audioFiles, file]);
    };

    const handleAudioFileChange = (e) => {
        if (e.target.files[0]) {
            handleAddAudioFile(e.target.files[0]);
        }
    };

    const handleRemoveAudioFile = (index) => {
        const updatedFiles = [...audioFiles];
        updatedFiles.splice(index, 1);
        setAudioFiles(updatedFiles);
    };

     return (
        <Box textAlign="center" fontSize="xl">
            <Grid
                templateAreas={`"header header"
                                "main results"
                                "footer footer"`}
                gridTemplateRows={'60px 1fr 60px'}
                gridTemplateColumns={'300px 1fr'}
                h='100%'
                gap='2'
                color='blackAlpha.800'
                fontWeight='bold'
            >
                <HeaderMenus />

                <GridItem bg='gray.50' padding={4} area={'main'}>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleDataSubmit(
                            e.target.externalEncounterId.value,
                            e.target.externalPatientId.value,
                            e.target.externalProviderId.value,
                            e.target.fhirPatientReference.value,
                            e.target.fhirPractitionerReference.value,
                            e.target.fhirLocationReference.value,
                            additionalData,
                            externalNote,
                            audioFiles,
                            transcriptSegments
                        );
                    }}>
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>External Encounter ID</FormLabel>
                                <Input
                                    type="text"
                                    name="externalEncounterId"
                                    defaultValue={Math.floor(1000 + Math.random() * 9000).toString()}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>External Patient ID</FormLabel>
                                <Input
                                    type="text"
                                    name="externalPatientId"
                                    defaultValue={Math.floor(1000 + Math.random() * 9000).toString()}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>External Provider ID</FormLabel>
                                <Input
                                    type="text"
                                    name="externalProviderId"
                                    defaultValue={Math.floor(1000 + Math.random() * 9000).toString()}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>FHIR Patient Reference</FormLabel>
                                <Input
                                    type="text"
                                    name="fhirPatientReference"
                                    defaultValue={Math.floor(1000 + Math.random() * 9000).toString()}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>FHIR Practitioner Reference</FormLabel>
                                <Input
                                    type="text"
                                    name="fhirPractitionerReference"
                                    defaultValue={Math.floor(1000 + Math.random() * 9000).toString()}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>FHIR Location Reference</FormLabel>
                                <Input
                                    type="text"
                                    name="fhirLocationReference"
                                    defaultValue={Math.floor(1000 + Math.random() * 9000).toString()}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Additional Data</FormLabel>
                                <Textarea
                                    bgColor="white"
                                    placeholder="Enter additional data here"
                                    value={additionalData}
                                    onChange={e => setAdditionalData(e.target.value)}
                                    rows={4}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>External Note</FormLabel>
                                <Textarea
                                    bgColor="white"
                                    placeholder="Enter external note here"
                                    value={externalNote}
                                    onChange={e => setExternalNote(e.target.value)}
                                    rows={10}
                                />
                            </FormControl>





                            <FormControl>
                        <FormLabel>Audio Files</FormLabel>
                        <Input
                            id="audio-file-input"
                            type="file"
                            accept="audio/*"
                            onChange={handleAudioFileChange}
                            style={{ display: 'none' }}
                        />
                        <Button leftIcon={<AddIcon />} onClick={() => document.getElementById('audio-file-input').click()}>
                            Add Audio File
                        </Button>
                        <List spacing={2} mt={2}>
                            {audioFiles.map((file, index) => (
                                <Flex key={index} alignItems="center">
                                    <ListItem>{file.name}</ListItem>
                                    <IconButton
                                        icon={<DeleteIcon />}
                                        aria-label="Remove audio file"
                                        onClick={() => handleRemoveAudioFile(index)}
                                        size="sm"
                                        ml={2}
                                        colorScheme="red"
                                    />
                                </Flex>
                            ))}
                        </List>
                    </FormControl>

                                    <FormControl>
                                        <FormLabel>Transcript Segments</FormLabel>
                                        {transcriptSegments.map((segment, index) => (
                                            <Flex key={index} alignItems="center" mb={2}>
                                                <Textarea
                                                    bgColor="white"
                                                    placeholder={`Transcript Segment ${index + 1}`}
                                                    value={segment}
                                                    onChange={(e) => handleTranscriptSegmentChange(index, e.target.value)}
                                                    rows={4}
                                                    mr={2}
                                                />
                                                <IconButton
                                                    icon={<DeleteIcon />}
                                                    aria-label={`Remove Transcript Segment ${index + 1}`}
                                                    onClick={() => handleRemoveTranscriptSegment(index)}
                                                    size="sm"
                                                    variant="ghost"
                                                    colorScheme="red"
                                                />
                                            </Flex>
                                        ))}
                                        <Button
                                            leftIcon={<AddIcon />}
                                            onClick={handleAddTranscriptSegment}
                                            size="sm"
                                            variant="outline"
                                            colorScheme="blue"
                                        >
                                            Add Transcript Segment
                                        </Button>
                                    </FormControl>


                            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
                                Analyze Encounter
                            </Button>
                        </VStack>
                    </form>
                </GridItem>

                <GridItem bg='gray.50' padding={4} area={'results'} maxW="1400px">
    {isLoading ? (
        <Spinner />
    ) : analysisResults ? (
        analysisResults.restructured_data ? (
            <Grid templateColumns="1fr 1fr" gap={4}>
                <Box textAlign="left" overflowX={"auto"}>
                    <Text fontWeight="bold">Results:</Text>
                    <pre>{JSON.stringify(analysisResults.results, null, 2)}</pre>
                </Box>
                <Box textAlign="left" overflowX={"auto"}>
                    <Text fontWeight="bold">Restructured Data:</Text>
                    <pre>{JSON.stringify(analysisResults.restructured_data, null, 2)}</pre>
                </Box>
            </Grid>
        ) : (
            <Box textAlign="left" overflowX={"auto"}>
                <Text fontWeight="bold">Analysis Results:</Text>
                <pre>{JSON.stringify(analysisResults, null, 2)}</pre>
            </Box>
        )
    ) : null}
</GridItem>
                <GridItem
                    bg='white'
                    borderTop='1px'
                    borderColor='brand.200'
                    paddingY={4}
                    paddingX={6}
                    area={'footer'}
                >
                    <Flex justifyContent="center" alignItems="center">
                        <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
                        <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                            &copy; {new Date().getFullYear()}
                        </Text>
                    </Flex>
                </GridItem>
            </Grid>
            <ChatToggle
                contextType={'revedy'}
                contextData={'revedy_encounter_analysis'}
                contextPersona={'revedy_encounter_analysis'}
                firstMessage={"Hi, what can I do on this page?"}
                useOptions={false}
                maintainContext={true}
                contextMode={'popup'}
            />
        </Box>
    );
}

export default EncounterAnalysis;