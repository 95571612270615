import React, { createContext, useState, useContext } from 'react';

// Create a new context
const FeatureFlagsContext = createContext();

// Create a provider component
export const FeatureFlagsProvider = ({ children, value }) => {
  const [featureFlags, setFeatureFlags] = useState(value);

  return (
    <FeatureFlagsContext.Provider value={{ featureFlags, setFeatureFlags }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

// Create a custom hook to access the context
export const useFeatureFlags = () => useContext(FeatureFlagsContext);