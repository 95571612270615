import React, { useState, useContext } from 'react';
import { 
  ChakraProvider, Box, Grid, GridItem, Flex, Image, VStack, Text, Input, Button, 
  Heading, Spinner, useToast, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon
} from "@chakra-ui/react";
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';
import ChatToggle from './ChatToggle';
import { extendTheme } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';


const colors = {
  brand: {
    900: '#8ECAE6', //light blue
    800: '#219EBC', //blue
    700: '#023047', //dark blue
    600: '#FFB703', //yellow
    500: '#FB8500' //orange
  },
};

const theme = extendTheme({ colors });

function CPTLookup() {
  const [cptCode, setCptCode] = useState('');
  const [cptData, setCptData] = useState(null);
  const [processing, setProcessing] = useState(false);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  const handleLookup = async () => {
    setProcessing(true);
    setCptData(null);

    try {
      const response = await callApi(`/cpt_lookup?cpt_code=${cptCode}`, accessToken, {
        method: 'GET',
      });

      const data = await response.json();
      if (response.ok) {
        setCptData(data);
        toast({
          title: 'Lookup successful',
          description: 'CPT code data has been retrieved.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Lookup failed',
          description: data.error || 'An error occurred while fetching data',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error looking up CPT code:', error);
      toast({
        title: 'Lookup failed',
        description: 'There was an error processing your request.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const renderCPTData = () => {
    if (!cptData) return null;

    return (
      <Accordion allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading size="md">Basic CPT Data</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text><strong>CPT Code:</strong> {cptData.cpt_code}</Text>
            <Text><strong>Concept ID:</strong> {cptData.concept_id}</Text>
            <Text><strong>Clinician Description:</strong> {cptData.clinician_description}</Text>
            <Text><strong>Patient Description:</strong> {cptData.patient_description}</Text>
          </AccordionPanel>
        </AccordionItem>

        {cptData.ama_cpt_data && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading size="md">AMA CPT Data</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text><strong>Long Description:</strong> {cptData.ama_cpt_data.long_description}</Text>
              <Text><strong>Valid From:</strong> {new Date(cptData.ama_cpt_data.valid_from).toLocaleDateString()}</Text>
              <Text><strong>Valid To:</strong> {cptData.ama_cpt_data.valid_to ? new Date(cptData.ama_cpt_data.valid_to).toLocaleDateString() : 'Present'}</Text>
            </AccordionPanel>
          </AccordionItem>
        )}

        {cptData.mcd_article_data && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading size="md">MCD Article Data</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text><strong>Number of related MCD articles:</strong> {cptData.mcd_article_data.count}</Text>
              {cptData.mcd_article_data.sample_articles && cptData.mcd_article_data.sample_articles.length > 0 && (
                <Box mt={2}>
                  <Text fontWeight="bold">Sample MCD Articles:</Text>
                  {cptData.mcd_article_data.sample_articles.map((article, index) => (
                    <Box key={index} mt={2} pl={4}>
                        <Text><strong>Article ID:</strong> {article.article_id}</Text>
                        <Text><strong>Article Version:</strong> {article.article_version}</Text>
                        <Text><strong>Display ID:</strong> {article.display_id}</Text>
                        <Text><strong>Long Description:</strong> {article.long_description}</Text>
                        <Text><strong>Short Description:</strong> {article.short_description}</Text>
                        <Text><strong>Publication Date:</strong> {article.publication_date ? new Date(article.publication_date).toLocaleDateString() : 'N/A'}</Text>
                        <Text><strong>Effective Date:</strong> {article.effective_date ? new Date(article.effective_date).toLocaleDateString() : 'N/A'}</Text>
                        <Text><strong>End Date:</strong> {article.end_date ? new Date(article.end_date).toLocaleDateString() : 'N/A'}</Text>
                        <Text><strong>Status:</strong> {article.status}</Text>
                    </Box>


                  ))}
                </Box>
              )}
            </AccordionPanel>
          </AccordionItem>
        )}

        {cptData.lcd_data && cptData.lcd_data.length > 0 && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading size="md">LCD Data</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {cptData.lcd_data.map((lcd, index) => (
                <Box key={index} mt={2}>
                  <Text><strong>LCD ID:</strong> {lcd.lcd_id}</Text>
                  <Text><strong>LCD Version:</strong> {lcd.lcd_version}</Text>
                  <Text><strong>Long Description:</strong> {lcd.long_description}</Text>
                  <Text><strong>Short Description:</strong> {lcd.short_description}</Text>
                </Box>
              ))}
            </AccordionPanel>
          </AccordionItem>
        )}

        {cptData.rev_lcd_data && cptData.rev_lcd_data.length > 0 && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading size="md">Revedy LCD Data</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {cptData.rev_lcd_data.map((revLcd, index) => (
                <Box key={index} mt={2}>
                  <Text><strong>LCD ID:</strong> {revLcd.lcd_id}</Text>
                  <Text><strong>Relevancy Score:</strong> {revLcd.relevancy_score}</Text>
                  <Text><strong>Relevant:</strong> {revLcd.relevant}</Text>
                  <Text><strong>Relevancy Source:</strong> {JSON.stringify(revLcd.relevancy_source)}</Text>
                </Box>
              ))}
            </AccordionPanel>
          </AccordionItem>
        )}

        {cptData.icd10_data && cptData.icd10_data.length > 0 && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading size="md">ICD-10 Data</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {cptData.icd10_data.map((icd10, index) => (
                <Box key={index} mt={2}>
                  <Text><strong>ICD-10 Code:</strong> {icd10.icd10_code}</Text>
                  <Text><strong>Relevancy Score:</strong> {icd10.relevancy_score}</Text>
                  <Text><strong>Relevancy Source:</strong> {JSON.stringify(icd10.relevancy_source)}</Text>
                </Box>
              ))}
            </AccordionPanel>
          </AccordionItem>
        )}

        {cptData.hcpcs_data && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading size="md">HCPCS Data</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text><strong>Long Description:</strong> {cptData.hcpcs_data.long_description}</Text>
              <Text><strong>Short Description:</strong> {cptData.hcpcs_data.short_description}</Text>
              <Text><strong>Pricing Indicator Code:</strong> {cptData.hcpcs_data.pricing_indicator_code}</Text>
              <Text><strong>Multiple Pricing Indicator Code:</strong> {cptData.hcpcs_data.multiple_pricing_indicator_code}</Text>
              <Text><strong>Coverage Code:</strong> {cptData.hcpcs_data.coverage_code}</Text>
              <Text><strong>Berenson-Eggers Type of Service Code:</strong> {cptData.hcpcs_data.berenson_eggers_type_of_service_code}</Text>
              <Text><strong>Type of Service Code:</strong> {cptData.hcpcs_data.type_of_service_code}</Text>
              <Text><strong>Anesthesia Base Unit Quantity:</strong> {cptData.hcpcs_data.anesthesia_base_unit_quantity}</Text>
              <Text><strong>Add Date:</strong> {cptData.hcpcs_data.add_date}</Text>
              <Text><strong>Action Effective Date:</strong> {cptData.hcpcs_data.action_effective_date}</Text>
              <Text><strong>Termination Date:</strong> {cptData.hcpcs_data.termination_date}</Text>
              <Text><strong>Action Code:</strong> {cptData.hcpcs_data.action_code}</Text>
            </AccordionPanel>
          </AccordionItem>
        )}






{cptData.cms_source_data && cptData.cms_source_data.length > 0 && (
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <Heading size="md">CMS Source Web Content</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Accordion allowMultiple>
        {Object.entries(cptData.cms_source_data.reduce((acc, item) => {
          item.matching_groups.forEach(group => {
            if (!acc[group]) acc[group] = [];
            acc[group].push(item);
          });
          return acc;
        }, {})).map(([group, items], groupIndex) => (
          <AccordionItem key={groupIndex}>
            <h3>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text fontWeight="bold">Matching Group: {group}</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h3>
            <AccordionPanel pb={4}>
              {items.map((item, itemIndex) => (
                <Box key={itemIndex} mt={4} borderWidth="1px" borderRadius="lg" p={4}>
                  <Text><strong>URL:</strong> <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a></Text>
                  <Accordion allowMultiple mt={2}>
                    <AccordionItem>
                      <h4>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <Text fontWeight="bold">Extracted Content</Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h4>
                      <AccordionPanel pb={4}>
                        <Text>{item.extracted_content}</Text>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <h4>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <Text fontWeight="bold">Extracted Markdown</Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h4>
                      <AccordionPanel pb={4}>
                        <ReactMarkdown>{item.extracted_markdown}</ReactMarkdown>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              ))}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </AccordionPanel>
  </AccordionItem>
)}















      </Accordion>
    );
  };

  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Grid
          templateAreas={`"header"    
                          "main"
                          "footer"`}
          gridTemplateRows={'60px 1fr 60px'}
          gridTemplateColumns={'1fr'}
          h='100vh'
          gap='1'
          color='blackAlpha.800'
        >
          <GridItem area={'header'}>
            <HeaderMenus />
          </GridItem>

          <GridItem bg='gray.50' area={'main'} overflowY="auto">
            <Box p={4}>
              <VStack spacing={4} align="stretch">
                <Heading mb={4}>CPT Code Lookup</Heading>
                <Flex>
                  <Input
                    placeholder="Enter CPT Code"
                    value={cptCode}
                    onChange={(e) => setCptCode(e.target.value)}
                    mr={2}
                  />
                  <Button onClick={handleLookup} isLoading={processing} loadingText="Looking up">
                    Lookup
                  </Button>
                </Flex>
                {renderCPTData()}
              </VStack>
            </Box>
          </GridItem>

          <GridItem
            bg='white'
            borderTop='1px'
            borderColor='brand.200'
            area={'footer'}
          >
            <Flex justifyContent="center" alignItems="center" height="100%">
              <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
              <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                &copy; {new Date().getFullYear()}
              </Text>
            </Flex>
          </GridItem>
        </Grid>

        <ChatToggle
          contextType={'revedy'}
          contextData={'revedy_cpt_lookup'}
          contextPersona={'revedy_cpt_lookup'}
          firstMessage={"Hi, what can I do on this page?"}
          useOptions={false}
          maintainContext={true}
          contextMode={'popup'}
        />
      </Box>
    </ChakraProvider>
  );
}

export default CPTLookup;
