import React from 'react';
import { Box, Text, VStack, UnorderedList, ListItem, Grid, GridItem, HStack, Table, Thead, Tbody, Tr, Th, Td , Select, Button} from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

const LinkRenderer = ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
  
  const renderIonmCpt = (ionmCpt, ionmModalityJustification) => {
    const renderCptCodes = (category) => {
      const preliminaryCptCodes = [];
      const nonPreliminaryCptCodes = [];
  
      Object.entries(ionmCpt[category]).forEach(([cptCode, details]) => {
        if (details.type === 'preliminary') {
          preliminaryCptCodes.push({ cptCode, details });
        } else {
          nonPreliminaryCptCodes.push({ cptCode, details });
        }
      });
  
      nonPreliminaryCptCodes.sort((a, b) => b.details.score - a.details.score);
  
      return (
        <>
          {nonPreliminaryCptCodes.map(({ cptCode, details }) => (
            <Box key={cptCode} borderWidth="1px" borderRadius="md" p={2} mb={2}>
              <Text fontWeight="bold">{details.cpt_code}
              {ionmModalityJustification[cptCode] && (
                <>
                   , Score: {ionmModalityJustification[cptCode].score}
                  </>
              )}
              </Text>
              <Text>Result: {details.result ? 'True' : 'False'}</Text>
              <Text>Reason: {details.reason}</Text>
              <Text>Units: {details.units}</Text>
              {details.total_duration && (
                <Text>Total Duration: {details.total_duration}</Text>
              )}
              {ionmModalityJustification[cptCode] && (
                <Box mt={2}>
                  <Text fontWeight="bold">Justification Discussion</Text>

                  <Text><ReactMarkdown>{ionmModalityJustification[cptCode].discussion}</ReactMarkdown></Text> 
                  <Text fontWeight="bold">Recommendations:</Text><Text> <ReactMarkdown>{ionmModalityJustification[cptCode].recommendations}</ReactMarkdown></Text>
                </Box>
              )}
            </Box>
          ))}
          {preliminaryCptCodes.map(({ cptCode, details }) => (
            <Box key={cptCode} borderWidth="1px" borderRadius="md" p={2} mb={2}>
              <Text fontWeight="bold">{details.cpt_code}  (Preliminary){ionmModalityJustification[cptCode] && (
                <>
                   , Score: {ionmModalityJustification[cptCode].score}
                  </>
              )}</Text>
              <Text>Result: {details.result ? 'True' : 'False'}</Text>
              <Text>Reason: {details.reason}</Text>
              <Text>Units: {details.units}</Text>
              {details.total_duration && (
                <Text>Total Duration: {details.total_duration}</Text>
              )}
              <Text>Type: {details.type}</Text>
              {ionmModalityJustification[cptCode] && (
                <Box mt={2}>
                  <Text fontWeight="bold">Justification Discussion</Text>
                  <Text>Discussion: {ionmModalityJustification[cptCode].discussion}</Text>
                  <Text fontWeight="bold">Recommendations:</Text><Text> {ionmModalityJustification[cptCode].recommendations}</Text>
                  <Text>Score: {ionmModalityJustification[cptCode].score}</Text>
                </Box>
              )}
            </Box>
          ))}
        </>
      );
    };
  
    return (
      <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          IONM CPT Codes
        </Text>
        {['tech', 'pro'].map((category) => (
          <Box key={category} mb={4}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              {category.toUpperCase()}
            </Text>
            {renderCptCodes(category)}
          </Box>
        ))}
      </Box>
    );
  };

const renderIonmIcd10 = (ionmIcd10) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        IONM ICD-10 Codes
      </Text>
      {Object.entries(ionmIcd10).map(([icd10Code, description]) => (
        <Box key={icd10Code} mb={2}>
          <Text fontWeight="bold">{icd10Code}</Text>
          <Text>{description}</Text>
        </Box>
      ))}
    </Box>
  );
};

const renderIonmMedicalNecessity = (ionmMedicalNecessity, payers, selectedPayer, handlePayerChange, handleReprocess,payer_name) => {

    const payerMappping = {
      'cms': 'Medicare',
      'cigna': 'Cigna',
      'aetna': 'Aetna',
      'uhc': 'UHC',
      'BCBS TX': 'BCBS TX',
      'BCBS NV': 'BCBS NV',
      'BCBS MI': 'BCBS MI',
      'BCBS IN': 'BCBS IN',
      'BCBS AL': 'BCBS AL',
      'UMR': 'UMR',
      'BCBS IL': 'BCBS IL',
      'BCBS PA': 'BCBS PA',
      'BCBS MA': 'BCBS MA',
      'BCBS GA': 'BCBS GA',
      'BSBS TN': 'BSBS TN',
      'BCBS NM': 'BCBS NM',
      'BCBS MS': 'BCBS MS',
      'BCBS CO': 'BCBS CO',
      'BCBS AZ': 'BCBS AZ',
      'BCBS AR': 'BCBS AR'
    };
    if (payerMappping[payer_name]) {
      payer_name = payerMappping[payer_name];
    }
    else {
      payer_name = payer_name;
    }

    try {
      return (
        <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            {payer_name}: Likelihood to pay: {ionmMedicalNecessity.score}/100
          </Text>
          <HStack spacing={4} align="left" width={'60%'}>
            <Select 
              value={selectedPayer} 
              onChange={handlePayerChange}
              flex={1}
            >
              {payers.map((payer) => (
                <option key={payer} value={payer}>{payer}</option>
              ))}
            </Select>
            <Button 
              onClick={handleReprocess} 
              isDisabled={!selectedPayer}
            >
              Run Analysis
            </Button>
          </HStack>
      
          {ionmMedicalNecessity.discussion && (
            <>

              <Text fontSize="lg" fontWeight="bold" mt={4} mb={2}>
                Analysis
              </Text>
            <details key="analysis" title='Analysis'>
              <ReactMarkdown
                components={{
                  a: LinkRenderer,
                }}
              >
                {ionmMedicalNecessity.discussion}
              </ReactMarkdown>
              </details>
            </>
          )}



          {ionmMedicalNecessity.recommendations && (
            <>
              <Text fontSize="lg" fontWeight="bold" mt={4} mb={2}>
                Recommendations
              </Text>
              <details key="recommendations" open>
              <ReactMarkdown
                components={{
                  a: LinkRenderer,
                }}
              >
                {ionmMedicalNecessity.recommendations}
              </ReactMarkdown>
              </details>
            </>
          )}
          

          {ionmMedicalNecessity.primary && (
            <>
            <Text fontSize="lg" fontWeight="bold" mt={4} mb={2}>
          Suggested Primary Diagnosis
          </Text>
            
            <ReactMarkdown
              components={{
                a: LinkRenderer,
              }}
            >
              {ionmMedicalNecessity.primary}
            </ReactMarkdown>
              </>
          )}


        </Box>
      );
    }
    catch (error) {
      console.error('Error rendering IONM Medical Necessity:', error);
      return <Text>No results available.</Text>;
    }

    };
    
const renderIonmModalityJustification = (ionmModalityJustification) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        IONM Modality Justification
      </Text>
      <ReactMarkdown
        components={{
          a: LinkRenderer,
        }}
      >
        {ionmModalityJustification}
      </ReactMarkdown>
    </Box>
  );
};

const renderRelevancyResults = (relevancyResults, payers, selectedPayer, handlePayerChange, handleReprocess) => {
  try {
    if (relevancyResults && typeof relevancyResults === 'object') {
      const { ionm_cpt, ionm_icd10, ionm_medical_necessity, ionm_modality_justification, payer_name } = relevancyResults;

      return (
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <GridItem colSpan={1}>
            {ionm_medical_necessity && renderIonmMedicalNecessity(ionm_medical_necessity, payers, selectedPayer, handlePayerChange, handleReprocess, payer_name)}
          </GridItem>
          <GridItem colSpan={1}>
            <VStack alignItems="stretch" spacing={4}>
              {ionm_icd10 && renderIonmIcd10(ionm_icd10)}
              {ionm_cpt && renderIonmCpt(ionm_cpt, ionm_modality_justification)}
            </VStack>
          </GridItem>
        </Grid>
      );
    }
    return <Text>No relevancy results to display.</Text>;
  }
  catch (error) {
    console.error('Error rendering relevancy results:', error);
    return <Text>No results available.</Text>;
  }

  };

const RelevancyResults = ({ relevancyResults, caseId, handleCaseClick, selectedPayer, setSelectedPayer }) => {
  const payers = [

    'Medicare', 'Cigna', 'Aetna', 'UHC (coming soon)','BCBS TX (coming soon)',  'BCBS NV (coming soon)', 'BCBS MI (coming soon)', 'BCBS IN (coming soon)',
     'BCBS AL (coming soon)',
     'UMR (coming soon)', 'BCBS IL (coming soon)', 'BCBS PA (coming soon)', 'BCBS MA (coming soon)', 
     'BCBS GA (coming soon)', 'BSBS TN (coming soon)', 'BCBS NM (coming soon)',
    'BCBS MS (coming soon)', 'BCBS CO (coming soon)', 'BCBS AZ (coming soon)', 'BCBS AR (coming soon)'
  ];

  const handlePayerChange = (event) => {
    setSelectedPayer(event.target.value);
  };

  const handleReprocess = () => {
    if (selectedPayer) {
      handleCaseClick(caseId, selectedPayer);
    }
  };

  return (
    <Box>
      {renderRelevancyResults(relevancyResults, payers, selectedPayer, handlePayerChange, handleReprocess)}
    </Box>
  );
};

export default RelevancyResults;