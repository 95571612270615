import React from 'react';
import { Box, Text, VStack, Grid, GridItem } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

const LinkRenderer = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const RRelevancyResults = ({ relevancyResults }) => {
  if (!relevancyResults || typeof relevancyResults !== 'object') {
    return <Text>No relevancy results to display.</Text>;
  }

    try {  
    const { ionm_cpt, ionm_icd10, ionm_medical_necessity, ionm_modality_justification, payer_name } = relevancyResults;

    const renderCptCodes = (category) => {
      try {
        return Object.entries(ionm_cpt[category]).map(([cptCode, details]) => (
          <Box key={cptCode} borderWidth="1px" borderRadius="md" p={2} mb={2}>
            <Text fontWeight="bold">
              {details.cpt_code}
              {details.type === 'preliminary' && ' (Preliminary)'}
              {ionm_modality_justification[cptCode] && `, Score: ${ionm_modality_justification[cptCode].score}`}
            </Text>
            <Text>Result: {details.result ? 'True' : 'False'}</Text>
            <Text>Reason: {details.reason}</Text>
            <Text>Units: {details.units}</Text>
            {details.total_duration && <Text>Total Duration: {details.total_duration}</Text>}
            {ionm_modality_justification[cptCode] && (
              <Box mt={2}>
                <Text fontWeight="bold">Justification Discussion</Text>
                <ReactMarkdown components={{ a: LinkRenderer }}>{ionm_modality_justification[cptCode].discussion}</ReactMarkdown>
                <Text fontWeight="bold">Recommendations:</Text>
                <ReactMarkdown components={{ a: LinkRenderer }}>{ionm_modality_justification[cptCode].recommendations}</ReactMarkdown>
              </Box>
            )}
          </Box>
        ));
      }
      catch (error) {
        console.error('Error rendering CPT codes:', error);
        return <Text>No CPT codes available.</Text>;
      }
    };

    return (
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <GridItem colSpan={1}>
          <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              {payer_name}: Likelihood to pay: {ionm_medical_necessity.score}/100
            </Text>
            {ionm_medical_necessity.discussion && (
              <>
                <Text fontSize="lg" fontWeight="bold" mt={4} mb={2}>Analysis</Text>
                <details key="analysis" title='Analysis'>
                  <ReactMarkdown components={{ a: LinkRenderer }}>{ionm_medical_necessity.discussion}</ReactMarkdown>
                </details>
              </>
            )}
            {ionm_medical_necessity.recommendations && (
              <>
                <Text fontSize="lg" fontWeight="bold" mt={4} mb={2}>Recommendations</Text>
                <details key="recommendations" open>
                  <ReactMarkdown components={{ a: LinkRenderer }}>{ionm_medical_necessity.recommendations}</ReactMarkdown>
                </details>
              </>
            )}
            {ionm_medical_necessity.primary && (
              <>
                <Text fontSize="lg" fontWeight="bold" mt={4} mb={2}>Suggested Primary Diagnosis</Text>
                <ReactMarkdown components={{ a: LinkRenderer }}>{ionm_medical_necessity.primary}</ReactMarkdown>
              </>
            )}
          </Box>
        </GridItem>
        <GridItem colSpan={1}>
          <VStack alignItems="stretch" spacing={4}>
            <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
              <Text fontSize="xl" fontWeight="bold" mb={4}>IONM ICD-10 Codes</Text>
              {Object.entries(ionm_icd10).map(([icd10Code, description]) => (
                <Box key={icd10Code} mb={2}>
                  <Text fontWeight="bold">{icd10Code}</Text>
                  <Text>{description}</Text>
                </Box>
              ))}
            </Box>
            <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
              <Text fontSize="xl" fontWeight="bold" mb={4}>IONM CPT Codes</Text>
              {['tech', 'pro'].map((category) => (
                <Box key={category} mb={4}>
                  <Text fontSize="lg" fontWeight="bold" mb={2}>{category.toUpperCase()}</Text>
                  {renderCptCodes(category)}
                </Box>
              ))}
            </Box>
          </VStack>
        </GridItem>
      </Grid>
    );
  }
  catch (error) {
    console.error('Error rendering relevancy results:', error);
    return <Text>No results available.</Text>;
  }
};

export default RRelevancyResults;