import React, { useState, useEffect, useContext } from 'react';
import { callApi } from '../callApi'; 
import { AppContext } from '../components/AppContext';
import { ChakraProvider, Box, Grid, GridItem, 
    Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel, Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Spinner,
    

 } from "@chakra-ui/react";
import { extendTheme } from '@chakra-ui/react';
import AccountMenu from '../account';
import CaseServiceList from './CaseServiceList';
import HeaderMenus from './HeaderMenus';
import { connectWebSocket } from '../utils';
import { setIn } from 'formik';
import ChatToggle from './ChatToggle'; // Adjust the import path as necessary
import { useHistory, useNavigate, useLocation } from 'react-router-dom';


function CreateNote() {
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken, loading, appState, setAppState } = useContext(AppContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const newNoteText = appState.generatedNoteText;
    const useSampleNote = urlParams.get('sample') === 'true' ? 1 : 0;

    const createNote = async () => {
      console.log("creating new note");
      try {
        let response = await callApi('/createnote', accessToken, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            use_sample_note: useSampleNote,
            note: newNoteText,
          })
        });
        let results = await response.json();

        let noteId = results.note_id;
        let versionId = results.version_id;
        console.log("noteId: ", noteId);

        setAppState(prevState => ({
          ...prevState,
          versionId: versionId,
          noteId: noteId,
          generatedNoteText: null, // Reset the generatedNoteText after creating the note
        }));

        navigate(`/note/?id=${noteId}&version=${versionId}`, { replace: true });
      } catch (error) {
        console.error(error);
        // Set an error message or navigate to an error page
        setAppState(prevState => ({
          ...prevState,
          errorMessage: 'Failed to create note.'
        }));
        navigate('/'); // Redirect to home or an error page
      }
    };

    if (newNoteText || useSampleNote) {
      createNote();
    } else {
      // Handle the case where there is no newNoteText and not using a sample
      navigate('/'); // Redirect to home or another appropriate route
    }
  }, [location]); // Empty dependency array ensures this runs once on mount

  // Render nothing or a loading indicator as this component is for action only
  return null;
}

export default CreateNote;