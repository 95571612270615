import React, { useContext, useState } from 'react';
import { callApi } from '../callApi';
import { AppContext } from '../components/AppContext';
import { ChakraProvider, Box, Grid, GridItem, Flex, Image, VStack, Text, Button, Select, Spinner, List, ListItem, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Divider, Textarea } from "@chakra-ui/react";
import HeaderMenus from './HeaderMenus';
import ChatToggle from './ChatToggle';

function EncounterList() {
    
    const { accessToken } = useContext(AppContext);
    const [apiResults, setApiResults] = useState(null);

    const clearResults = () => {
        setApiResults(null);
    };
    
    const handleSaveNoteSubmit = async (e) => {
        e.preventDefault();
        const encounterId = e.target.saveNoteEncounterId.value;
        const noteText = e.target.noteText.value;
        const description = e.target.description.value;
        clearResults();

        const response = await callApi(`/v1/encounters/${encounterId}/notes`, accessToken, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                note_text: noteText,
                description: description,
            }),
        });

        if (response.ok) {
            const data = await response.json();
            setApiResults(JSON.stringify(data, null, 2));
        } else {
            // Handle errors
            setApiResults('Error saving note.');
        }
    };

    const handleRecentNoteSubmit = async (e) => {
        e.preventDefault();
        const encounterId = e.target.recentNoteEncounterId.value;
        clearResults();

        const response = await callApi(`/v1/encounters/${encounterId}/notes/recent`, accessToken, {
            method: 'GET',
        });

        if (response.ok) {
            const data = await response.json();
            setApiResults(JSON.stringify(data, null, 2));
        } else {
            // Handle errors
            setApiResults('Error fetching recent note.');
        }
    };

    const handleAllNotesSubmit = async (e) => {
        e.preventDefault();
        const encounterId = e.target.allNotesEncounterId.value;
        clearResults();

        const response = await callApi(`/v1/encounters/${encounterId}/notes/all`, accessToken, {
            method: 'GET',
        });

        if (response.ok) {
            const data = await response.json();
            setApiResults(JSON.stringify(data, null, 2));
        } else {
            // Handle errors
            setApiResults('Error fetching all notes.');
        }
    };


    const handleUserProvidedContentSubmit = async (e) => {
        e.preventDefault();
        const encounterId = e.target.userProvidedContentEncounterId.value;
        const userProvidedContent = e.target.userProvidedContent.value;
        clearResults();
      
        const response = await callApi(`/v1/encounters/${encounterId}/patient-facing-content`, accessToken, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_provided_content: userProvidedContent,
          }),
        });
      
        if (response.ok) {
          const data = await response.json();
          setApiResults(JSON.stringify(data, null, 2));
        } else {
            // Handle errors
            setApiResults('Error fetching patient-facing content.');
        }
      };

    const handleRecentPatientFacingContentSubmit = async (e) => {
        e.preventDefault();
        const encounterId = e.target.recentPatientFacingContentEncounterId.value;
        clearResults();
    
        const response = await callApi(`/v1/encounters/${encounterId}/patient-facing-content/recent`, accessToken, {
            method: 'GET',
        });
    
        if (response.ok) {
            const data = await response.json();
            setApiResults(JSON.stringify(data, null, 2));
        } else {
            // Handle errors
            setApiResults('Error fetching recent patient-facing content.');
        }
    };
    
    const handleAllPatientFacingContentSubmit = async (e) => {
        e.preventDefault();
        const encounterId = e.target.allPatientFacingContentEncounterId.value;
        clearResults();
    
        const response = await callApi(`/v1/encounters/${encounterId}/patient-facing-content/all`, accessToken, {
            method: 'GET',
        });
    
        if (response.ok) {
            const data = await response.json();
            setApiResults(JSON.stringify(data, null, 2));

        } else {
            // Handle errors
            setApiResults('Error fetching all patient-facing content.');
        }
    };


    const handlePatientFacingContentSubmit = async (e) => {
        e.preventDefault();
        const encounterId = e.target.patientFacingContentEncounterId.value;
        const readingLevel = e.target.readingLevel.value;
        clearResults();
    
        const response = await callApi(`/v1/encounters/${encounterId}/patient-facing-content`, accessToken, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                reading_level: readingLevel,
            }),
        });
    
        if (response.ok) {
            const data = await response.json();
            setApiResults(JSON.stringify(data, null, 2));
        } else {
            // Handle errors
            setApiResults('Error fetching patient-facing content.');
        }
    };
    

    const handleEncounterDetailsSubmit = async (e) => {
        e.preventDefault();
        const encounterId = e.target.encounterId.value;
        clearResults();

        const response = await callApi(`/v1/encounters/${encounterId}`, accessToken, {
            method: 'GET',
        });

        if (response.ok) {
            const data = await response.json();
            setApiResults(JSON.stringify(data, null, 2));
        } else {
            // Handle errors
            setApiResults('Error fetching encounter details.');
        }
    };

    const handleEncounterListSubmit = async (e) => {
        e.preventDefault();
        clearResults();

        const response = await callApi('/v1/encounters', accessToken, {
            method: 'GET',
        });

        if (response.ok) {
            const data = await response.json();
            setApiResults(JSON.stringify(data, null, 2));
        } else {
            // Handle errors
            setApiResults('Error fetching encounter list.');
        }
    };

    const handleCurrentCodesSubmit = async (e) => {
        e.preventDefault();
        const encounterId = e.target.currentCodesEncounterId.value;
        clearResults();

        const response = await callApi(`/v1/encounters/${encounterId}/codes/current`, accessToken, {
            method: 'GET',
        });

        if (response.ok) {
            const data = await response.json();
            setApiResults(JSON.stringify(data, null, 2));
        } else {
            // Handle errors
            setApiResults('Error fetching current codes.');
        }
    };

    const handleCodeHistorySubmit = async (e) => {
        e.preventDefault();
        const encounterId = e.target.codeHistoryEncounterId.value;
        clearResults();

        const response = await callApi(`/v1/encounters/${encounterId}/codes/history`, accessToken, {
            method: 'GET',
        });

        if (response.ok) {
            const data = await response.json();
            setApiResults(JSON.stringify(data, null, 2));
        } else {
            // Handle errors
            setApiResults('Error fetching code history.');
        }
    };

    return (
        <Box textAlign="center" fontSize="xl">
            <Grid
                templateAreas={`"header header"
                                "main results"
                                "footer footer"`}
                gridTemplateRows={'60px 1fr 60px'}
                gridTemplateColumns={'500px 1fr'}
                h='100%'
                gap='2'
                color='blackAlpha.800'
                fontWeight='bold'
            >
                <HeaderMenus />

                <GridItem bg='gray.50' padding={4} area={'main'}>
  <Tabs>
    <TabList>
      <Tab>Encounters</Tab>
      <Tab>Notes</Tab>
      <Tab>Codes</Tab>
      <Tab>Patient Content</Tab>
    </TabList>

    <TabPanels>
        <TabPanel>
            <VStack spacing={4} align="stretch">
            <Text fontWeight="bold" mt={8}>GET /v1/encounters</Text>
            <form onSubmit={handleEncounterListSubmit}>
                <Button type="submit" colorScheme="blue">
                Fetch Encounter List
                </Button>
            </form>

            <Divider my={4} />

            <Text fontWeight="bold">GET /v1/encounters/:encounterId</Text>
            <form onSubmit={handleEncounterDetailsSubmit}>
                <FormControl>
                <FormLabel>Encounter ID</FormLabel>
                <Input type="text" name="encounterId" />
                </FormControl>
                <Button type="submit" colorScheme="blue" mt={4}>
                Fetch Encounter Details
                </Button>
            </form>
            </VStack>
        </TabPanel>

        <TabPanel>
                                <VStack spacing={4} align="stretch">
                                    <Text fontWeight="bold" mt={8}>POST /v1/encounters/:encounterId/notes</Text>
                                    <form onSubmit={handleSaveNoteSubmit}>
                                        <FormControl>
                                            <FormLabel>Encounter ID</FormLabel>
                                            <Input type="text" name="saveNoteEncounterId" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Note Text</FormLabel>
                                            <Textarea name="noteText" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Description</FormLabel>
                                            <Input type="text" name="description" />
                                        </FormControl>
                                        <Button type="submit" colorScheme="blue" mt={4}>
                                            Save Note
                                        </Button>
                                    </form>

                                    <Divider my={4} />

                                    <Text fontWeight="bold" mt={8}>GET /v1/encounters/:encounterId/notes/recent</Text>
                                    <form onSubmit={handleRecentNoteSubmit}>
                                        <FormControl>
                                            <FormLabel>Encounter ID</FormLabel>
                                            <Input type="text" name="recentNoteEncounterId" />
                                        </FormControl>
                                        <Button type="submit" colorScheme="blue" mt={4}>
                                            Fetch Recent Note
                                        </Button>
                                    </form>

                                    <Divider my={4} />

                                    <Text fontWeight="bold" mt={8}>GET /v1/encounters/:encounterId/notes/all</Text>
                                    <form onSubmit={handleAllNotesSubmit}>
                                        <FormControl>
                                            <FormLabel>Encounter ID</FormLabel>
                                            <Input type="text" name="allNotesEncounterId" />
                                        </FormControl>
                                        <Button type="submit" colorScheme="blue" mt={4}>
                                            Fetch All Notes
                                        </Button>
                                    </form>
                                </VStack>
                            </TabPanel>

        <TabPanel>
            <VStack spacing={4} align="stretch">
            <Text fontWeight="bold" mt={8}>GET /v1/encounters/:encounterId/codes/current</Text>
            <form onSubmit={handleCurrentCodesSubmit}>
                <FormControl>
                <FormLabel>Encounter ID</FormLabel>
                <Input type="text" name="currentCodesEncounterId" />
                </FormControl>
                <Button type="submit" colorScheme="blue" mt={4}>
                Fetch Current Codes
                </Button>
            </form>

            <Divider my={4} />

            <Text fontWeight="bold" mt={8}>GET /v1/encounters/:encounterId/codes/history</Text>
            <form onSubmit={handleCodeHistorySubmit}>
                <FormControl>
                <FormLabel>Encounter ID</FormLabel>
                <Input type="text" name="codeHistoryEncounterId" />
                </FormControl>
                <Button type="submit" colorScheme="blue" mt={4}>
                Fetch Code History
                </Button>
            </form>
            </VStack>
        </TabPanel>

        <TabPanel>
            <VStack spacing={4} align="stretch">
            <Text fontWeight="bold" mt={8}>POST /v1/encounters/:encounterId/patient-facing-content</Text>
            <form onSubmit={handlePatientFacingContentSubmit}>
                <FormControl>
                <FormLabel>Reading Level</FormLabel>
                <Select name="readingLevel">
                    <option value="2nd grade">2nd grade</option>
                    <option value="5th grade">5th grade</option>
                    <option value="10th grade">10th grade</option>
                </Select>
                </FormControl>
                <FormControl>
                <FormLabel>Encounter ID</FormLabel>
                <Input type="text" name="patientFacingContentEncounterId" />
                </FormControl>
                <Button type="submit" colorScheme="blue" mt={4}>
                Generate Patient-Facing Content
                </Button>
            </form>

            <Divider my={4} />

            <Text fontWeight="bold" mt={8}>POST /v1/encounters/:encounterId/patient-facing-content (User Provided)</Text>
            <form onSubmit={handleUserProvidedContentSubmit}>
                <FormControl>
                <FormLabel>Encounter ID</FormLabel>
                <Input type="text" name="userProvidedContentEncounterId" />
                </FormControl>
                <FormControl>
                <FormLabel>User Provided Content</FormLabel>
                <Textarea name="userProvidedContent" />
                </FormControl>
                <Button type="submit" colorScheme="blue" mt={4}>
                Submit User Provided Content
                </Button>
            </form>

            <Divider my={4} />

            <Text fontWeight="bold" mt={8}>GET /v1/encounters/:encounterId/patient-facing-content/recent</Text>
            <form onSubmit={handleRecentPatientFacingContentSubmit}>
                <FormControl>
                <FormLabel>Encounter ID</FormLabel>
                <Input type="text" name="recentPatientFacingContentEncounterId" />
                </FormControl>
                <Button type="submit" colorScheme="blue" mt={4}>
                Fetch Recent Patient-Facing Content
                </Button>
            </form>

            <Divider my={4} />

            <Text fontWeight="bold" mt={8}>GET /v1/encounters/:encounterId/patient-facing-content/all</Text>
            <form onSubmit={handleAllPatientFacingContentSubmit}>
                <FormControl>
                <FormLabel>Encounter ID</FormLabel>
                <Input type="text" name="allPatientFacingContentEncounterId" />
                </FormControl>
                <Button type="submit" colorScheme="blue" mt={4}>
                Fetch All Patient-Facing Content
                </Button>
            </form>
            </VStack>
        </TabPanel>
        </TabPanels>
  </Tabs>
</GridItem>

<GridItem bg='gray.50' padding={4} area={'results'} maxW="1400px">
  {apiResults && (
    <Box textAlign="left" overflowX={"auto"}>
      <Text fontWeight="bold">API Results:</Text>
      <pre id="apiResults">{apiResults}</pre>
    </Box>
    )}

</GridItem>

                <GridItem
                    bg='white'
                    borderTop='1px'
                    borderColor='brand.200'
                    paddingY={4}
                    paddingX={6}
                    area={'footer'}
                >
                    <Flex justifyContent="center" alignItems="center">
                        <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
                        <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                            &copy; {new Date().getFullYear()}
                        </Text>
                    </Flex>
                </GridItem>
            </Grid>
            <ChatToggle
                contextType={'revedy'}
                contextData={'revedy_encounter_list'}
                contextPersona={'revedy_encounter_list'}
                firstMessage={"Hi, what can I do on this page?"}
                useOptions={false}
                maintainContext={true}
                contextMode={'popup'}
            />
        </Box>
    );
}

export default EncounterList;