// noteService.js
import { callApi } from './callApi'; 

export const handleNoteList = async (accessToken) => {
    try {
      let response = await callApi('/getrecentnotes', accessToken, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      let results = await response.json();
      console.log("got results: " + JSON.stringify(results, null, 2));
      return results.notes || []; // return an empty array if results.notes is undefined
    } catch (error) {
      console.error(error);
      return []; // return an empty array in case of an error
    }
  };