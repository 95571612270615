// caseService.js

export const handleCaseList = async (callApi, accessToken, caseId="", codingIssues=0, searchTerm = "", perIssue=false) => {
    try {
      let response = await callApi('/getcases', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          issue: codingIssues,
          search_term: searchTerm,
          per_issue: perIssue,
          case_id: caseId,})
      });
      let results = await response.json();
      return results.cases || []; // return an empty array if results.cases is undefined
    } catch (error) {
      console.error(error);
      return []; // return an empty array in case of an error
    }
};

