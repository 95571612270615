import React, { useState, useContext } from 'react';
import { callApi } from '../callApi'; 
import { AppContext } from '../components/AppContext';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast
} from "@chakra-ui/react";

const CreateUser = () => {
  const { accessToken, loading, appState, setAppState } = useContext(AppContext);
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [orgId, setOrgId] = useState('');
  const [name, setName] = useState('');
  const [user_context, setUserContext] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Basic validation
    if (!email || !orgId || !name) {
      toast({
        title: 'Error',
        description: 'Please fill in all fields.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    try {
      const response = await callApi('/create_user', accessToken,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email: email, 
          org_id: orgId,
          name: name,
          user_context: user_context,  
      }),
      });
      const data = await response.json();

      if (response.ok) {
        toast({
          title: 'Success',
          description: 'User created successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setEmail('');
        setOrgId('');
      } else {
        toast({
          title: 'Error',
          description: data.error || 'Failed to create user.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An unexpected error occurred.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box p={4}>
      <form onSubmit={handleSubmit}>
        <FormControl id="email" isRequired>
          <FormLabel>Email address</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl id="name" isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>

        <FormControl id="orgId" mt={4} isRequired>
          <FormLabel>Organization ID</FormLabel>
          <Input
            type="text"
            value={orgId}
            onChange={(e) => setOrgId(e.target.value)}
          />
        </FormControl>
        <FormControl id="user_context" isRequired>
          <FormLabel>User Description</FormLabel>
          <Input
            type="text"
            value={user_context}
            onChange={(e) => setUserContext(e.target.value)}
          />
        </FormControl>


        
        <Button
          mt={4}
          colorScheme="blue"
          type="submit"
          isLoading={isLoading}
        >
          Create User
        </Button>
      </form>
    </Box>
  );
};

export default CreateUser;